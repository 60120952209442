import styled, { css } from 'styled-components'

import { media } from '@/styles/media'
import { flexCenter, flexColumn, flexRow, typography } from '@/styles/utils'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1em;
  width: 100%;
  background-color: ${({ theme }) => theme.color.white};
  border-radius: 1rem;
  padding: 1rem;
`

export const FlexRow = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(186px, 1fr));
  gap: 1rem;
  align-items: center;

  ${media.mobile(
    () => css`
      flex-direction: column;
    `,
  )}
`
export const ModalBody = styled.div`
  ${flexRow()};
  gap: 1em;
`
export const ModalContainer = styled.div`
  height: auto;
  padding: 1rem;
  border-radius: 18px;
  background-color: ${({ theme }) => theme.color.mangolia};
  ${flexColumn()};
`

export const ModalHeader = styled.header`
  width: 100%;
  height: 4rem;
  ${flexCenter()};
  ${typography.h3};
  position: relative;
  background-color: ${({ theme }) => theme.color.white};
  z-index: 1;
  border-radius: 18px 18px 0px 0px;
`
export const ModalParagraph = styled.p`
  font-size: ${({ theme }) => theme.fontSize.normal};
  text-align: center;
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  color: ${({ theme }) => theme.color.black};
`
export const Body = styled.div`
  padding: 1rem;
  ${flexCenter()};
  flex-direction: column;
  gap: 2rem;
  flex: 1;
  z-index: 2;
  background-color: ${({ theme }) => theme.color.mangolia};
  border-radius: 0px 0px 18px 18px;
`
