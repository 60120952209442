import Chart from 'react-apexcharts'

import { Loader } from '@/components'

import type { DateQuery } from '../../overview'
import { Card, GraphContainers } from '../ParentReports.styled'
import usePieChartParent from './usePieChartParent'

export default function ParentPieChart({ date }: { date: DateQuery }) {
  const { graphRender, loading, graphRenderSubs, graphRenderActive } =
    usePieChartParent(date.startDate, date.endDate)
  return (
    <GraphContainers>
      <Card>
        {loading ? (
          <Loader size="4em" color="codeCurrent" />
        ) : (
          <Chart
            series={graphRender.series}
            options={graphRender}
            height={graphRender.chart?.height ?? 'auto'}
            type="donut"
          />
        )}
      </Card>
      <Card>
        {loading ? (
          <Loader size="4em" color="codeCurrent" />
        ) : (
          <Chart
            series={graphRenderActive.series}
            options={graphRenderActive}
            height={graphRenderActive.chart?.height ?? 'auto'}
            type="donut"
          />
        )}
      </Card>
      <Card>
        {loading ? (
          <Loader size="4em" color="codeCurrent" />
        ) : (
          <Chart
            series={graphRenderSubs.series}
            options={graphRenderSubs}
            height={graphRenderSubs.chart?.height ?? 'auto'}
            type="donut"
          />
        )}
      </Card>
    </GraphContainers>
  )
}
