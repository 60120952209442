import { useState } from 'react'
import Chart from 'react-apexcharts'
import { useTranslation } from 'react-i18next'
import type { SingleValue } from 'react-select'

import type { Option } from '@/components'
import { Loader, Select } from '@/components'

import type { DateQuery } from '../../overview'
import { Card } from '../ChildRepots.styled'
import useTimeStatsSubs from './useTimeStatsSubs'

export function ChildSubscriptions({ date }: { date: DateQuery }) {
  const [selected, setSelected] = useState<string | undefined>(undefined)
  const { graphRender: timeStats, loading: loadingTimeStats } =
    useTimeStatsSubs(date, selected)

  const { t: ts } = useTranslation('admin', { keyPrefix: 'subscriptions' })
  const { t } = useTranslation('admin', { keyPrefix: 'students' })
  const SubscriptionOptions: Option[] = [
    { value: '', label: 'All' },
    { value: 'Active', label: ts('active') },
    { value: 'Trial', label: ts('trial') },
    { value: 'Canceled', label: ts('cancelled') },
    { value: 'PastDue', label: ts('pastDue') },
  ]
  return (
    <Card>
      <Select
        name="subscription"
        label={t('filterSubs')}
        options={SubscriptionOptions.map((subscription) => ({
          label: subscription.label,
          value: subscription.value,
        }))}
        defaultValue={SubscriptionOptions.find((x) => x.value === '')}
        appearance="grey"
        onChange={(e: SingleValue<Option>) => {
          setSelected(e?.value ?? undefined)
        }}
      />
      {loadingTimeStats ? (
        <Loader size="4em" color="codeCurrent" />
      ) : (
        <Chart
          series={timeStats.series}
          options={timeStats}
          height={timeStats.chart?.height}
          type="area"
        />
      )}
    </Card>
  )
}
