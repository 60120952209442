import type { PropsWithChildren } from 'react'
import { useEffect } from 'react'
import type { Props } from 'react-modal'
import ReactModal from 'react-modal'

import { Icon } from '@/components'

import type { ClosePosition } from './Modal.styled'
import {
  bodyOpenClassName,
  CloseIcon,
  closeTimeout,
  Content,
  Overlay,
} from './Modal.styled'

const rootElement = document.getElementById('root')!

export type ModalProps = Omit<
  Props,
  | 'contentElement'
  | 'className'
  | 'bodyOpenClassName'
  | 'overlayClassName'
  | 'overlayElement'
  | 'onRequestClose'
  | 'appElement'
  | 'ariaHideApp'
> &
  Required<Pick<Props, 'onRequestClose'>> & {
    hideClose?: boolean
    closePosition?: ClosePosition
    className?: string
  }

export function Modal({
  shouldCloseOnOverlayClick = false,
  shouldCloseOnEsc = true,
  isOpen,
  hideClose = false,
  closePosition = { top: '3.25rem', right: '2.25rem' },
  onRequestClose,
  children,
  className,
  ...rest
}: PropsWithChildren<ModalProps>) {
  useEffect(() => {
    const scrollElement = document.documentElement
    if (isOpen) {
      scrollElement.style.overflow = 'hidden'
    } else {
      scrollElement.style.overflow = 'auto'
    }
  }, [isOpen])

  return (
    <ReactModal
      {...rest}
      isOpen={isOpen}
      appElement={rootElement}
      parentSelector={() => rootElement}
      closeTimeoutMS={closeTimeout}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      shouldCloseOnEsc={shouldCloseOnEsc}
      bodyOpenClassName={bodyOpenClassName}
      className="_"
      contentElement={(properties_) => (
        <Content
          {...properties_}
          className={`${properties_.className ?? ''} ${className ?? ''}`}
        >
          {!hideClose && (
            <CloseIcon position={closePosition} onClick={onRequestClose}>
              <Icon icon="close" />
            </CloseIcon>
          )}
          {children}
        </Content>
      )}
      overlayClassName="_"
      overlayElement={(properties_, contentElement) => (
        <Overlay {...properties_}>{contentElement}</Overlay>
      )}
      onRequestClose={onRequestClose}
    />
  )
}
