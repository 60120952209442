/* eslint-disable */

declare global {
  var grecaptcha: {
    enterprise: {
      ready: (callback: VoidFunction) => void
      execute: (siteKey: string, props: { action: string }) => Promise<string>
    }
  }
  var dataLayer: unknown[]
  var gtag: (...args: unknown[]) => void
  var fbq: (...args: unknown[]) => void
}

export class HtmlScripts {
  init() {
    this.initRecaptcha()
    this.initGoogleAnalytics()
    this.initFacebookPixel()
  }

  private createAndInjectScript(src: string, async = true) {
    const scriptElement = document.createElement('script')
    scriptElement.type = 'text/javascript'
    scriptElement.async = async
    scriptElement.setAttribute('charset', 'utf-8')
    scriptElement.src = src

    const firstScriptElement = document.getElementsByTagName('script')[0]

    if (!firstScriptElement.parentNode) {
      throw new Error('NO_SCRIPT_ELEMENTS_FOUND')
    }

    firstScriptElement.parentNode.insertBefore(
      scriptElement,
      firstScriptElement,
    )
  }

  private initGoogleAnalytics() {
    const isDev = import.meta.env.MODE === 'development'

    const measurementId = import.meta.env.VITE_GOOGLE_ANALYTICS_MEASUREMENT_ID
    const src = `https://www.googletagmanager.com/gtag/js?id=${measurementId}`

    this.createAndInjectScript(src)

    window.dataLayer = window.dataLayer || []
    window.gtag = function () {
      window.dataLayer.push(arguments)
    }

    gtag('js', new Date())
    gtag('config', measurementId, {
      debug_mode: isDev,
    })
  }

  private initFacebookPixel() {
    const pixelId = import.meta.env.VITE_FACEBOOK_PIXEL_ID

    fbq('init', pixelId)
  }

  private initRecaptcha() {
    const src = `https://www.google.com/recaptcha/enterprise.js?render=${
      import.meta.env.VITE_RECAPTCHA_SITE_KEY
    }`

    this.createAndInjectScript(src, false)
  }
}

export const htmlScripts = new HtmlScripts()
