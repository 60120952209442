import { useRecoilState } from 'recoil'

import type { CartCourse, MarketplaceCart } from './atoms'
import { defaultValue, marketplaceCart } from './atoms'

export const useMarketplaceCart = () => {
  const [cart, setCart] = useRecoilState(marketplaceCart)

  const addCourse = (course: CartCourse) => {
    setCart((prevState) => ({
      courses: {
        ...prevState.courses,
        [course.child._id]: [
          ...(prevState.courses[course.child._id] ?? []),
          course,
        ],
      },
    }))
  }

  const deleteCourse = (childId: string, courseId?: string) => {
    setCart((prevState) => {
      const newCourses = courseId
        ? prevState.courses[childId]?.filter(
            (course) => course._id !== courseId,
          )
        : []

      // cast to array and then back to object to save order
      const parsedCourses = Object.entries(prevState.courses)
        .map(([id, courses]) =>
          id === childId ? [id, newCourses] : [id, courses],
        )
        .reduce(
          (acc, [_id, _course]) => ({ ...acc, [_id as string]: _course }),
          {},
        ) as MarketplaceCart['courses']

      for (const _childId in parsedCourses) {
        if (parsedCourses[_childId].length === 0) {
          // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
          delete parsedCourses[_childId]
        }
      }

      return {
        courses: parsedCourses,
      }
    })
  }

  const clearMarketplaceCart = () => {
    setCart(defaultValue)
  }

  const clearOnlyFreeCourses = () => {
    setCart((prevState) => {
      const newCourses: Record<string, CartCourse[]> = {}

      for (const childId in prevState.courses) {
        newCourses[childId] = prevState.courses[childId].filter(
          ({ price }) => price > 0,
        )
      }

      return {
        courses: newCourses,
      }
    })
  }

  const total = Object.entries(cart.courses).reduce((total, [, courses]) => {
    const totalForChild = courses.reduce(
      (childTotal, { price }) => childTotal + price,
      0,
    )

    return total + totalForChild
  }, 0)

  return {
    courses: cart.courses,
    coursesArray: Object.entries(cart.courses),
    total: total.toFixed(2),
    addCourse,
    deleteCourse,
    clearMarketplaceCart,
    clearOnlyFreeCourses,
  }
}
