import styled, { css } from 'styled-components'

import stars from '@/assets/images/landing/stars.svg'
import { media } from '@/styles/media'

export const Main = styled.div`
  background: url(${stars}) no-repeat 75% 25%;
  padding-top: 18rem;

  ${media.mobile(
    () => css`
      background-size: 60px;
      background-position: 85% 25%;
    `,
  )}
`

export const TagLine = styled.div`
  color: ${({ theme }) => theme.color.seductiveThorns};
  font-size: ${({ theme }) => theme.landingSpecialFontSize.huge};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
`
