import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

export const useTitle = (i18nKey: string, params?: Record<string, string>) => {
  const { t } = useTranslation()

  const defaultTitle = t('title.default')

  const buildTitle = () => {
    const pageTitle = t(`title.${i18nKey}`, { defaultValue: '', ...params })

    return pageTitle ? `${defaultTitle} | ${pageTitle}` : defaultTitle
  }

  useEffect(() => {
    document.title = buildTitle()
  }, [i18nKey, params])
}
