import type { ApexOptions } from 'apexcharts'
import { formatISO } from 'date-fns'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useRegistrationsByDateQuery } from '@/graphql/generated/types'
import { theme } from '@/styles/theme'

import type { DateQuery } from '../../overview'

const usePayingChart = (dateFilter: DateQuery) => {
  const { data, loading } = useRegistrationsByDateQuery({
    variables: {
      endDate: dateFilter.endDate,
      startDate: dateFilter.startDate,
    },
  })

  const { t } = useTranslation('admin', { keyPrefix: 'educators' })
  const renderGraph: ApexOptions = useMemo(() => {
    if (data) {
      const finalData = data.registrationsByDate
      const Status = Array.from(new Set(finalData.map((x) => x.status)))
      const graph: ApexOptions = {
        series: Status.map((status) => {
          const dataFiltered = finalData.filter((x) => x.status === status)
          return {
            name: status,
            data: dataFiltered.map((x) => ({
              x: new Date(x.date).getTime(),
              y: x.value,
            })),
          }
        }),
        chart: {
          type: 'area',
          stacked: false,
          height: 600,
          zoom: {
            type: 'xy',
            enabled: true,
            autoScaleYaxis: true,
          },
          toolbar: {
            show: true,
            autoSelected: 'zoom',
            tools: {
              pan: true,
            },
          },
        },
        title: {
          text: t('graphs.timeSubsGraph', {
            startDate: formatISO(new Date(dateFilter.startDate), {
              representation: 'date',
            }),
            endDate: formatISO(new Date(dateFilter.endDate), {
              representation: 'date',
            }),
          }),
          align: 'left',
          style: {
            fontWeight: theme.fontWeight.medium,
            fontFamily: 'Fredoka',
            color: theme.color.slateGray,
          },
        },
        yaxis: {
          labels: {},
          title: {
            text: 'Users',
          },
        },
        dataLabels: {
          enabled: false,
        },
        fill: {
          type: 'gradient',
          gradient: {
            shadeIntensity: 1,
            inverseColors: false,
            opacityFrom: 0.5,
            opacityTo: 0,
            stops: [0, 90, 100],
          },
        },
        tooltip: {
          shared: true,
          y: {
            formatter(val: number) {
              return val.toFixed(0)
            },
          },
        },
        xaxis: {
          type: 'datetime',
          title: {
            text: 'Time Period',
          },
        },
        noData: {
          text: `No new members have joined and are paying this month as to date`,
          align: 'center',
          verticalAlign: 'middle',
        },
      }

      return graph
    }

    return {}
  }, [data])

  return {
    renderGraph,
    loading,
  }
}

export default usePayingChart
