import type { SignUpInput } from '@graphql/generated/types'

import type { MergedForm } from '@/pages/auth/SignUp/SignUp'

export const convertToSignUpInput = ({
  firstName,
  lastName,
  email,
  password,
  recaptchaToken,
  adUrl,
  source,
}: MergedForm): SignUpInput => ({
  firstName,
  lastName,
  email,
  password,
  recaptchaToken,
  adUrl,
  source: source.label,
})
