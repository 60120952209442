import { atom } from 'recoil'

export type App = {
  isLoading: boolean
}

export const app = atom<App>({
  key: 'app',
  default: {
    isLoading: true,
  },
})
