import type { MouseEventHandler, PropsWithChildren } from 'react'

import { Loader } from '@/components/Loader'

import { Button as StyledButton } from './Button.styled'

export type ButtonAppearance = 'default' | 'outline'

type ButtonProps = {
  onClick?: MouseEventHandler<HTMLButtonElement>
  loading?: boolean
  disabled?: boolean
  appearance?: ButtonAppearance
  className?: string
  rounded?: boolean
  thin?: boolean
}

export function Button({
  children,
  loading = false,
  disabled = false,
  rounded = false,
  thin = false,
  appearance = 'default',
  ...rest
}: PropsWithChildren<ButtonProps>) {
  return (
    <StyledButton
      {...rest}
      thin={thin}
      rounded={rounded}
      appearance={appearance}
      disabled={disabled || loading}
    >
      {loading ? (
        <Loader
          color={appearance === 'outline' ? 'grape' : 'white'}
          size={thin ? '1rem' : undefined}
        />
      ) : (
        children
      )}
    </StyledButton>
  )
}
