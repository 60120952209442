import styled from 'styled-components'

import { Accordion, Button } from '@/components'
import { Expander } from '@/components/Accordion/Accordion.styled'
import {
  flexCenter,
  flexColumn,
  flexRow,
  oneLineText,
  scrollbar,
  typography,
} from '@/styles/utils'

export const Header = styled.header`
  padding: 1rem;
  ${typography.h3()};
  background-color: ${({ theme }) => theme.color.haiti};
  color: ${({ theme }) => theme.color.white};
  ${oneLineText()};
`

export const Container = styled.div`
  border-radius: 18px;
  background-color: ${({ theme }) => theme.color.mangolia};
  overflow: hidden;
  max-height: 80vh;
  width: 40rem;
  ${flexColumn()};
`

export const Content = styled.div`
  font-size: initial;
  max-height: 800px;
  overflow-y: auto;
  padding: 1rem;

  a {
    color: inherit;
  }
`

export const Tabs = styled.div`
  ${flexRow()};
`

export const TabButton = styled(Button)`
  width: 8rem;
  overflow: auto;
  white-space: unset;
  height: auto;
  margin: 1rem 0rem 0rem 1rem;
  padding: 0rem;
  border-radius: 8px 8px 0px 0px;

  &:hover {
    background-color: ${({ theme }) => theme.color.grape};
    color: ${({ theme }) => theme.color.white};
  }
`

export const Hr = styled.hr`
  margin: 0rem 1rem;
  border: 2px solid ${({ theme }) => theme.color.grape};
  color: ${({ theme }) => theme.color.grape};
`

export const LoaderContainer = styled.div`
  flex: 1;
  ${flexCenter()};
  padding: 1rem;
`

export const Body = styled.div`
  max-height: 80vh;
  padding: 1rem;
  overflow-y: auto;
  ${scrollbar('white')};
  ${flexColumn()};
  gap: 0.5rem;
`

export const Unit = styled(Accordion)`
  padding: 0;
  color: ${({ theme }) => theme.color.black};
  ${typography.bodyBold()};
  gap: 0;

  ${Expander} {
    &:before,
    &:after {
      background-color: ${({ theme }) => theme.color.black};
    }
  }
`

export const TitleUnitsAndLessons = styled.p`
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  font-size: ${({ theme }) => theme.fontSize.big};
  border-radius: 10px;
`

export const Lessons = styled.ul`
  ${flexColumn()};
  ${typography.body()};
  gap: 0.25rem;
  padding: 0.5rem 1rem 1rem 1rem;
`

export const VideoContainer = styled.div`
  padding: 1rem 2rem;
  margin: auto;
`

export const IFrame = styled.iframe`
  border-radius: 5px;
  width: 30rem;
  height: 20rem;
`
