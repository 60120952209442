import styled from 'styled-components'

import { flexColumn, transitions, typography } from '@/styles/utils'

export const Content = styled.div`
  ${flexColumn()}
  gap: 0.5rem;
  align-items: center;
`

export const Title = styled.h3`
  ${typography.h3};
  font-size: ${({ theme }) => theme.fontSize.huge};
`

export const Paragraph = styled.p`
  text-align: left;
`

export const Step = styled.div`
  width: 1.5rem;
  height: 1.5rem;
  min-width: 1.5rem;
  border-radius: 50%;
  ${transitions({})('background-color', 'border')};
  background-color: ${({ theme }) => theme.color.grape};
  border: 1px solid ${({ theme }) => theme.color.lilacBush};
  text-align: center;
  color: ${({ theme }) => theme.color.white};
`

export const NextSteps = styled.div`
  display: flex;
  column-gap: 10px;
  width: 100%;
  align-items: center;
`
