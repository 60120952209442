import styled, { css } from 'styled-components'

import { media } from '@/styles/media'

export const Main = styled.div`
  margin: 9.75rem 0;
  width: 100%;

  ${media.tablet(
    () => css`
      margin: 8rem 0;
    `,
  )};
`

export const ImageContainer = styled.div`
  max-width: 45rem;
  margin: auto;
  position: relative;

  ${media.mobile(
    () => css`
      margin: 0 1rem;
    `,
  )};
`

export const ImageCourses = styled.img`
  box-shadow: 8px 8px 0px ${({ theme }) => theme.color.sweetLucidDreams};
  border-radius: 2rem;
`

export const ImageAnalytics = styled.img`
  position: absolute;
  bottom: -10rem;
  left: 5rem;
  width: auto;

  ${media.mobile(
    () => css`
      height: 3.5rem;
      bottom: -5rem;
      left: -2rem;
    `,
  )};
`
