import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Loader, Modal } from '@/components'
import type { useModalControls } from '@/components/Modal/useModalControls'
import type { PlanOutput } from '@/graphql/generated/types'
import {
  SubscriptionPlanInterval,
  useCreatePaymentSessionMutation,
  useSubscriptionFamilyPlansQuery,
  useSubscriptionPlansQuery,
} from '@/graphql/generated/types'
import { useChild } from '@/store/parent/child'
import { useParentProfile } from '@/store/user'

import {
  BillingText,
  Body,
  Bullet,
  Bullets,
  Check,
  Container,
  CrossedPrice,
  Header,
  IntervalStatus,
  LoadingContainer,
  PrePostPriceText,
  Price,
  PriceBulletsContainer,
  Subscribe,
} from './UpgradePlanModal.styled'

type UpgradePlanModalProps = Pick<
  ReturnType<typeof useModalControls>,
  'isOpen' | 'onClose'
>
type PlanCard = { price: string; bullets: string[] }

const familyBullets = [
  'newBulletAdFree',
  'newBulletDownload',
  'newBulletAI',
  'newBulletUpgrades',
  'newBulletCS',
]

export function UpgradePlanModal({ isOpen, onClose }: UpgradePlanModalProps) {
  const { t } = useTranslation('parent', {
    keyPrefix: 'home',
  })
  const { t: ts } = useTranslation('parent', {
    keyPrefix: 'childProfile',
  })
  const { current } = useChild()
  const { profile: parentProfile } = useParentProfile()
  const [isYearly, setIsYearly] = useState(true)
  const [isFamilyAccount, setIsFamilyAccount] = useState(true)
  const { data, loading } = useSubscriptionPlansQuery()
  const { data: familyPlanData, loading: familyPlanLoading } =
    useSubscriptionFamilyPlansQuery()
  const [familyAnnualPlan, setFamilyAnnualPlan] = useState<
    PlanOutput | undefined
  >()

  const [createPaymentSession, { loading: createSessionLoading }] =
    useCreatePaymentSessionMutation({
      onCompleted({ createPaymentSession }) {
        window.location.href = createPaymentSession
      },
    })

  const getPrice = (key: string, price: string) => `${t(key, { price })}`

  // const extractPrice = (priceStr: string) => {
  //   const startIndex = priceStr.indexOf('$') ?? -1

  //   if (startIndex === -1) {
  //     return -1
  //   }

  //   const endIndex = priceStr.indexOf(' ', startIndex) ?? -1
  //   if (endIndex === -1) {
  //     const price = parseFloat(
  //       priceStr.substring(startIndex + 1) ?? -1,
  //     ).toFixed(2)

  //     return parseFloat(price)
  //   }

  //   if (endIndex !== -1 && endIndex > startIndex) {
  //     const price = parseFloat(
  //       priceStr.substring(startIndex + 1, endIndex) ?? -1,
  //     ).toFixed(2)
  //     return parseFloat(price)
  //   }

  //   return -1
  // }

  const getMonthlyPrice = () => {
    const monthlyPlan = data?.subscriptionPlans.find(
      ({ interval }) => interval === SubscriptionPlanInterval.Month,
    )

    if (!monthlyPlan) {
      return ''
    }

    return (
      <Price>
        <PrePostPriceText>{monthlyPlan.prePriceText}</PrePostPriceText>
        {monthlyPlan.price}
        <PrePostPriceText>{monthlyPlan.postPriceText}</PrePostPriceText>
      </Price>
    )
    // const monthlyPlan = data?.subscriptionPlans.find(
    //   ({ interval }) => interval === SubscriptionPlanInterval.Month,
    // )

    // if (!monthlyPlan) {
    //   return ''
    // }

    // const monthlyPrice = extractPrice(monthlyPlan.price)

    // if (monthlyPrice === -1) {
    //   return <Price>{monthlyPlan.price}</Price>
    // }

    // return (
    //   <Price>
    //     <PrePostPriceText>{monthlyPlan.prePriceText}</PrePostPriceText>
    //     {ts('lbPriceMonth', { price: monthlyPrice })}
    //     <PrePostPriceText>{monthlyPlan.postPriceText}</PrePostPriceText>
    //   </Price>
    // )
  }

  const getAnnualPrice = () => {
    const annualPlan = data?.subscriptionPlans.find(
      ({ interval }) => interval === SubscriptionPlanInterval.Year,
    )

    const monthlyPlan = data?.subscriptionPlans.find(
      ({ interval }) => interval === SubscriptionPlanInterval.Month,
    )

    if (!monthlyPlan || !annualPlan) {
      return ''
    }

    return (
      <Price>
        <PrePostPriceText>{annualPlan.prePriceText}</PrePostPriceText>
        {annualPlan.price} <CrossedPrice>({monthlyPlan.price})</CrossedPrice>
        <PrePostPriceText>{annualPlan.postPriceText}</PrePostPriceText>
      </Price>
    )

    // const monthlyPrice = extractPrice(monthlyPlan.price)
    // const annualPrice = extractPrice(annualPlan.price)

    // if (monthlyPrice === -1) {
    //   if (annualPrice !== -1) {
    //     return <Price>{ts('lbPrice', { price: annualPrice })}</Price>
    //   }

    //   return <Price>{annualPlan.price}</Price>
    // }

    // const bigAnnualPrice = parseFloat((monthlyPrice * 12).toFixed(2))

    // return (
    //   <Price>
    //     <PrePostPriceText>{annualPlan.prePriceText}</PrePostPriceText>
    //     {ts('lbPrice', { price: annualPrice })}{' '}
    //     <CrossedPrice>
    //       ({ts('lbPrice', { price: bigAnnualPrice })})
    //     </CrossedPrice>
    //     <PrePostPriceText>{annualPlan.postPriceText}</PrePostPriceText>
    //   </Price>
    // )
  }

  const getIconPriceAndBullets = (
    interval: SubscriptionPlanInterval,
    price: string,
  ): PlanCard => {
    const map: Record<SubscriptionPlanInterval, PlanCard> = {
      [SubscriptionPlanInterval.Month]: {
        price: getPrice('priceMonthPopup', price),
        bullets: [
          'newBulletAdFree',
          'newBulletDownload',
          'newBulletAI',
          'newBulletUpgrades',
          'newBulletCS',
        ],
      },
      [SubscriptionPlanInterval.Year]: {
        price: getPrice('priceMonthPopup', price),
        bullets: [
          'newBulletAdFree',
          'newBulletDownload',
          'newBulletAI',
          'newBulletUpgrades',
          'newBulletCS',
        ],
      },
    }

    return map[interval]
  }

  useEffect(() => {
    if (parentProfile) {
      setIsFamilyAccount(Boolean(parentProfile.isFamilyAccount))
    }
  }, [parentProfile])

  useEffect(() => {
    if (!familyPlanData) {
      return
    }

    setFamilyAnnualPlan(
      familyPlanData.subscriptionFamilyPlans.find(
        ({ interval }) => interval === SubscriptionPlanInterval.Year,
      ),
    )
  }, [familyPlanData])

  if (!current) {
    return null
  }

  const loadIndividualPriceAndBullets = () => {
    if (loading || !data || !data.subscriptionPlans) {
      return
    }

    const subPlan = data.subscriptionPlans.find(({ interval }) =>
      isYearly
        ? interval === SubscriptionPlanInterval.Year
        : interval === SubscriptionPlanInterval.Month,
    )

    if (!subPlan) {
      return
    }

    const { interval, price: rawPrice, stripePriceId } = subPlan

    const { bullets } = getIconPriceAndBullets(interval, rawPrice)
    return (
      <PriceBulletsContainer>
        {interval === SubscriptionPlanInterval.Year && getAnnualPrice()}
        {interval === SubscriptionPlanInterval.Month && getMonthlyPrice()}

        <Bullets>
          {bullets.map((bullet) => (
            <Bullet key={bullet}>
              <Check icon="checkMarkPurple" />
              {ts(bullet)}
            </Bullet>
          ))}
        </Bullets>

        <Subscribe
          thin
          loading={createSessionLoading}
          onClick={() => {
            createPaymentSession({
              variables: {
                childId: current._id,
                priceId: stripePriceId,
              },
            })
          }}
        >
          {t(current?.isTrialAvailable ? 'startFreeTrial' : 'subscribe')}
        </Subscribe>
      </PriceBulletsContainer>
    )
  }

  const loadFamilyPriceAndBullets = () => {
    if (!familyAnnualPlan) {
      return
    }

    const { price, stripePriceId, prePriceText, postPriceText } =
      familyAnnualPlan

    return (
      <PriceBulletsContainer>
        <Price>
          <PrePostPriceText>{prePriceText}</PrePostPriceText>
          {price}
          <PrePostPriceText>{postPriceText}</PrePostPriceText>
        </Price>

        <Bullets>
          {familyBullets.map((bullet) => (
            <Bullet key={bullet}>
              <Check icon="checkMarkPurple" />
              {ts(bullet)}
            </Bullet>
          ))}
        </Bullets>

        <Subscribe
          thin
          loading={createSessionLoading}
          onClick={() => {
            createPaymentSession({
              variables: {
                childId: current._id,
                priceId: stripePriceId,
              },
            })
          }}
        >
          {t(current?.isTrialAvailable ? 'startFreeTrial' : 'subscribe')}
        </Subscribe>
      </PriceBulletsContainer>
    )
  }

  return (
    <Modal
      shouldCloseOnOverlayClick
      isOpen={isOpen}
      closePosition={{
        top: '1rem',
        right: '100rem',
      }}
      onRequestClose={onClose}
    >
      <Container
        onClick={(event) => {
          event.stopPropagation()
        }}
      >
        {!isFamilyAccount && (
          <>
            <Header>
              <BillingText>Monthly billing</BillingText>
              <IntervalStatus
                isYearly={isYearly}
                onClick={() => {
                  setIsYearly(!isYearly)
                }}
              />
              <BillingText>Yearly billing</BillingText>
            </Header>
            <Body>
              {loading && (
                <LoadingContainer>
                  <Loader color="grape" />
                </LoadingContainer>
              )}
              {!loading &&
                data &&
                data.subscriptionPlans &&
                loadIndividualPriceAndBullets()}
            </Body>
          </>
        )}

        {isFamilyAccount && (
          <>
            <Header>
              <BillingText>{t('familyFirstPlan')}</BillingText>
            </Header>
            <Body>
              {familyPlanLoading && (
                <LoadingContainer>
                  <Loader color="grape" />
                </LoadingContainer>
              )}
              {!familyPlanLoading &&
                familyAnnualPlan &&
                loadFamilyPriceAndBullets()}
            </Body>
          </>
        )}
      </Container>
    </Modal>
  )
}
