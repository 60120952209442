import { useEffect, useRef, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import { defaultPage } from '@/core/const'

export const useInitialPage = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const wasPageSetRef = useRef(false)
  const [initialPage, setInitialPage] = useState<number>()

  const getParamPage = () => {
    const page = searchParams.get('page')

    if (!page) {
      return
    }

    return Number(page)
  }

  useEffect(() => {
    if (wasPageSetRef.current) {
      return
    }

    const paramPage = getParamPage()

    if (paramPage) {
      setInitialPage(paramPage)
      wasPageSetRef.current = true
      return
    }

    setSearchParams(
      {
        page: String(defaultPage),
      },
      {
        replace: true,
      },
    )
    setInitialPage(defaultPage)
    wasPageSetRef.current = true
  }, [searchParams])

  return {
    page: initialPage,
  }
}
