import { useState } from 'react'

export type UseModalControlsReturn = {
  isOpen: boolean
  onOpen: () => void
  onClose: () => void
}

export const useModalControls = (): UseModalControlsReturn => {
  const [isOpen, setIsOpen] = useState(false)

  const onOpen = () => {
    setIsOpen(true)
  }

  const onClose = () => {
    setIsOpen(false)
  }

  return { isOpen, onOpen, onClose }
}
