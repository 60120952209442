import styled, { css } from 'styled-components'

import { media } from '@/styles/media'
import {
  changeOpacity,
  fadeInAnimation,
  flexCenter,
  flexColumn,
  multilineTruncateText,
  transitions,
  typography,
} from '@/styles/utils'

export const Container = styled.div`
  position: relative;
  ${fadeInAnimation()};
  width: 100%;
  gap: 1rem;
  ${flexColumn()};
`

export const LoaderContainer = styled.div`
  pointer-events: none;
  ${flexCenter()};
  background-color: ${({ theme }) =>
    changeOpacity(theme.color.blueChalk, 0.75)};
  position: absolute;
  inset: 0;
`

export const Count = styled.h4`
  ${typography.body};
`

export const Bold = styled.span`
  ${typography.bodyBold()};
`

export const StyledCourses = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1.5rem;

  ${media.desktop(
    () => css`
      grid-template-columns: repeat(3, 1fr);
    `,
  )}

  ${media.tablet(
    () => css`
      grid-template-columns: repeat(2, 1fr);
    `,
  )}
`

export const Course = styled.div<{ isDisabled: boolean }>`
  ${flexColumn()};
  padding: 0.5rem;
  border-radius: 18px;
  overflow: hidden;
  box-shadow: 2px 4px 10px
    ${({ theme }) => changeOpacity(theme.color.black, 0.15)};
  gap: 1rem;
  ${transitions({})('transform', 'opacity')};
  &:hover {
    transform: scale(1.03);
  }

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      opacity: 0.33;
      pointer-events: none;
      cursor: not-allowed;
    `}
`

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 18px;
  overflow: hidden;
`

export const Name = styled.p`
  height: 2.5rem;
  ${typography.bodyBold()};
  ${multilineTruncateText()};
`

export const Price = styled.p`
  color: ${({ theme }) => theme.color.doveGray};
`

export const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const Info = styled.button`
  display: block;
  height: 2rem;
  width: 2rem;
  ${typography.h3};
  color: ${({ theme }) => theme.color.white};
  background-color: ${({ theme }) => theme.color.grape};
  border-radius: 50%;
  ${flexCenter()};
`
