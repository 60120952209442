import styled, { css } from 'styled-components'

import { media } from '@/styles/media'

import type { LandingColors } from '../../Landing.styled'

export const Words = styled.p`
  margin-bottom: 1.5rem;
  margin-top: 1.5rem;
  font-weight: ${({ theme }) => theme.fontWeight.bigger};
  font-size: ${({ theme }) => theme.landingSpecialFontSize.great};

  ${media.mobile(
    () => css`
      font-size: ${({ theme }) => theme.landingSpecialFontSize.normal};
    `,
  )};
`

export const Container = styled.div`
  text-align: left;
  padding: 0 1rem;
`

export const HrLine = styled.hr<{ bgColor: LandingColors }>`
  border: 2px solid
    ${({ bgColor, theme }) =>
      (bgColor === 'Green' && theme.color.seductiveThorns) ||
      (bgColor === 'Blue' && theme.color.greekSea) ||
      (bgColor === 'Purple' && theme.color.classicBouquet) ||
      (bgColor === 'Red' && theme.color.jinzaSaddlower) ||
      (bgColor === 'Yellow' && theme.color.philippineGoldenYellow) ||
      theme.color.pecanVeneer};
`
