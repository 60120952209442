import styled, { css } from 'styled-components'

import bgBlurTwo from '@/assets/images/landing/bg-blur-two.webp'
import { media } from '@/styles/media'
import { absoluteCenter, flexRow } from '@/styles/utils'

export const Main = styled.div`
  padding: 9.75rem 0;
  border-radius: 4rem;

  ${media.tablet(
    () => css`
      padding: 9.75rem 1rem;
    `,
  )};

  ${media.mobile(
    () => css`
      padding: 8rem 1rem;
    `,
  )};
`
export const UnlimitedContainer = styled.div`
  background: ${({ theme }) => theme.color.blueViolet};
  color: ${({ theme }) => theme.color.white};
  max-width: 75rem;
  width: 100%;
  margin: auto;
  padding: 4rem;
  text-align: left;
  border-radius: 2rem;
  box-shadow: 8px 8px 0px ${({ theme }) => theme.color.americanBlue};
  position: relative;

  background: url(${bgBlurTwo}) no-repeat,
    linear-gradient(
      112.94deg,
      ${({ theme }) => theme.color.violetMagican} -6.08%,
      ${({ theme }) => theme.color.kingTriton} 121.92%
    );

  background-position: top right, center;
  background-size: auto 100%, auto;

  ${media.tablet(
    () => css`
      padding: 2rem;
      padding-top: 16rem;
    `,
  )};

  ${media.mobile(
    () => css`
      padding-top: 10rem;
    `,
  )};
`

export const UnlimitedHeader = styled.p`
  font-size: ${({ theme }) => theme.landingSpecialFontSize.gigantic};
  font-weight: ${({ theme }) => theme.fontWeight.boldest};
  margin-bottom: 3.5rem;

  ${media.mobile(
    () => css`
      font-size: ${({ theme }) => theme.landingSpecialFontSize.massive};
    `,
  )};
`
export const BulletPoints = styled.div`
  ${flexRow()}
  gap: 1rem;
  margin-top: 0.6rem;
`
export const Checkmark = styled.img``

export const BulletLine = styled.p`
  font-size: ${({ theme }) => theme.landingSpecialFontSize.bigger};
  font-weight: ${({ theme }) => theme.fontWeight.medium};

  ${media.mobile(
    () => css`
      font-size: ${({ theme }) => theme.landingSpecialFontSize.big};
    `,
  )};
`

const imageSizeBig = 27
const imageSizeMid = 20
const imageSizeSmall = 14

export const ImageFire = styled.img`
  position: absolute;
  right: 0rem;
  height: ${imageSizeBig}rem;
  width: auto;

  ${media.tablet(
    () => css`
      height: ${imageSizeMid}rem;

      ${absoluteCenter()}
      top: ${imageSizeMid / 3}rem;
    `,
  )}

  ${media.mobile(
    () => css`
      height: ${imageSizeSmall}rem;
      top: ${imageSizeSmall / 4}rem;
    `,
  )}
`

export const ImageFireLines = styled.img`
  position: absolute;
  right: ${imageSizeBig / 4}rem;
  top: 0rem;

  ${media.tablet(
    () => css`
      height: 3.5rem;
      right: 35%;
      top: -${imageSizeMid / 3}rem;
    `,
  )}
  ${media.mobile(() => css``)};
`

export const ImageCornerLines = styled.img`
  position: absolute;
  left: -7rem;
  bottom: -7rem;
  height: 5.5rem;
  width: auto;

  ${media.tablet(
    () => css`
      height: 4rem;
      left: -2rem;
      bottom: -4rem;
    `,
  )}
  ${media.mobile(
    () => css`
      height: 3rem;
      bottom: -3rem;
    `,
  )};
`

export const Tagline = styled.p`
  color: ${({ theme }) => theme.color.white};
  margin-top: 2rem;
  font-size: ${({ theme }) => theme.landingSpecialFontSize.tiny};
  font-weight: ${({ theme }) => theme.fontWeight.light};
`
