import { GraphQLError } from 'graphql'

export class TokensEmptyError extends GraphQLError {
  constructor() {
    super('TOKENS_EMPTY')
  }
}

export class PdfSaveError extends GraphQLError {
  constructor() {
    super('PDF_SAVE_ERROR')
  }
}

export class PdfUploadError extends GraphQLError {
  constructor() {
    super('PDF_UPLOAD_ERROR')
  }
}

export class GetLocationError extends GraphQLError {
  constructor() {
    super('GET_LOCATION_ERROR')
  }
}
