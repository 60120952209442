import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import signUpMarketplace from '@/assets/images/sign-up-marketplace.webp'
import {
  ImageContainer,
  ImageCourses,
} from '@/pages/common/Landing/components/Library/Library.styled'
import { LineListing } from '@/pages/common/Landing/components/LineListing'
import {
  ImageTextFlexContainer,
  LinesContainer,
  MainTitle as MainTitleLanding,
} from '@/pages/common/Landing/Landing.styled'
import { Main as StyledMain } from '@/styles/components'

export const Main = styled(StyledMain)`
  font-family: MuseoSans, sans-serif;
  background-color: ${({ theme }) => theme.color.blueChalk};
  padding-top: 0px;
`

export const MaxWidthContainer = styled.div`
  width: 100%;
  padding: 1rem 0rem;
  text-align: center;
  background-color: ${({ theme }) => theme.color.blueChalk};
`

const MarketplaceImage = styled(ImageCourses)`
  box-shadow: none;
  max-height: 40rem;
`

const MainTitle = styled(MainTitleLanding)`
  margin-top: 2rem;
  margin-bottom: 0rem;
`

const lines = [
  'accessThousands',
  'flexibleScheduling',
  'increaseEngagement',
  'gapAssessment',
]

export function Footer() {
  const { t } = useTranslation('landing')

  return (
    <MaxWidthContainer>
      <Main>
        <MainTitle>{t('titles.aLibraryThat')}</MainTitle>

        <ImageTextFlexContainer>
          <LinesContainer>
            <LineListing lines={lines} color="Purple" />
          </LinesContainer>

          <ImageContainer>
            <MarketplaceImage
              src={signUpMarketplace}
              loading="lazy"
              alt="courses view"
            />
          </ImageContainer>
        </ImageTextFlexContainer>
      </Main>
    </MaxWidthContainer>
  )
}
