import styled from 'styled-components'

import { flexRow } from '@/styles/utils'

export const FlexRow = styled.div`
  ${flexRow()}
  gap:1em;
  flex-wrap: wrap;
`
export const GraphContainers = styled.div`
  ${flexRow()}
  gap:1em;
  flex-wrap: wrap;
  flex: 1;
`
export const Card = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75em;
  background-color: ${({ theme }) => theme.color.white};
  border-radius: 1rem;
  padding: 1rem;
`
