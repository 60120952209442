import { Outlet } from 'react-router-dom'
import styled, { css } from 'styled-components'

import { Navbar } from '@/pages/parent/components/Navbar'
import {
  Main as StyledMain,
  MaxWidthContainer as StyledMaxWidthContainer,
  parentHeaderHeight,
  parentHeaderHeightMobile,
} from '@/styles/components'
import { media } from '@/styles/media'

export const Main = styled(StyledMain)`
  padding-top: ${parentHeaderHeight};
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  background-color: ${({ theme }) => theme.color.blueChalk};
  justify-content: center;
  ${media.mobile(
    () => css`
      padding-top: ${parentHeaderHeightMobile};
    `,
  )}
`

export const MaxWidthContainer = styled(StyledMaxWidthContainer)`
  padding: 0 2rem;

  ${media.mobile(
    () => css`
      padding: 0 1rem;
    `,
  )}
`

export function ParentContainer() {
  return (
    <>
      <Navbar />
      <Outlet />
    </>
  )
}
