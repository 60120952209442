import { useRequestResetPasswordMutation } from '@graphql/generated/types'
import { setFieldErrors } from '@graphql/utils/errors'
import { joiResolver } from '@hookform/resolvers/joi/dist/joi'
import * as Joi from 'joi'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'

import { Button, Input } from '@/components'
import { schoolioBookStoreUrl } from '@/core/const'
import { useRecaptcha, useTitle } from '@/core/hooks'
import { validators } from '@/core/validation'
import { Form, FormColumn, SchoolioLogo, Title } from '@/pages/auth/Auth.styled'
import { route } from '@/pages/routes'

import { Buttons, Cancel, Description, Email } from './ForgotPassword.styled'

type ForgotPasswordSchema = {
  usernameOrEmail: string
}

const schema = Joi.object<ForgotPasswordSchema>({
  usernameOrEmail: validators.stringRequired(),
})

export function ForgotPassword() {
  const { t } = useTranslation('forgotPassword')

  useTitle('forgotPassword')

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm<ForgotPasswordSchema>({
    resolver: joiResolver(schema),
    defaultValues: {
      usernameOrEmail: '',
    },
  })

  const navigate = useNavigate()
  const { isLoading, getToken } = useRecaptcha('requestResetPassword')

  const [requestResetPassword, { data, loading }] =
    useRequestResetPasswordMutation({
      onError({ graphQLErrors }) {
        setFieldErrors(graphQLErrors, setError)
      },
    })

  const onSubmit = async ({ usernameOrEmail }: ForgotPasswordSchema) => {
    const recaptchaToken = await getToken()

    requestResetPassword({
      variables: {
        usernameOrEmail,
        recaptchaToken,
      },
    })
  }

  const onBack = () => {
    navigate(route.signIn())
  }

  const openBookStore = () => {
    window.open(schoolioBookStoreUrl, '_self')
  }

  const email = data?.requestResetPassword.email

  return (
    <FormColumn>
      <SchoolioLogo onClick={openBookStore} />
      <Title>{t('forgotPassword')}</Title>
      <Description>
        {email ? (
          <>
            {t('success')} <Email>{email}.</Email>
            <br />
            <Link to={route.signIn()}>{t('backToLogin')}</Link>
          </>
        ) : (
          t('enterUsernameOrEmail')
        )}
      </Description>
      {!email && (
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Input
            label={t('form.usernameOrEmail', { ns: 'signIn' })}
            placeholder={t('form.usernameOrEmailPlaceholder', { ns: 'signIn' })}
            error={errors.usernameOrEmail?.message}
            {...register('usernameOrEmail')}
          />
          <Buttons>
            <Button loading={loading || isLoading}>{t('resetPassword')}</Button>
            <Cancel appearance="outline" onClick={onBack}>
              {t('cancel')}
            </Cancel>
          </Buttons>
        </Form>
      )}
    </FormColumn>
  )
}
