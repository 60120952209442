import type { SignInUser } from '@graphql/generated/types'
import { UserRole, useSignUpMutation } from '@graphql/generated/types'
import { getFieldErrors } from '@graphql/utils/errors'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

import { useRecaptcha, useTitle } from '@/core/hooks'
import { adUserManager } from '@/core/services/adUser'
import { childAnalytics, parentAnalytics } from '@/core/services/analytics'
import { session } from '@/core/services/session'
import type { NonNullableKeys } from '@/core/types'
import { FormColumn, Title } from '@/pages/auth/Auth.styled'
import { route } from '@/pages/routes'
import { useAuthUser } from '@/store/user'

import { AuthRoute } from '../authRoutes'
import type { InitialFormSchema } from './components'
import { Finish, InitialForm } from './components'
import { convertToSignUpInput } from './convertToSignUpInput'

export type CurrentStep = 'initial' | 'finish'

export type MergedForm = NonNullableKeys<
  InitialFormSchema & { recaptchaToken: string; adUrl: string }
>

export function SignUp() {
  const { pathname } = useLocation()
  const { t } = useTranslation('signUp')
  const { isLoading: isRecaptchaLoading, getToken } = useRecaptcha('register')

  const [currentStep, setCurrentStep] = useState<CurrentStep>('initial')
  const [initialForm, setInitialForm] = useState<InitialFormSchema>()
  const navigate = useNavigate()
  const [validationErrors, setValidationErrors] =
    useState<Record<string, string>>()

  const isLanding = pathname === AuthRoute.Landing

  const initAnalytics = ({ _id, role, email, username }: SignInUser) => {
    if (role === UserRole.Child) {
      childAnalytics.identify(_id, email, username)
      childAnalytics.emitLogin()
    } else {
      parentAnalytics.identify(_id, email, username)
      parentAnalytics.emitLogin()
    }
  }

  const [signUp, { loading }] = useSignUpMutation({
    onCompleted({ signUp: { _id, user, ...rest } }) {
      session.setRememberMe(true)
      initAnalytics(user)

      parentAnalytics.emitRegistration({
        distinct_id: _id,
        ...rest,
      })
      adUserManager.clearAdUrl()
      if (user.role === UserRole.Child) {
        const lastMoodCheckStart = user.lastMoodCheck?.start

        if (!lastMoodCheckStart) {
          return
        }

        if (new Date(lastMoodCheckStart) < new Date()) {
          return
        }

        setUser(user)
      } else {
        setUser(user)
      }

      setCurrentStep('finish')
    },
    onError({ graphQLErrors, message: reason }) {
      parentAnalytics.emitRegistrationFail({
        email: initialForm?.email,
        reason,
      })
      setValidationErrors(getFieldErrors(graphQLErrors))
    },
  })

  const { setUser } = useAuthUser()

  useEffect(() => {
    if (!isLanding) {
      navigate(route.landing())
    }
  }, [])

  useEffect(() => {
    adUserManager.handleUrl()
  }, [])

  useEffect(() => {
    parentAnalytics.emitRegistrationStart()
  }, [])

  useEffect(() => {
    if (!validationErrors) {
      return
    }

    if (validationErrors.email) {
      setCurrentStep('initial')
    }
  }, [validationErrors])

  const onSignUp = async (initialForm: InitialFormSchema) => {
    setInitialForm(initialForm)
    setValidationErrors(undefined)

    const recaptchaToken = await getToken()

    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    const mergedForm = {
      ...initialForm,
      recaptchaToken,
      adUrl: adUserManager.getAdUrl() ?? undefined,
    } as MergedForm

    signUp({
      variables: {
        input: convertToSignUpInput(mergedForm),
      },
    })
  }

  useTitle('signUp')

  return (
    <FormColumn>
      <Title>{t('signUp')}</Title>
      <InitialForm
        isLoading={loading || isRecaptchaLoading}
        emailValidationError={validationErrors?.email}
        hide={currentStep !== 'initial'}
        onSubmit={(form) => {
          setValidationErrors(undefined)
          onSignUp(form)
        }}
      />
      <Finish hide={currentStep !== 'finish'} />
    </FormColumn>
  )
}
