import Chart from 'react-apexcharts'

import { Loader } from '@/components'

import { LocationContainer } from './LocationData.styled'
import useLocationChart from './useLocationChart'

export function LocationData() {
  const { loading, renderGraph } = useLocationChart()

  return (
    <LocationContainer>
      {loading ? (
        <Loader size="4em" color="codeCurrent" />
      ) : (
        <Chart
          series={renderGraph.series}
          type="bar"
          options={renderGraph}
          height={renderGraph.chart?.height}
        />
      )}
    </LocationContainer>
  )
}
