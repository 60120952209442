import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import Backend from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    ns: [
      'signIn',
      'common',
      'error',
      'dashboard',
      'lesson',
      'signUp',
      'history',
      'langs',
      'forgotPassword',
      'verifyEmail',
      'resetPassword',
      'parent',
      'scheduleCourse',
    ],
    defaultNS: 'common',
    load: 'currentOnly',
    fallbackLng: 'en-CA',
    debug: false,
  })

export { i18n }
