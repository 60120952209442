import styled, { css } from 'styled-components'

import { media } from '@/styles/media'
import { flexColumn, flexRow } from '@/styles/utils'

export const Main = styled.div`
  ${media.tablet(
    () => css`
      padding: 0 1rem;
    `,
  )}
`

export const OrangeText = styled.p`
  color: ${({ theme }) => theme.color.fieldPoppy};
  font-size: ${({ theme }) => theme.landingSpecialFontSize.huge};
  font-weight: ${({ theme }) => theme.fontWeight.medium};

  ${media.tablet(
    () => css`
      font-size: ${({ theme }) => theme.landingSpecialFontSize.big};
    `,
  )}
`

export const ButtonsContainer = styled.div`
  ${flexRow}
  gap: 1.5rem;
  margin: auto;
  justify-content: center;

  ${media.tablet(
    () => css`
      ${flexColumn};
      gap: 1rem;
    `,
  )}
`

export const commonQuickStartStyle = () => css`
  font-size: ${({ theme }) => theme.landingSpecialFontSize.small};
  font-weight: ${({ theme }) => theme.fontWeight.light};

  ${media.tablet(
    () => css`
      font-size: ${({ theme }) => theme.landingSpecialFontSize.tiny};
    `,
  )}
`

export const NotSureText = styled.p`
  ${commonQuickStartStyle}
  margin-top: 4rem;

  ${media.tablet(
    () => css`
      margin-top: 2rem;
    `,
  )}
`

export const GetHelp = styled.a`
  color: ${({ theme }) => theme.color.fieldPoppy};
  ${commonQuickStartStyle}
  text-decoration: underline;
`
