import styled, { css } from 'styled-components'

import { Button } from '@/components'
import { Popup } from '@/styles/components'
import { media } from '@/styles/media'
import {
  changeOpacity,
  flexCenter,
  flexColumn,
  oneLineText,
  scrollbar,
  transitions,
  typography,
} from '@/styles/utils'

export const Container = styled.div`
  position: relative;

  ${media.mobile(
    () => css`
      display: none;
    `,
  )}
`

const counterPosition = '0.75rem'
const counterSize = '1.5rem'

export const CartButton = styled.button<{ count: number }>`
  position: relative;
  ${flexCenter()};
  ${transitions({})('opacity')};

  &:disabled {
    opacity: 0.5;
  }

  ${({ count }) =>
    count > 0 &&
    css`
      &:before {
        content: '${count}';
        position: absolute;
        bottom: ${counterPosition};
        left: ${counterPosition};
        background-color: ${({ theme }) => theme.color.grape};
        border-radius: 50%;
        width: ${counterSize};
        height: ${counterSize};
        ${flexCenter()};
        color: ${({ theme }) => theme.color.white};
      }
    `}
`

export const StyledPopup = styled(Popup)`
  width: 34.625rem;
  padding: 1rem;
  ${flexColumn()};
  gap: 1rem;
`

export const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const Title = styled.h3`
  ${typography.h3};
  font-size: ${({ theme }) => theme.fontSize.great};
`

export const Total = styled.p`
  ${typography.bodyBold};
  display: flex;
  justify-content: flex-end;
  padding-right: 2.5rem;
`

const closeSize = '1.5rem'

export const Close = styled.button`
  width: ${closeSize};
  height: ${closeSize};
  border-radius: 50%;
  background-color: ${({ theme }) => changeOpacity(theme.color.grape, 0.08)};
  ${flexCenter()};

  svg {
    width: 50%;
    height: 50%;
  }
`

export const Body = styled.div`
  ${flexColumn()};
  gap: 0.5rem;
  max-height: 60vh;
  overflow-y: auto;
  ${scrollbar('white')};
`

export const Student = styled.h4`
  ${typography.bodyBold()};
`

export const Courses = styled.div`
  padding: 0.5rem 1rem;
  ${flexColumn()};
  gap: 0.5rem;
`

export const Info = styled.div`
  ${flexColumn};
  ${typography.bodyBold};
  justify-content: center;
  align-items: flex-start;
  gap: 1rem;
`

const imageSize = '3rem'

export const Course = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`

export const CourseImage = styled.img`
  width: ${imageSize};
  height: ${imageSize};
  border-radius: 50%;
  object-fit: cover;
`

export const Name = styled.p`
  ${oneLineText()};
`

export const Price = styled.p`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  color: ${({ theme }) => theme.color.doveGray};
`

export const Delete = styled.button`
  display: flex;
  justify-content: flex-end;
`

export const StudentCourse = styled.div`
  ${flexColumn()};
  gap: 0.5rem;
  &:not(:last-of-type) {
    border-bottom: 1px solid ${({ theme }) => theme.color.alto};
  }
`

export const Actions = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  align-self: flex-end;
`

export const Add = styled(Button)`
  align-self: flex-end;
  max-width: fit-content;
  min-width: 4.563rem;
`
