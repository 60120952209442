import { useCallback, useEffect, useState } from 'react'

export const usePopup = () => {
  const [isOpen, setIsOpen] = useState(false)

  const onToggle = () => {
    setIsOpen((prevState) => !prevState)
  }

  const onOutClick = useCallback(() => {
    setIsOpen(false)
    document.removeEventListener('click', onOutClick)
  }, [])

  useEffect(() => {
    if (!isOpen) {
      return
    }

    document.addEventListener('click', onOutClick)
  }, [isOpen])

  return { isOpen, onToggle }
}
