import styled, { css } from 'styled-components'

import flower from '@/assets/images/landing/flower.svg'
import sun from '@/assets/images/landing/sun.svg'
import { media } from '@/styles/media'

import type { LandingColors } from '../../Landing.styled'
import { imageTextFlex } from '../../Landing.styled'

export const Main = styled.div`
  border-radius: 4rem;
  width: 100%;
  position: relative;

  background: url(${flower}) no-repeat, url(${sun}) no-repeat;
  background-position: 5% 570px, 100% 100%;

  ${media.desktop(
    () => css`
      background-position: 0% 570px, 100% 100%;
      background-size: 7.5rem, 7rem;
    `,
  )};

  ${media.tablet(
    () => css`
      background-position: 5% 500px, 100% 100%;
      background-size: 7.5rem, 7rem;
    `,
  )};

  ${media.mobile(
    () => css`
      background-position: 0% 130px, 100% 99%;
      background-size: 6rem, 5rem;
    `,
  )};
`

export const HeadingSmall = styled.p<{
  color: LandingColors
}>`
  font-family: 'SketchnoteText';
  color: ${({ color, theme }) =>
    (color === 'Green' && theme.color.seductiveThorns) ||
    (color === 'Blue' && theme.color.greekSea) ||
    (color === 'Purple' && theme.color.classicBouquet) ||
    (color === 'Red' && theme.color.jinzaSaddlower) ||
    (color === 'Yellow' && theme.color.philippineGoldenYellow) ||
    theme.color.pecanVeneer};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  font-size: ${({ theme }) => theme.landingSpecialFontSize.huge};

  ${media.mobile(
    () => css`
      font-size: ${({ theme }) => theme.landingSpecialFontSize.large};
    `,
  )};
`

export const ImageTextFlexContainer = styled.div<{
  isReverse: boolean
}>`
  ${imageTextFlex()};
  margin-top: 4rem;
  margin-bottom: 6rem;

  ${({ isReverse }) => {
    return media.tablet(
      () => css`
        flex-direction: ${isReverse ? 'column-reverse' : 'column'};
        margin-top: 2rem;
        margin-bottom: 4rem;
      `,
    )
  }}
`

export const ImageContainer = styled.div`
  margin: 1rem;
`

export const Image = styled.img<{
  shadowColor: LandingColors
}>`
  height: 22.5rem;
  max-width: 35.5rem;
  width: auto;
  object-fit: cover;
  margin: auto;
  border-radius: 2rem;
  box-shadow: 4px 4px 0px
    ${({ shadowColor, theme }) =>
      (shadowColor === 'Purple' && theme.color.astroZinger) ||
      (shadowColor === 'Red' && theme.color.wafer) ||
      (shadowColor === 'Orange' && theme.color.ladyGuinevere) ||
      (shadowColor === 'Green' && theme.color.wasabiPeanut) ||
      (shadowColor === 'Yellow' && theme.color.sprucedUp) ||
      theme.color.alaskanIce};

  ${media.tablet(
    () => css`
      height: 20.5rem;
      width: 100%;
    `,
  )};
`

export type Margin = 'Auto' | 'Left' | 'Right'

export const LinesContainer = styled.div<{ align: Margin }>`
  min-width: 22rem;
  max-width: 28rem;
  text-align: left;
  margin: ${({ align }) => (align === 'Auto' && 'auto') || 'unset'};
  margin-left: ${({ align }) => (align === 'Right' && 'auto') || 'unset'};
  margin-right: ${({ align }) => (align === 'Left' && 'auto') || 'unset'};

  ${media.tablet(
    () => css`
      margin: auto;
    `,
  )};
`

export const ButtonContainer = styled.button`
  ${media.mobile(
    () => css`
      padding: 0 1rem;
      width: 100%;
    `,
  )};
`
