import { useId, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import type { Props } from 'react-select'
import { useTheme } from 'styled-components'

import { Error, getIsError } from '@/components/Input/Error'
import type { InputProps } from '@/components/Input/Input'

import {
  classNamePrefix,
  Container,
  CreatableSelect as StyledCreatableSelect,
  Label,
  Select as StyledSelect,
} from './Select.styled'

export type Option<Value = string> = {
  value: Value
  label: string
  isAlreadyScheduled?: boolean
}

export type CreatableOption<Value = string> = Option<Value> & {
  __isNew__?: boolean
}

export type SelectProps = Omit<
  Props<Option, false>,
  'theme' | 'classNamePrefix' | 'isMulti'
> &
  Pick<InputProps, 'error' | 'label' | 'appearance'> & {
    isMulti?: boolean
    isCreatable?: boolean
  }

export function Select({
  label,
  error,
  id,
  placeholder,
  isMulti,
  isCreatable,
  isDisabled = false,
  appearance = 'default',
  ...rest
}: SelectProps) {
  const { t } = useTranslation('error')
  const generatedId = useId()
  const theme = useTheme()

  const inputId = generatedId ?? id

  const isError = useMemo(() => getIsError(error), [error])

  const FinalSelect = isCreatable ? StyledCreatableSelect : StyledSelect

  return (
    <Container disabled={isDisabled}>
      {label && (
        <Label aria-label={rest['aria-label']} htmlFor={inputId}>
          {label}
        </Label>
      )}
      <FinalSelect
        // @ts-expect-error ignore generic isMulti
        isMulti={isMulti}
        appearance={appearance}
        classNamePrefix={classNamePrefix}
        error={isError}
        id={inputId}
        styles={{
          option(base, event) {
            return {
              ...base,
              backgroundColor: event.data.isAlreadyScheduled
                ? theme.color.distilledMoss
                : theme.color.white,
            }
          },
        }}
        placeholder={placeholder ?? t('select', { ns: 'common' })}
        isDisabled={isDisabled}
        {...rest}
      />
      {error && <Error error={error} label={label} />}
    </Container>
  )
}
