import styled, { css } from 'styled-components'

import { media } from '@/styles/media'
import { flexColumn, typography } from '@/styles/utils'

export const Container = styled.div<{ hide: boolean }>`
  ${flexColumn()};
  align-items: center;
  gap: 2rem;
  width: 100%;

  ${({ hide }) =>
    hide &&
    css`
      display: none;
    `}
`
export const FlexRow = styled.div`
  display: flex;
  gap: 1rem;
  & > div {
    width: 47%;
  }

  ${media.mobile(
    () => css`
      flex-direction: column;
      gap: 0rem;
      & > div {
        width: 100%;
      }
    `,
  )}
`

export const HaveAnAccount = styled.span`
  ${typography.body()};
`
