import SVG from 'react-inlinesvg'
import styled, { css } from 'styled-components'

import book from '@/assets/images/landing/book.svg'
import planet from '@/assets/images/landing/planet.svg'
import starSingle from '@/assets/images/landing/star-single.svg'
import { media } from '@/styles/media'
import { flexColumn, flexRow, transitions } from '@/styles/utils'

export const Main = styled.div`
  margin-top: 8rem;
  padding: 8rem 0 4rem 0;
  background: url('${book}') no-repeat, url('${planet}') no-repeat,
    url('${starSingle}') no-repeat,
    linear-gradient(
      123.25deg,
      ${({ theme }) => theme.color.violetMagican} 25.29%,
      ${({ theme }) => theme.color.kingTriton} 156.78%
    );
  background-position: -3% 30%, 105% 80%, 95% 10%;
  border-radius: 4rem 4rem 0 0;
  width: 100%;

  ${media.tablet(
    () => css`
      margin-top: 4rem;
      padding: 4rem 1rem 2rem 1rem;
      background-size: 118px 86px, 100px 155px, 38px 35px, 100%;
    `,
  )}
`

export const MiddleContainer = styled.div`
  max-width: 75rem;
  margin: auto;
`

export const FlexContainer = styled.div`
  ${flexRow}
  justify-content: space-between;
  margin: auto;

  ${media.tablet(
    () => css`
      ${flexColumn}
      gap: 2rem;
    `,
  )}
`

export const Logo = styled.img`
  height: 2.2rem;
  width: auto;

  ${media.tablet(
    () => css`
      height: 1.2rem;
      width: 6.25rem;
      margin: auto;
    `,
  )}
`

export const Links = styled.div`
  ${flexColumn}
  gap: 1.5rem;
  text-align: left;

  ${media.tablet(
    () => css`
      gap: 1rem;
      text-align: center;
    `,
  )}
`

export const Link = styled.a`
  color: ${({ theme }) => theme.color.white};
  font-size: ${({ theme }) => theme.landingSpecialFontSize.normal};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  cursor: hover;
  text-decoration: none;

  ${media.tablet(
    () => css`
      font-size: ${({ theme }) => theme.landingSpecialFontSize.small};
    `,
  )}

  &:hover {
    color: ${({ theme }) => theme.color.uscGold};
    ${transitions({})('color')}
  }
`

export const SocialMediaContainer = styled.div`
  ${flexRow}
  gap: 1rem;

  ${media.tablet(
    () => css`
      margin: auto;
    `,
  )}
`

export const SocialLink = styled.a`
  cursor: pointer;
`

export const SvgIcon = styled(SVG)`
  height: 42px;
  width: auto;

  ${media.tablet(
    () => css`
      height: 33px;
    `,
  )}

  &:hover {
    path {
      ${transitions({})('fill')}
      fill: ${({ theme }) => theme.color.uscGold};
    }
  }
`

export const Hr = styled.hr`
  border: 1px solid ${({ theme }) => theme.color.seriousCloud};
  margin: 4rem 0;

  ${media.tablet(
    () => css`
      margin: 2rem 0;
    `,
  )}
`

export const Rights = styled.p`
  color: ${({ theme }) => theme.color.sweetLucidDreams};
  font-size: ${({ theme }) => theme.landingSpecialFontSize.small};
  font-weight: ${({ theme }) => theme.fontWeight.light};
  text-align: center;

  ${media.tablet(
    () => css`
      font-size: ${({ theme }) => theme.landingSpecialFontSize.tiny};
    `,
  )}
`
