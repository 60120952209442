import styled, { css } from 'styled-components'

import type { LandingColors } from '@/pages/common/Landing/Landing.styled'
import { imageTextFlex } from '@/pages/common/Landing/Landing.styled'
import { media } from '@/styles/media'

export const CardContainer = styled.div<{
  color: LandingColors
  isReverse?: boolean
}>`
  ${imageTextFlex};
  max-width: 75rem;
  margin: auto;
  overflow: hidden;
  border-radius: 2rem;
  gap: 0;
  margin: 2rem auto;
  position: relative;
  cursor: grab;

  background-color: ${({ color, theme }) =>
    (color === 'Purple' && theme.color.placeboFuchsia) ||
    (color === 'Red' && theme.color.peachBreeze) ||
    (color === 'Green' && theme.color.babyTooth) ||
    (color === 'Blue' && theme.color.aliceBlue) ||
    theme.color.fieldPoppy};
  box-shadow: 8px 8px 0px
    ${({ color, theme }) =>
      (color === 'Purple' && theme.color.sweetLucidDreams) ||
      (color === 'Red' && theme.color.wafer) ||
      (color === 'Green' && theme.color.wasabiPeanut) ||
      (color === 'Blue' && theme.color.eternalElegance) ||
      theme.color.fieldPoppy};

  ${media.tablet(
    () => css`
      width: 22.75rem;
      max-width: 22.75rem;
      height: 42rem;
      margin: 1rem auto;
      display: flex !important;
      gap: 0;
    `,
  )};

  ${({ isReverse }) => {
    return media.tablet(
      () => css`
        flex-direction: ${isReverse ? 'column-reverse' : 'column'};
      `,
    )
  }}
`

export const ImageContainer = styled.div`
  flex: 1 1 0;
`

export const Image = styled.img`
  min-height: 20rem;
  object-fit: cover;

  ${media.tablet(
    () => css`
      min-height: none;
      height: auto;
    `,
  )};
`

export const TextContainer = styled.div`
  flex: 1 1 0;
  text-align: left;
`

export const ColorText = styled.p<{ color: LandingColors }>`
  font-family: 'SketchnoteText';
  padding: 2rem;
  color: ${({ color, theme }) =>
    (color === 'Purple' && theme.color.violetMagican) ||
    (color === 'Red' && theme.color.manchesterNights) ||
    (color === 'Green' && theme.color.obscureOlive) ||
    (color === 'Blue' && theme.color.wideSky) ||
    theme.color.black};
  font-size: ${({ theme }) => theme.landingSpecialFontSize.bigger};
  font-weight: ${({ theme }) => theme.fontWeight.medium};

  ${media.tablet(
    () => css`
      font-size: ${({ theme }) => theme.landingSpecialFontSize.normal};
    `,
  )};
`
