import type { WebViewerInstance } from '@pdftron/pdfjs-express'

type SetHeaderArgs = {
  instance: WebViewerInstance
  isParent: boolean
  isFamilyAccount: boolean
  isOnFreemium?: boolean
  onDownload: (backToFullScreeen: boolean) => void
  onShowPlans: VoidFunction
}

export const setHeader = ({
  instance: {
    UI,
    Core: { documentViewer, PageRotation },
  },
  isParent,
  isOnFreemium = false,
  isFamilyAccount,
  onDownload,
  onShowPlans,
}: SetHeaderArgs) => {
  UI.setHeaderItems(function (header) {
    const annotHistoryManager = documentViewer.getAnnotationHistoryManager()

    const defaultHeader = header.getHeader('default')

    const undoButton = {
      type: 'actionButton',
      dataElement: 'undoButton',
      title: 'action.undo',
      img: 'icon-operation-undo',
      onClick() {
        annotHistoryManager.undo()
      },
      isNotClickableSelector: () => !annotHistoryManager.canUndo(),
    }

    const redoButton = {
      type: 'actionButton',
      dataElement: 'redoButton',
      title: 'action.redo',
      img: 'icon-operation-redo',
      onClick() {
        annotHistoryManager.redo()
      },
      isNotClickableSelector: () => !annotHistoryManager.canRedo(),
    }

    const rectangleTool = {
      type: 'toolGroupButton',
      toolGroup: 'rectangleTools',
      dataElement: 'shapeToolGroupButton',
      title: 'annotation.rectangle',
    }

    const freeTextTool = {
      type: 'toolGroupButton',
      toolGroup: 'freeTextTools',
      dataElement: 'freeTextToolGroupButton',
      title: 'annotation.freeText',
    }

    const freeHandTool = {
      type: 'toolGroupButton',
      toolGroup: 'freeHandTools',
      dataElement: 'freeHandToolGroupButton',
      title: 'annotation.freehand',
    }

    const eraserTool = {
      type: 'toolButton',
      toolName: 'AnnotationEraserTool',
    }

    const spacer = {
      type: 'spacer',
    }

    const zoom = defaultHeader.get('zoomOverlayButton')

    const fullScreen = {
      img: 'icon-header-full-screen',
      type: 'actionButton',
      element: 'fullScreenButton',
      onClick() {
        UI.toggleFullScreen()
      },
    }

    const downloadButton = {
      img: 'icon-header-download',
      type: 'actionButton',
      element: 'downloadButton',
      onClick() {
        if (UI.isFullscreen()) {
          UI.toggleFullScreen()
          onDownload(true)
        } else {
          onDownload(false)
        }
      },
    }

    const fakeRectangleTool = {
      img: '<svg version="1.1" id="icon" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 24 24" style="enable-background:new 0 0 24 24;" xml:space="preserve"><style type="text/css"> .st0{fill:#868E96;} </style><path class="st0" d="M19.3,3.8H4.7c-1,0-1.8,0.8-1.8,1.8v12.8c0,1,0.8,1.8,1.8,1.8h14.7c1,0,1.8-0.8,1.8-1.8V5.6 C21.2,4.6,20.3,3.7,19.3,3.8z M5.1,18V6h13.8v12H5.1z"></path><rect fill="none" x="5.1" y="6" width="13.8" height="12"></rect></svg>',
      type: 'actionButton',
      element: 'fakeRectangleTool',
      onClick() {
        onShowPlans()
      },
    }

    const fakefreeTextTool = {
      img: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><defs><style>.cls-1{fill:#abb0c4;}</style></defs><title>icon - tool - text - free text</title><g><path class="cls-1" d="M19.39,4.77V7.64A.43.43,0,0,1,19,8h-.82a.41.41,0,0,1-.4-.41V6.51H13.34V18h1.54a.41.41,0,0,1,.41.41v.81a.41.41,0,0,1-.41.41H9.14a.41.41,0,0,1-.41-.41v-.81A.41.41,0,0,1,9.14,18h1.54V6.51H6.27V7.64A.41.41,0,0,1,5.86,8H5A.43.43,0,0,1,4.6,7.6V4.77A.41.41,0,0,1,5,4.36H19A.39.39,0,0,1,19.39,4.77Z"></path></g></svg>',
      type: 'actionButton',
      element: 'fakeRectangleTool',
      onClick() {
        onShowPlans()
      },
    }

    const fakefreeHandTool = {
      img: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><defs><style>.cls-1{fill:#abb0c4;}</style></defs><title>icon - tool - pen - line</title><path class="cls-1" d="M18.91,19.66a1.13,1.13,0,0,1-1.35,1.82,5.82,5.82,0,0,0-2.09-1c-3.22-.85-4.57-.29-6.82.63l-.55.23A7.42,7.42,0,0,1,5.42,22a3,3,0,0,1-2-.69,2.38,2.38,0,0,1-.87-1.66,1.81,1.81,0,0,1,.59-1.36,9.16,9.16,0,0,1,.93-.78,1.13,1.13,0,0,1,1.58.23,1.14,1.14,0,0,1-.23,1.59,2.93,2.93,0,0,0-.42.33c.27.13.84.17,2.25-.4L7.8,19a10.81,10.81,0,0,1,8.25-.72A7.89,7.89,0,0,1,18.91,19.66Z"></path><path fill="default" class="cls-1" d="M21.45,5.77a2.09,2.09,0,0,1-.62,1.51l-9.16,9.16c-.09.08-.26.26-.44.26,0,0-4.18.9-4.42.89a1,1,0,0,1-.72-.13.67.67,0,0,1-.16-.73l.8-4.53c0-.18.08-.27.26-.45L13,5.8l3.2-3.2a2.2,2.2,0,0,1,3,0l1.66,1.66A2.34,2.34,0,0,1,21.45,5.77ZM16.21,9.06,14.37,7.22,8.59,13l1.84,1.84Zm3.2-3.2L17.57,4,15.88,5.71l1.84,1.84Z"></path></svg>',
      type: 'actionButton',
      element: 'fakeRectangleTool',
      onClick() {
        onShowPlans()
      },
    }

    const freeElementsForFreemium = [
      fakeRectangleTool,
      fakefreeTextTool,
      fakefreeHandTool,
    ]

    const nextRotationMap = {
      [PageRotation.E_0]: PageRotation.E_90,
      [PageRotation.E_90]: PageRotation.E_180,
      [PageRotation.E_180]: PageRotation.E_270,
      [PageRotation.E_270]: PageRotation.E_0,
    }

    const rotateButton = {
      type: 'actionButton',
      title: 'Rotate',
      img: '/assets/icons/rotate.svg',
      onClick() {
        const currentRotation = documentViewer.getRotation()
        documentViewer.setRotation(nextRotationMap[currentRotation])
      },
    }

    defaultHeader.update([
      undoButton,
      redoButton,
      rectangleTool,
      freeTextTool,
      freeHandTool,
      eraserTool,
      rotateButton,
      spacer,
      zoom,
      fullScreen,
    ])

    if (isFamilyAccount) {
      defaultHeader.update([
        undoButton,
        redoButton,
        rotateButton,
        spacer,
        zoom,
        fullScreen,
      ])
    } else if (isOnFreemium && isParent) {
      defaultHeader.update([
        undoButton,
        redoButton,
        ...freeElementsForFreemium,
        rotateButton,
        spacer,
        zoom,
        fullScreen,
      ])
    } else if (isOnFreemium && !isParent) {
      defaultHeader.update([
        undoButton,
        redoButton,
        rotateButton,
        spacer,
        zoom,
        fullScreen,
      ])
    }

    if (isParent) {
      defaultHeader.push(downloadButton)
    }
  })
}
