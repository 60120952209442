import styled from 'styled-components'

import { Button } from '@/components'
import { flexCenter, flexRow } from '@/styles/utils'

export const GraphContainers = styled.div`
  ${flexRow()}
  gap:1em;
  flex-wrap: wrap;
  flex: 1;
`

export const FlewRow = styled.div`
  ${flexRow()}
  gap:1em;
  flex-wrap: wrap;
`
export const Card = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75em;
  background-color: ${({ theme }) => theme.color.white};
  border-radius: 1rem;
  padding: 1rem;
  width: auto;
`
export const RowCard = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.75em;
  background-color: ${({ theme }) => theme.color.white};
  border-radius: 1rem;
  padding: 1rem;
`

export const RowCardDownload = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.75em;
  background-color: ${({ theme }) => theme.color.white};
  border-radius: 1rem;
  padding: 0 1rem;
`

export const DownloadButton = styled(Button)`
  padding: 0rem;
  height: auto;
  background-color: ${({ theme }) => theme.color.white};
  border-radius: 0;
`

export const DownloadIcon = styled.img``

export const StudentsLine = styled.p`
  font-size: ${({ theme }) => theme.landingSpecialFontSize.small};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
`

export const LoaderContainer = styled.div`
  flex: 1;
  ${flexCenter()};
`
