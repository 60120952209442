import styled from 'styled-components'

import { flexColumn } from '@/styles/utils'

export const LocationContainer = styled.div`
  ${flexColumn()};
  background-color: ${({ theme }) => theme.color.white};
  padding: 1em;
  height: auto;
  row-gap: 1em;
  border-radius: 0.75em;
`
