import type { ChildrenQuery } from '@graphql/generated/types'
import { atom } from 'recoil'
import { recoilPersist } from 'recoil-persist'

import type { ArrayElement } from '@/core/types'

const { persistAtom } = recoilPersist()

export type Child = {
  current: ArrayElement<ChildrenQuery['children']> | undefined
}

export const defaultValue = {
  current: undefined,
}

export const child = atom<Child>({
  key: 'child',
  default: defaultValue,
  effects_UNSTABLE: [persistAtom],
})
