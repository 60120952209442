import { useEffect, useState } from 'react'

const preloadImage = async (src: string) => {
  return new Promise((resolve, reject) => {
    const img = new Image()

    img.onload = () => {
      resolve(img)
    }

    img.onerror = () => {
      reject(src)
    }

    img.onabort = () => {
      reject(src)
    }

    img.src = src
  })
}

export const useImagePreloader = (images: string[]) => {
  const [areImagesPreloaded, setAreImagesPreloaded] = useState(false)

  useEffect(() => {
    let isCancelled = false

    async function effect() {
      if (isCancelled) {
        return
      }

      const imagesPromiseList: Array<Promise<any>> = []
      for (const image of images) {
        imagesPromiseList.push(preloadImage(image))
      }

      await Promise.all(imagesPromiseList)

      if (isCancelled) {
        return
      }

      setAreImagesPreloaded(true)
    }

    effect()

    return () => {
      isCancelled = true
    }
  }, [images])

  return { areImagesPreloaded }
}
