import {
  useDeleteNotificationMutation,
  useDeleteNotificationsMutation,
  useNotificationsQuery,
  useReadNotificationsMutation,
} from '@graphql/generated/types'
import { useEffect, useMemo, useState } from 'react'

export const useNotifications = () => {
  const { data, loading: isNotificationsLoading } = useNotificationsQuery({
    fetchPolicy: 'no-cache',
  })

  const [notifications, setNotifications] = useState(data?.notifications)

  const [deleteNotification, { loading: isDeleteNotification }] =
    useDeleteNotificationMutation({
      onCompleted({ deleteNotification: { _id } }) {
        setNotifications((prevState) =>
          (prevState ?? []).filter((notification) => notification._id !== _id),
        )
      },
    })

  const unreadNotifications = useMemo<NonNullable<typeof notifications>>(
    () => notifications?.filter((notification) => !notification.isRead) ?? [],
    [notifications],
  )

  const [readNotifications] = useReadNotificationsMutation({
    onCompleted({ readNotifications }) {
      setNotifications((prevState) =>
        (prevState ?? []).map((notification) =>
          readNotifications.includes(notification._id)
            ? { ...notification, isRead: true }
            : notification,
        ),
      )
    },
  })

  const [clearAllNotifications, { loading: isClearAllNotifications }] =
    useDeleteNotificationsMutation({
      onCompleted() {
        setNotifications([])
      },
    })

  useEffect(() => {
    if (!data || isNotificationsLoading) {
      return
    }

    setNotifications(data.notifications)
  }, [data, isNotificationsLoading])

  const onRead = () => {
    if (unreadNotifications.length === 0) {
      return
    }

    const ids = unreadNotifications.map(({ _id }) => _id)

    readNotifications({
      variables: {
        ids,
      },
    })
  }

  const onClearAll = () => {
    if (isClearAllNotifications) {
      return
    }

    const ids = notifications?.map(({ _id }) => _id) ?? []

    clearAllNotifications({
      variables: {
        ids,
      },
    })
  }

  const onDelete = (id: string) => {
    if (isDeleteNotification) {
      return
    }

    deleteNotification({
      variables: {
        id,
      },
    })
  }

  return {
    notifications,
    isLoading: isNotificationsLoading,
    onDelete,
    onRead,
    onClearAll,
    unreadCount: unreadNotifications.length,
    isEmpty: notifications && notifications.length === 0,
  }
}
