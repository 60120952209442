import { Suspense } from 'react'
import { Route } from 'react-router-dom'

import { FullscreenLoader } from '@/components'
import { lazyImport } from '@/core/utils/lazyImport'
import { FormColumn } from '@/pages/auth/Auth.styled'
import { AuthContainer } from '@/pages/auth/AuthContainer'
import { ForgotPassword } from '@/pages/auth/ForgotPassword'
import { ResetPassword } from '@/pages/auth/ResetPassword'
import { SignIn } from '@/pages/auth/SignIn'

import { Landing } from '../common/Landing'

const { SignUp } = lazyImport(
  async () => import('@/pages/auth/SignUp'),
  'SignUp',
)

export enum AuthRoute {
  Landing = '/',
  SignIn = '/sign-in',
  SignUp = '/sign-up',
  ForgotPassword = '/forgot-password',
  ResetPassword = '/resetPassword/:token',
}

// Unable use <AuthRoutes /> due to react-router-dom exception
export const getAuthRoutes = () => (
  <Route element={<AuthContainer />}>
    <Route path={AuthRoute.SignIn} element={<SignIn />} />
    <Route path={AuthRoute.Landing} element={<Landing />} />
    <Route
      path={AuthRoute.SignUp}
      element={
        <Suspense
          fallback={
            <FormColumn>
              <FullscreenLoader
                backgroundColor="transparent"
                isAbsolute={false}
              />
            </FormColumn>
          }
        >
          <SignUp />
        </Suspense>
      }
    />
    <Route path={AuthRoute.ForgotPassword} element={<ForgotPassword />} />
    <Route path={AuthRoute.ResetPassword} element={<ResetPassword />} />
  </Route>
)
