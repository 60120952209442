import type { UserAgentInput } from '@graphql/generated/types'
import parser from 'ua-parser-js'

export const getUserAgentData = (): UserAgentInput => {
  const { width, height } = screen
  const { browser, cpu, device, os } = parser()
  return {
    screenResolution: {
      width,
      height,
    },
    browser: {
      name: browser.name,
      version: browser.version,
    },
    cpu: cpu.architecture,
    device: {
      model: device.model,
      vendor: device.vendor,
    },
    os,
  }
}
