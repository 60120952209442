import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { useTitle } from '@/core/hooks'
import { Main, MaxWidthContainer } from '@/styles/components'
import { flexColumn, typography } from '@/styles/utils'

const StyledMain = styled(Main)`
  padding: 2rem 1rem;
  color: ${({ theme }) => theme.color.white};
`

export const StyledMaxWidthContainer = styled(MaxWidthContainer)`
  ${flexColumn()};
  gap: 1rem;
`

const Title = styled.h1`
  ${typography.h1()};
`

export const Italic = styled.p`
  font-style: italic;
`

export function SystemRequirements() {
  useTitle('systemRequirements')

  const { t } = useTranslation('common', {
    keyPrefix: 'systemRequirements',
  })

  return (
    <StyledMain>
      <StyledMaxWidthContainer>
        <Title>{t('systemRequirements')}</Title>
        <p>{t('theSchoolioPlatformIsBest')}</p>
        <p>{t('learnersCanAlsoUse')}</p>
        <p>{t('weDoHavePlans')}</p>
        <Italic>{t('androidGoAndChromeOS')}</Italic>
      </StyledMaxWidthContainer>
    </StyledMain>
  )
}
