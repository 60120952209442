import styled from 'styled-components'

import { checkmarkStick } from '@/styles/components'
import { flexCenter, flexColumn, typography } from '@/styles/utils'

const size = '0.96rem'

export const Container = styled.div`
  ${flexColumn()};
  ${typography.body()};
`

export const Label = styled.label`
  display: flex;
  align-items: center;
  gap: 0.813rem;
  color: ${({ theme }) => theme.color.black};
  cursor: pointer;
`

export const HiddenInput = styled.input``

export const Checkbox = styled.div<{ isError: boolean }>`
  position: relative;
  ${flexCenter()};
  min-width: ${size};
  width: ${size};
  height: ${size};
  border-radius: 3px;
  border: 2.39px solid
    ${({
      theme: {
        color: { iron, cerise },
      },
      isError,
    }) => (isError ? cerise : iron)};

  ${HiddenInput}:checked ~ & {
    background-color: ${({ theme }) => theme.color.grape};
    border: 2.39px solid ${({ theme }) => theme.color.grape};

    &:before {
      ${checkmarkStick()};
      height: 4px;
      rotate: -45deg;
      top: 4px;
      left: 4px;
    }

    &:after {
      ${checkmarkStick()};
      height: 7px;
      rotate: 45deg;
      bottom: 3.5px;
      left: 3px;
    }
  }
`

export const Text = styled.p``
