import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { flexCenter, flexColumn, flexRow, typography } from '@/styles/utils'

export const FormContainer = styled.div`
  ${flexColumn()};
  gap: 0.563rem;
`

export const Buttons = styled.div`
  ${flexColumn()};
  gap: 0.469rem;
`

export const Separator = styled.span`
  ${flexCenter()};
  ${typography.body()};
  &:after,
  &:before {
    content: '';
    width: 50%;
    height: 1px;
    background-color: ${({ theme }) => theme.color.doveGray};
    margin-top: 1px;
  }

  &:after {
    margin-left: 5px;
  }

  &:before {
    margin-right: 5px;
  }
`

export const ForgotPassword = styled(Link)`
  ${typography.body()};
  color: ${({ theme }) => theme.color.electricViolet};
`

export const PasswordContainer = styled.div`
  ${flexRow}
  justify-content: space-between;
`

export const PasswordInputContainer = styled.div`
  width: 100%;
`

export const EyeIcon = styled.img`
  height: 40px;
  width: 40px;
  cursor: pointer;
  margin-bottom: 25px;
  margin-top: auto;
  padding: 0.5rem;
`
