import styled, { css } from 'styled-components'

import { Main as StyledMain } from '@/styles/components'
import { media } from '@/styles/media'
import { flexColumn, flexRow } from '@/styles/utils'

export type LandingColors =
  | 'Orange'
  | 'Red'
  | 'Purple'
  | 'Green'
  | 'Blue'
  | 'Yellow'

export const Main = styled(StyledMain)`
  font-family: MuseoSans, sans-serif;
  background-color: ${({ theme }) => theme.color.white};
  padding-top: 0px;
`

export const MiniMainSignUp = styled.main`
  width: 100vw;
  min-height: 100vh;
  display: flex;
  background-color: ${({ theme }) => theme.color.blueChalk};
  overflow-x: hidden;
`

export const MaxWidthContainer = styled.div`
  width: 100%;
  text-align: center;
`

export const GetHelpLine = styled.div`
  width: 100%;
  background: linear-gradient(
    98.27deg,
    ${({ theme }) => theme.color.violetMagican} 1.4%,
    ${({ theme }) => theme.color.kingTriton} 183.31%
  );

  padding: 7px 0;
`

export const GetHelpContainer = styled.div`
  max-width: 70rem;
  display: flex;
  gap: 0.625rem;
  justify-content: flex-end;
  width: 100%;
  margin: auto;
  ${media.mobile(
    () => css`
      justify-content: center;
    `,
  )};
`

export const GetHelpText = styled.p`
  color: ${({ theme }) => theme.color.white};
  font-weight: ${({ theme }) => theme.fontWeight.light};
`

export const GetHelpLink = styled.a`
  color: ${({ theme }) => theme.color.uscGold};
  font-weight: ${({ theme }) => theme.fontWeight.light};
  text-decoration: underline;
`

export const CallIcon = styled.img``

export const Button = styled.button<{
  bgColor: LandingColors
  marginTop?: number
  marginBottom?: number
  isFullWidthMobile?: boolean
  minWidth?: number
}>`
  padding: 1rem 2rem;
  border-radius: 16px;
  background-color: ${({ bgColor, theme }) =>
    (bgColor === 'Purple' && theme.color.violetMagican) ||
    (bgColor === 'Red' && theme.color.manchesterNights) ||
    (bgColor === 'Green' && theme.color.alpineMeadow) ||
    (bgColor === 'Blue' && theme.color.wideSky) ||
    (bgColor === 'Yellow' && theme.color.philippineGoldenYellow) ||
    theme.color.fieldPoppy};
  box-shadow: 6px 6px 0px
    ${({ bgColor, theme }) =>
      (bgColor === 'Purple' && theme.color.obsidianShell) ||
      (bgColor === 'Red' && theme.color.philippineBrown) ||
      (bgColor === 'Green' && theme.color.bowserShell) ||
      (bgColor === 'Blue' && theme.color.codeCurrent) ||
      (bgColor === 'Yellow' && theme.color.sprucedUp) ||
      (bgColor === 'Orange' && theme.color.gryphonneSepiaWash)};
  font-weight: ${({ theme }) => theme.fontWeight.bigger};
  font-size: ${({ theme }) => theme.landingSpecialFontSize.great};
  color: ${({ theme }) => theme.color.white};
  margin-top: ${({ marginTop }) => (marginTop ? `${marginTop}rem` : '0rem')};
  margin-bottom: ${({ marginBottom }) =>
    marginBottom ? `${marginBottom}rem` : '0.375rem'};
  position: relative;

  ${media.mobile(
    () => css`
      font-size: ${({ theme }) => theme.landingSpecialFontSize.normal};
    `,
  )}

  ${({ isFullWidthMobile }) => {
    return media.mobile(
      () => css`
        width: ${isFullWidthMobile ? '100%' : 'auto'};
      `,
    )
  }}

  ${({ minWidth }) => css`
    min-width: ${minWidth ? minWidth : '0'}rem;
  `}
`

export const MainTitle = styled.p<{
  marginTop?: number
  marginBottom?: number
  hasNoMarginBottomMobile?: boolean
}>`
  font-size: ${({ theme }) => theme.landingSpecialFontSize.gigantic};
  font-weight: ${({ theme }) => theme.fontWeight.boldest};
  margin-top: ${({ marginTop }) => (marginTop ? `${marginTop}rem` : '0rem')};
  margin-bottom: ${({ marginBottom }) =>
    marginBottom ? `${marginBottom}rem` : '6rem'};

  ${media.mobile(
    () => css`
      font-size: ${({ theme }) => theme.landingSpecialFontSize.massive};
      padding: 0 1rem;
    `,
  )}

  ${({ hasNoMarginBottomMobile }) => {
    return media.mobile(
      () => css`
        margin-bottom: ${hasNoMarginBottomMobile ? '0rem' : '4rem'};
      `,
    )
  }}
`

export const imageTextFlex = () => css`
  ${flexRow()}
  max-width: 75rem;
  margin: auto;
  justify-content: center;
  gap: 2rem;
  width: 100%;
  position: relative;

  ${media.tablet(
    () => css`
      ${flexColumn()}
      gap: 4rem;
    `,
  )};
`

export const ImageTextFlexContainer = styled.div`
  ${imageTextFlex()};
`

export const LinesContainer = styled.div`
  min-width: 22rem;
  max-width: 28rem;
  margin: auto;
`

export const ImagePaths = styled.img`
  position: absolute;
  top: -4rem;
  right: 45%;
  width: auto;
  ${media.tablet(
    () => css`
      height: 3rem;
      top: -1.5rem;
    `,
  )};
`
