import styled from 'styled-components'

const Label = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  background-color: aquamarine;
  padding: 5px 10px;
  opacity: 0.3;
  z-index: 10;
`

export function Environment() {
  const mode = import.meta.env.MODE

  return mode !== 'production' ? (
    <Label
      onClick={() => {
        // eslint-disable-next-line no-alert
        alert(JSON.stringify(import.meta.env, undefined, 2))
      }}
    >
      {mode}
    </Label>
  ) : null
}
