import { useSetLastMoodCheckMutation } from '@graphql/generated/types'
import { useTranslation } from 'react-i18next'

import Angry from '@/assets/images/emojis/angry.gif'
import Calm from '@/assets/images/emojis/calm.gif'
import Confused from '@/assets/images/emojis/confused.gif'
import Curious from '@/assets/images/emojis/curious.gif'
import Distracted from '@/assets/images/emojis/distracted.gif'
import Excited from '@/assets/images/emojis/excited.gif'
import Happy from '@/assets/images/emojis/happy.gif'
import Nervous from '@/assets/images/emojis/nervous.gif'
import Sad from '@/assets/images/emojis/sad.gif'
import Silly from '@/assets/images/emojis/silly.gif'
import { Loader } from '@/components'
import { childAnalytics } from '@/core/services/analytics'
import { useChildProfile } from '@/store/user'

import {
  Container,
  Img,
  Item,
  LoadingContainer,
  MoodContainer,
  Paragraph,
  Skip,
  Title,
} from './Mood.styled'

export type MoodType =
  | 'angry'
  | 'happy'
  | 'calm'
  | 'sad'
  | 'excited'
  | 'distracted'
  | 'confused'
  | 'silly'
  | 'curious'
  | 'nervous'

type Emoji = {
  img: string
  mood: MoodType
}

export const emojis: Emoji[] = [
  {
    img: Angry,
    mood: 'angry',
  },
  {
    img: Happy,
    mood: 'happy',
  },
  {
    img: Calm,
    mood: 'calm',
  },
  {
    img: Sad,
    mood: 'sad',
  },
  {
    img: Excited,
    mood: 'excited',
  },
  {
    img: Distracted,
    mood: 'distracted',
  },
  {
    img: Confused,
    mood: 'confused',
  },
  {
    img: Silly,
    mood: 'silly',
  },
  {
    img: Curious,
    mood: 'curious',
  },
  {
    img: Nervous,
    mood: 'nervous',
  },
]

export type CheckTime = 'start' | 'end'

type MoodProps = {
  onClick?: VoidFunction
  checkTime: CheckTime
  paragraph: string
  title: string
  externalMoodCheckId?: string
  lastCompletedLesson?: {
    subjectId: string
    subjectSlug: string
  }
}

export function Mood({
  onClick,
  checkTime,
  paragraph,
  title,
  externalMoodCheckId,
  lastCompletedLesson,
}: MoodProps) {
  const { t } = useTranslation('common')
  const { profile, setLastMoodCheck } = useChildProfile()

  const [setLastMoodCheckMutation, { loading }] = useSetLastMoodCheckMutation({
    fetchPolicy: 'no-cache',
    onCompleted({ setLastMoodCheck: lastMoodCheck }) {
      setLastMoodCheck({
        _id: lastMoodCheck._id,
        start: lastMoodCheck.start?.date,
        end: lastMoodCheck.end?.date,
      })
    },
  })

  const onMoodClick = async (mood?: MoodType) => {
    if (loading) {
      return
    }

    const moodCheckId = externalMoodCheckId ?? profile?.lastMoodCheck?._id

    await setLastMoodCheckMutation({
      variables: {
        input: {
          _id: moodCheckId,
          checkTime,
          mood: mood ?? '',
        },
      },
    })

    if (mood) {
      childAnalytics.emitMoodCheck({
        checkTime,
        mood,
        ...lastCompletedLesson,
      })
    } else {
      childAnalytics.emitSkipMoodCheck({
        checkTime,
      })
    }

    onClick?.()
  }

  return (
    <Container>
      {loading && (
        <LoadingContainer>
          <Loader />
        </LoadingContainer>
      )}
      <Paragraph>{paragraph}</Paragraph>
      <Title>{title}</Title>
      <MoodContainer isLoading={loading}>
        {emojis.map(({ img, mood }) => (
          <Item key={mood} onClick={async () => onMoodClick(mood)}>
            <Img src={img} alt={mood} />
            {t(`mood.${mood}`)}
          </Item>
        ))}
        <Skip onClick={async () => onMoodClick()}>{t('skip')}</Skip>
      </MoodContainer>
    </Container>
  )
}
