import styled from 'styled-components'

import {
  fadeInAnimation,
  flexColumn,
  oneLineText,
  typography,
} from '@/styles/utils'

export const Container = styled.div`
  border-radius: 18px;
  background-color: ${({ theme }) => theme.color.white};
  ${flexColumn()};
  padding: 1rem;
  flex: 1;
  ${fadeInAnimation()};
`

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${({ theme }) => theme.color.snuff};
  padding-bottom: 1rem;
`

export const Title = styled.h3`
  ${typography.h3()};
  ${oneLineText()};
`

export const Actions = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`
