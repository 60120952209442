import 'react-datepicker/dist/react-datepicker.css'

import styled from 'styled-components'

import { DateRangePicker } from '../components'
import useDateQuery from '../hooks/useDateQuery'
import { FlewRow, GraphContainers } from './ChildRepots.styled'
import { ChildPieChart } from './components/ChildPieChart'
import { ChildrenData } from './components/ChildrenData'
import { ChildSubscriptions } from './components/ChildSubscriptions'
import { SubscriptionTypeChart } from './components/SubscriptionTypeChart'

const Main = styled.div`
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  row-gap: 1.2em;
  width: 100%;
  height: 100vh;
`

export function ChildReports() {
  const { date, setDate } = useDateQuery()

  return (
    <Main>
      <FlewRow>
        <DateRangePicker onChange={setDate} />
        <GraphContainers>
          <ChildPieChart date={date} />
          <SubscriptionTypeChart date={date} />
        </GraphContainers>
      </FlewRow>
      <ChildrenData />
      <ChildSubscriptions date={date} />
    </Main>
  )
}
