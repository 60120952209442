import {
  SessionStatus,
  useCheckCoursesPurchaseMutation,
} from '@graphql/generated/types'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'

import { purchaseSessionIdKey } from '@/core/const'
import { parentAnalytics } from '@/core/services/analytics'
import { ToastWithLink } from '@/pages/parent/Marketplace/ToastWithLink'
import { route } from '@/pages/routes'
import { useMarketplaceCart } from '@/store/parent/marketplaceCart'

const removePurchaseSession = () => {
  localStorage.removeItem(purchaseSessionIdKey)
}

export const usePurchaseSession = () => {
  const { t } = useTranslation('parent', {
    keyPrefix: 'cart',
  })

  const {
    coursesArray,
    total: totalString,
    clearMarketplaceCart,
  } = useMarketplaceCart()
  const navigate = useNavigate()
  const total = Number(totalString)

  const [checkCoursesPurchase, { loading }] = useCheckCoursesPurchaseMutation({
    onCompleted({ checkCoursesPurchase }) {
      if (checkCoursesPurchase.success) {
        handleAnalyticsSuccess()
        clearMarketplaceCart()
        removePurchaseSession()
        toast.success(t('coursesAddedSuccess'))
        navigate(route.parent.dashboard.home())
        return
      }

      if (checkCoursesPurchase.sessionStatus === SessionStatus.Expired) {
        removePurchaseSession()
        parentAnalytics.emitMarketplacePurchaseFail({
          total,
          reason: 'PURCHASE_SESSION_EXPIRED',
        })
        toast.warning(t('purchaseSessionExpired'))
        return
      }

      if (
        checkCoursesPurchase.sessionStatus === SessionStatus.Open &&
        checkCoursesPurchase.currentSessionUrl
      ) {
        toast.warning(
          <ToastWithLink href={checkCoursesPurchase.currentSessionUrl} />,
          {
            autoClose: 10000,
          },
        )
      }
    },
    onError({ message }) {
      removePurchaseSession()
      parentAnalytics.emitMarketplacePurchaseFail({
        total,
        reason: message,
      })
    },
  })

  const [searchParams] = useSearchParams()

  const handleAnalyticsSuccess = () => {
    parentAnalytics.emitMarketplacePurchaseSuccess({
      total,
    })
    coursesArray.forEach(([childId, childCourses]) => {
      childCourses?.forEach((course) => {
        parentAnalytics.emitAcquireMarketplaceItem({
          childId,
          childName: course.child.fullName,
          childGrade: course.child.grade,
          coursePrice: course.price,
          courseId: course._id,
          courseName: course.name,
          subject: course.subject?.slug ?? '',
          grade: course.grade?.slug ?? '',
        })
      })
    })
  }

  useEffect(() => {
    const purchaseCancelled = searchParams.get('purchaseCancelled')

    if (purchaseCancelled === 'true') {
      removePurchaseSession()
      parentAnalytics.emitMarketplacePurchaseFail({
        total,
        reason: 'PARENT_CANCEL_PURCHASE',
      })
    }
  }, [searchParams])

  useEffect(() => {
    const page = searchParams.get('page')
    const purchaseCancelled = searchParams.get('purchaseCancelled')

    if (purchaseCancelled === 'true' || !page) {
      return
    }

    const purchaseSessionId = localStorage.getItem(purchaseSessionIdKey)

    if (!purchaseSessionId) {
      return
    }

    checkCoursesPurchase({
      variables: {
        sessionId: purchaseSessionId,
      },
    })
  }, [searchParams])

  return {
    loading,
  }
}
