import { useCourseDetailsForMarketplaceQuery } from '@graphql/generated/types'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Loader } from '@/components'
import { Modal } from '@/components/Modal'
import type { useModalControls } from '@/components/Modal/useModalControls'
import { MetaverseDetailsModal } from '@/pages/parent/dashboard/components/CourseCards/MetaverseDetailsModal'

import type { CourseDetails } from '../Courses'
import {
  Body,
  Container,
  Content,
  Header,
  Hr,
  IFrame,
  Lessons,
  LoaderContainer,
  TabButton,
  Tabs,
  TitleUnitsAndLessons,
  Unit,
  VideoContainer,
} from './CourseDetailsModal.styled'

type CurrentTab = 'Overview' | 'Units'

type CourseDetailsModalProps = Pick<
  ReturnType<typeof useModalControls>,
  'isOpen' | 'onClose'
> & {
  course?: CourseDetails
}

export function CourseDetailsModal({
  isOpen,
  onClose,
  course = {
    id: '',
    isMetaverse: true,
    name: '',
  },
}: CourseDetailsModalProps) {
  const { t } = useTranslation('parent', {
    keyPrefix: 'marketplace',
  })
  const [currentStep, setCurrentStep] = useState<CurrentTab>('Units')

  const { data, loading } = useCourseDetailsForMarketplaceQuery({
    skip: !course.id || course.isMetaverse,
    variables: {
      id: course.id,
    },
  })

  const extractVideoId = (url: string) => {
    // Regular expression pattern to match the 'v' query parameter
    const regex = /[?&]v=([^&]+)/

    const match = regex.exec(url)

    if (match) {
      const id = match[1]
      return id
    }

    return ''
  }

  if (course.isMetaverse) {
    return <MetaverseDetailsModal isOpen={isOpen} onClose={onClose} />
  }

  return (
    <Modal
      shouldCloseOnOverlayClick
      isOpen={isOpen}
      closePosition={{
        top: '1.5rem',
        right: '100rem',
      }}
      onRequestClose={onClose}
    >
      <Container>
        {loading && (
          <LoaderContainer>
            <Loader color="grape" />
          </LoaderContainer>
        )}
        {!loading && data && (
          <>
            <Header>{data.courseDetailsForMarketplace.name}</Header>

            <Tabs>
              <TabButton
                appearance={currentStep === 'Overview' ? 'default' : 'outline'}
                onClick={() => {
                  setCurrentStep('Overview')
                }}
              >
                {t('overview')}
              </TabButton>
              <TabButton
                appearance={currentStep === 'Units' ? 'default' : 'outline'}
                onClick={() => {
                  setCurrentStep('Units')
                }}
              >
                {t('units')}
              </TabButton>
            </Tabs>

            <Hr />

            {currentStep === 'Overview' && (
              <Content
                dangerouslySetInnerHTML={{
                  __html: data.courseDetailsForMarketplace.description,
                }}
              />
            )}

            {currentStep === 'Units' && (
              <Body>
                <TitleUnitsAndLessons>
                  {t('unitsAndLessons')}
                </TitleUnitsAndLessons>
                {data.courseDetailsForMarketplace.courseInfoVideoLink &&
                  extractVideoId(
                    data.courseDetailsForMarketplace.courseInfoVideoLink,
                  ) && (
                    <VideoContainer>
                      <IFrame
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen;"
                        src={`https://www.youtube.com/embed/${extractVideoId(
                          data.courseDetailsForMarketplace.courseInfoVideoLink,
                        )}?rel=0`}
                      />
                    </VideoContainer>
                  )}
                {data.courseDetailsForMarketplace.units.map(
                  ({ _id, name, lessons }) => (
                    <Unit
                      key={_id}
                      header={`${name} (${lessons.length} ${t(
                        lessons.length > 1 ? 'lessons' : 'lesson',
                      )})`}
                      defaultExpanded={false}
                    >
                      <Lessons>
                        {lessons.map(({ _id, name }) => (
                          <li key={_id}>{name}</li>
                        ))}
                      </Lessons>
                    </Unit>
                  ),
                )}
              </Body>
            )}
          </>
        )}
      </Container>
    </Modal>
  )
}
