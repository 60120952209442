import { useTranslation } from 'react-i18next'

import LogoWhite from '@/assets/images/logo-with-rocket.png'
import Rocket from '@/assets/images/rocket.png'
import { Mood } from '@/pages/child/components/Mood'

import {
  Bottom,
  Container,
  Content,
  SchoolioLogo,
  StyledRocket,
  Top,
} from './Transition.styled'

type TransitionProps = {
  onMoodClick: VoidFunction
  username: string
  moodCheckId?: string
}

const { bottom, rocket, content } = {
  bottom: 250,
  rocket: 750,
  content: 1250,
}

export function Transition({
  onMoodClick,
  username,
  moodCheckId,
}: TransitionProps) {
  const { t } = useTranslation('signIn')

  return (
    <Container>
      <Top delay={bottom} />
      <Bottom duration={bottom} />
      <StyledRocket
        src={Rocket}
        duration={rocket}
        delay={bottom * 2}
        alt="rocket"
      />
      <Content duration={content} delay={rocket * 2}>
        <SchoolioLogo src={LogoWhite} alt="schoolio logo" />
        <Mood
          paragraph={t('welcomeBack', { username })}
          title={t('howDoYouFeel')}
          checkTime="start"
          externalMoodCheckId={moodCheckId}
          onClick={onMoodClick}
        />
      </Content>
    </Container>
  )
}
