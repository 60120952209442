import styled from 'styled-components'

export const Container = styled.div`
  height: 31.25rem;
  aspect-ratio: 16 / 9;
  padding: 1rem;
  border-radius: 18px;
  background-color: ${({ theme }) => theme.color.white};
`

export const IFrame = styled.iframe`
  border-radius: 18px;
  width: 100%;
  height: 100%;
`
