import { Outlet } from 'react-router-dom'
import styled, { css } from 'styled-components'

import { Navbar } from '@/pages/admin/components/Navbar'
import { Main as StyledMain } from '@/styles/components'
import { media } from '@/styles/media'

export const Main = styled(StyledMain)`
  padding-top: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  background-color: ${({ theme }) => theme.color.gallery};
  justify-content: center;
`
const MaxWidthContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0;
  flex: 1;
  width: 100%;
  ${media.mobile(
    () => css`
      flex-direction: column;
    `,
  )}
`

export function AdminContainer() {
  return (
    <Main>
      <MaxWidthContainer>
        <Navbar />
        <Outlet />
      </MaxWidthContainer>
    </Main>
  )
}
