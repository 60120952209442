import styled, { css } from 'styled-components'

import { Button } from '@/components'
import { media } from '@/styles/media'
import {
  changeOpacity,
  fadeInAnimation,
  flexCenter,
  flexColumn,
  typography,
} from '@/styles/utils'

export const Open = styled(Button)`
  opacity: 0.75;
  background-color: ${({ theme }) => theme.color.cerise};
`

export const Container = styled.div<{ isVisible: boolean }>`
  position: fixed;
  z-index: 200;
  bottom: 0;
  right: 0;
  padding: 1rem;
  display: ${({ isVisible }) => (isVisible ? 'static' : 'none')};

  &:hover {
    ${Open} {
      opacity: 1;
    }
  }

  ${media.mobile(
    () => css`
      padding: 0.5rem;
    `,
  )}
`

export const ReportForm = styled.form`
  ${flexColumn()};
  gap: 0.5rem;
  background-color: ${({ theme }) => theme.color.white};
  border-radius: 18px;
  box-shadow: 2px 4px 20px
    ${({ theme }) => changeOpacity(theme.color.black, 0.25)};
  ${fadeInAnimation()};
  width: 25rem;
  padding: 1rem;
  max-height: 90vh;
  overflow-y: auto;

  ${media.mobile(
    () => css`
      width: calc(100vw - 2rem);
      height: 80vh;
    `,
  )}
`

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const ScreenshotsContainer = styled.div`
  ${flexColumn()};
  gap: 0.563rem;
  margin-bottom: 0.5rem;
`

export const Screenshots = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 0.25rem;
`

const screenshotHeight = '5rem'

export const ScreenshotContainer = styled.div`
  position: relative;
  height: ${screenshotHeight};
  width: 100%;
`

export const ScreenshotDelete = styled.button`
  position: absolute;
  right: 5%;
  top: 5%;
  ${flexCenter()};
  background-color: ${({ theme }) => theme.color.grape};
  border-radius: 50%;
  padding: 5px;
  path {
    fill: ${({ theme }) => theme.color.white};
  }
`

export const Screenshot = styled.img`
  object-fit: cover;
  height: 100%;
  width: 100%;
`

export const AddScreenshot = styled.button`
  padding: 0.5rem;
  background-color: ${({ theme }) => theme.color.grape};
  color: ${({ theme }) => theme.color.white};
  height: ${screenshotHeight};
  border-radius: 8px;
`

export const Title = styled.h3`
  ${typography.h3()};
  margin-bottom: 0.5rem;
`

export const Close = styled.button``

export const SubmitButton = styled(Button)`
  overflow: unset;
  height: 3rem !important;
`
