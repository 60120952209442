import ReactSelect from 'react-select'
import CSelect from 'react-select/creatable'
import styled, { css } from 'styled-components'

import {
  Container as InputContainer,
  Error as InputError,
  inputStyles,
  Label as InputLabel,
  placeholderStyles,
} from '@/components/Input/Input.styled'
import { changeOpacity, oneLineText, scrollbar } from '@/styles/utils'

import type { WithAppearance, WithError } from '../Input/Input.styled'
import type { Option } from './Select'

export const classNamePrefix = 'input-select'

export const Container = styled(InputContainer)<{ disabled: boolean }>`
  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
    `}
`

export const Label = styled(InputLabel)``

export const Error = styled(InputError)``

const selectStyles = () => css<WithError & WithAppearance>`
  .${classNamePrefix} {
    &__control {
      ${inputStyles};
      cursor: pointer;

      &--is-disabled {
        opacity: 0.5;
        color: ${({ theme }) => theme.color.black};
      }
    }
    &__input-container {
      font-size: inherit;
      color: inherit;
      padding: 0;
      margin: 0;
    }
    &__placeholder {
      font-size: inherit;
      ${placeholderStyles};
    }
    &__menu {
      top: calc(100% - ${({ theme }) => theme.fontSize.large});
    }
    &__menu-list {
      overflow-x: hidden;
      ${scrollbar('white')}
    }
    &__single-value {
      &--is-disabled {
        color: ${({ theme }) => theme.color.black};
      }
    }
    &__value-container {
      padding: 0;
      gap: 0.45rem;
    }
    &__indicator {
      padding: 0 0 0 0.75rem;
    }
    &__clear-indicator {
      padding: 0 0.75rem 0 0;
    }
    &__multi-value {
      margin: 0;
      border-radius: 5px;
      background-color: ${({ theme }) => changeOpacity(theme.color.grape, 0.1)};

      &__label {
        padding: 0 0.5rem;
      }
    }
    &__option {
      cursor: pointer;
      ${oneLineText()};
      ${({
        theme: {
          color: { grape, white },
        },
      }) => css`
        &--is-focused {
          background-color: ${changeOpacity(grape, 0.1)};
        }

        &--is-selected,
        &:active {
          color: ${white};
          background-color: ${grape};
          cursor: default;
        }
      `}
    }
  }
`

export const CreatableSelect = styled(CSelect<Option, false>)<
  WithError & WithAppearance
>`
  ${selectStyles()}
`

export const Select = styled(ReactSelect<Option, false>)<
  WithError & WithAppearance
>`
  ${selectStyles()}
`
