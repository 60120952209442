/* eslint-disable import/no-duplicates */
import styled, { css } from 'styled-components'

import FamilyLearningPlan from '@/assets/images/family-learning-plan.webp'
import Logo from '@/assets/images/logo-with-rocket.png'
import SignInImage from '@/assets/images/sign-in.webp'
import SignUpImage from '@/assets/images/sign-in.webp'
import { media } from '@/styles/media'
import { flexCenter, flexColumn } from '@/styles/utils'

import { Nav } from '../common/Landing/components/Nav'

export const OuterMain = styled.main`
  background-color: ${({ theme }) => theme.color.blueChalk};
  overflow-x: hidden;

  & > section {
    animation: none;
    position: relative;
  }

  & > section {
    & > div {
      margin: 0rem auto;
      padding: 0.5rem;
    }
  }
`

export const AuthNav = styled(Nav)``

export const Main = styled.main`
  width: 100vw;
  min-height: 100vh;
  display: flex;
  background-color: ${({ theme }) => theme.color.blueChalk};
  overflow-x: hidden;
`

export const ImageColumn = styled.div`
  flex: 1;
  ${flexCenter()};
  flex-direction: column;
  gap: 2.5rem;

  ${media.desktop(
    () => css`
      flex: 0.8;
    `,
  )}

  ${media.tablet(
    () => css`
      display: none;
    `,
  )}
`

export const QuoteColumn = styled.div`
  flex: 0.5;
  ${flexCenter()};
  flex-direction: column;
  gap: 2.5rem;
  background-color: ${({ theme }) => theme.color.classicBouquet};

  ${media.desktop(
    () => css`
      flex: 0.5;
    `,
  )}

  ${media.tablet(
    () => css`
      display: none;
    `,
  )}
`

export const SignUpSalesColumn = styled.div`
  flex: 0.8;
  margin: auto 0;
  flex-direction: column;
  padding: 0 10px;
  gap: 2.5rem;

  ${media.desktop(
    () => css`
      flex: 1;
    `,
  )}
  ${media.tablet(
    () => css`
      display: none;
    `,
  )};
`

export const ImageBackground = styled.img.attrs(() => ({
  src: SignInImage,
}))`
  display: none;
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0.15;
  filter: blur(5px);
  object-fit: cover;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(${SignInImage});

  ${media.tablet(
    () => css`
      display: block;
    `,
  )}
`

export const SignUpImageBackground = styled.img.attrs(() => ({
  src: SignUpImage,
}))`
  display: none;
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0.15;
  filter: blur(5px);
  object-fit: cover;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(${SignUpImage});

  ${media.tablet(
    () => css`
      display: block;
    `,
  )}
`

export const Image = styled.img.attrs(() => ({
  src: FamilyLearningPlan,
}))`
  top: 0;
  width: 100%;
  height: 100vh;
  object-fit: cover;

  ${media.desktop(
    () => css`
      width: 100%;
    `,
  )}
`
export const ImageSignUp = styled.img.attrs(() => ({
  src: SignUpImage,
}))`
  position: fixed;
  top: 0;
  width: 50%;
  height: 100vh;
  object-fit: cover;

  ${media.desktop(
    () => css`
      width: 40%;
    `,
  )}
`

export const FormColumn = styled.div`
  flex: 1;
  ${flexCenter()};
  flex-direction: column;
  gap: 1.5rem;
  padding: 0rem 0;
  z-index: 1;

  ${media.desktop(
    () => css`
      flex: 1.2;
    `,
  )}

  ${media.tablet(
    () => css`
      padding: 1rem;
    `,
  )}
`

export const SchoolioLogo = styled.img.attrs(() => ({
  src: Logo,
  alt: 'schoolio logo',
}))`
  width: 8rem;
  cursor: pointer;
`

export const Title = styled.h1`
  font-size: ${({ theme }) => theme.fontSize.huge};
  color: ${({ theme }) => theme.color.slateGray};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
`

export const Form = styled.form<{ hide?: boolean }>`
  width: 27rem;
  padding: 2rem;
  ${flexColumn()};
  border-radius: 18px;
  background-color: ${({ theme }) => theme.color.white};
  gap: 1.25rem;
  box-shadow: 0 61px 62px 0 hsl(0deg 0% 0% / 7%);
  z-index: 1;

  ${media.desktop(
    () => css`
      width: 30rem;
    `,
  )}

  ${media.tablet(
    () => css`
      max-width: 43.75rem;
      width: 80%;
    `,
  )}
  
  ${media.mobile(
    () => css`
      width: 100%;
      padding: 2rem 1rem;
    `,
  )}
  
  ${({ hide }) =>
    hide &&
    css`
      display: none;
    `}
`

export const Inputs = styled.div`
  ${flexColumn()};
  gap: 0.75rem;
`

export const SignUpSalesDiv = styled.div`
  max-width: 25rem;
  margin-left: auto;
`

export const Quote = styled.p`
  font-size: ${({ theme }) => theme.fontSize.big};
  color: ${({ theme }) => theme.color.white};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  text-align: center;
  padding: 0 25px;
`

export const PersonName = styled.p`
  font-size: ${({ theme }) => theme.fontSize.big};
  color: ${({ theme }) => theme.color.white};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  text-align: center;
  padding: 0 10px;
`

export const GetStarted = styled.p`
  font-size: ${({ theme }) => theme.fontSize.big};
  color: #949494;
  font-weight: ${({ theme }) => theme.fontWeight.medium};
`

export const FreeTrial = styled.p`
  font-size: ${({ theme }) => theme.fontSize.great};
  color: ${({ theme }) => theme.color.slateGray};
  font-weight: ${({ theme }) => theme.fontWeight.big};
  margin-top: 1rem;
`

export const SchoolioUniverse = styled.p`
  font-size: ${({ theme }) => theme.fontSize.big};
  color: ${({ theme }) => theme.color.slateGray};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  margin-top: 0.5rem;
`

export const DiscoverLine = styled.p`
  font-size: ${({ theme }) => theme.fontSize.bigger};
  color: ${({ theme }) => theme.color.slateGray};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
`

export const SignUpBullet = styled.li`
  font-size: ${({ theme }) => theme.fontSize.big};
  color: ${({ theme }) => theme.color.slateGray};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  list-style: disc;
  padding-left: 0.5rem;
  margin-top: 0.3rem;
`

export const SSPLine = styled.p`
  font-size: ${({ theme }) => theme.fontSize.big};
  color: ${({ theme }) => theme.color.slateGray};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  margin-top: 1rem;
`
