import type { PropsWithChildren, ReactNode } from 'react'

import { Actions, Container, Header, Title } from './Card.styled'

type CardProps = {
  title: string
  actions?: ReactNode[]
  className?: string
}

export function Card({
  title,
  children,
  actions,
  className,
}: PropsWithChildren<CardProps>) {
  return (
    <Container className={className}>
      <Header>
        <Title>{title}</Title>
        {actions && <Actions>{actions.map((action) => action)}</Actions>}
      </Header>
      {children}
    </Container>
  )
}
