import { useRecoilState, useRecoilValue } from 'recoil'

import type { Child } from './atoms'
import { child, defaultValue } from './atoms'
import { isTrialAvailableSelector } from './selectors'

export const useChild = () => {
  const [{ current }, setChild] = useRecoilState(child)
  const isTrialAvailable = useRecoilValue(isTrialAvailableSelector)

  const setCurrent = (current: Child['current']) => {
    setChild({
      current,
    })
  }

  const clearChild = () => {
    setChild(defaultValue)
  }

  return {
    setCurrent,
    current: current
      ? {
          ...current,
          isTrialAvailable,
        }
      : undefined,
    clearChild,
  }
}
