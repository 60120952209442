import styled, { css } from 'styled-components'

import { scrollbar } from '@/styles/utils'

export const Container = styled.div`
  display: contents;

  .public-DraftStyleDefault-block {
    margin: 0;
  }

  .rdw-editor-wrapper {
    height: 100%;
    border-radius: 12px;
    overflow: hidden;

    ${({ theme }) => css`
      background-color: ${theme.color.white};
      color: ${theme.color.black};
      font-size: ${theme.fontSize.bigger};
      font-weight: ${theme.fontWeight.regular};
    `}
  }

  .rdw-editor-main {
    padding: 1rem;
    ${scrollbar('white', 'grape')};
  }

  .rdw-option-wrapper {
    border: none;
  }

  .rdw-link-modal-target-option {
    display: none;
  }
`
