import { Link as RouterLink } from 'react-router-dom'
import styled from 'styled-components'

import { changeOpacity, flexCenter, transitions } from '@/styles/utils'

const arrowSize = '5px'

export const Container = styled.button`
  position: relative;
  width: 1.25rem;
  height: 1.25rem;

  &:after {
    position: absolute;
    content: '';
    width: 0;
    height: 0;
    top: 50%;
    left: 26px;
    border-left: ${arrowSize} solid transparent;
    border-right: ${arrowSize} solid transparent;
    border-top: ${arrowSize} solid ${({ theme }) => theme.color.white};
  }
`

export const Link = styled(RouterLink)`
  ${flexCenter()};
  justify-content: flex-start;
  padding-left: 1.125rem;
  height: 2.813rem;
  text-decoration: none;
  color: ${({ theme }) => theme.color.black};
  ${transitions({})('background-color')};
  &:hover {
    background-color: ${({ theme }) => changeOpacity(theme.color.grape, 0.15)};
  }
`

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
`
