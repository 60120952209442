import styled, { css } from 'styled-components'

import { media } from '@/styles/media'
import { flexColumn } from '@/styles/utils'

import { imageTextFlex } from '../../Landing.styled'

export const Main = styled.div`
  margin: 9.75rem 0;
  border-radius: 4rem;
  width: 100%;

  ${media.tablet(
    () => css`
      margin: 8rem 0;
    `,
  )};
`

export const ImageContainer = styled.div`
  max-width: 45rem;
  margin: auto;
  position: relative;
`

export const ImageBoy = styled.img`
  padding: 0rem 1rem;
`

export const TagLine = styled.p`
  font-size: ${({ theme }) => theme.landingSpecialFontSize.extraGreat};
  font-weight: ${({ theme }) => theme.fontWeight.light};
  margin-bottom: 6rem;

  ${media.mobile(
    () => css`
      font-size: ${({ theme }) => theme.landingSpecialFontSize.big};
      margin-bottom: 4rem;
    `,
  )}
`

export const ImageCircles = styled.img`
  position: absolute;
  top: -5rem;
  right: -5rem;

  ${media.mobile(
    () => css`
      height: 5.5rem;
      width: auto;
      top: -3rem;
      right: -3rem;
    `,
  )};
`

export const ImageTextFlexContainer = styled.div`
  ${imageTextFlex()}

  ${media.tablet(
    () => css`
      ${flexColumn()}
      flex-direction: column-reverse;
    `,
  )};
`
