import { Link as RouterLink } from 'react-router-dom'
import styled, { css, keyframes } from 'styled-components'

import { Button } from '@/components'
import { Card as CardComponent } from '@/pages/parent/dashboard/Home/components/Card'
import { Header } from '@/pages/parent/dashboard/Home/components/Card/Card.styled'
import { checkmarkStick } from '@/styles/components'
import { media } from '@/styles/media'
import type { Color } from '@/styles/theme'
import { theme } from '@/styles/theme'
import {
  changeOpacity,
  fadeInAnimation,
  flexCenter,
  flexColumn,
  flexRow,
  transitions,
  typography,
} from '@/styles/utils'

export const Card = styled(CardComponent)`
  padding: 0;

  ${Header} {
    padding: 1rem;
    border-bottom: none;
  }
`

export const FlexRow = styled.div`
  display: flex;
  align-items: end !important;
  gap: 0.5rem;
`

export const Container = styled.div`
  ${flexColumn()};
  flex: 1;

  ${media.mobile(
    () => css`
      padding-bottom: 3rem;
    `,
  )}
`

export const Plans = styled.div`
  display: flex;
  flex: 1;

  ${media.mobile(
    () => css`
      flex-direction: column;
    `,
  )}
`

export const FamilyPlan = styled.div`
  background-color: ${({ theme }) => theme.color.white};
  flex: 1;
  ${flexColumn()};
  padding: 1rem;
  gap: 1rem;
  align-items: center;
  border-radius: 18px;
  box-shadow: 0 0 10px 1px
    ${({ theme }) => changeOpacity(theme.color.black, 0.15)};
  ${fadeInAnimation()};

  justify-content: flex-start !important;
`

export const Plan = styled.div<{ hidden?: boolean }>`
  display: ${({ hidden }) => (!hidden ? 'flex' : 'none !important')};
  background-color: ${({ theme }) => theme.color.white};
  flex: 1;
  ${flexColumn()};
  padding: 1rem;
  gap: 1rem;
  align-items: center;
  border-radius: 18px;
  box-shadow: 0 0 10px 1px
    ${({ theme }) => changeOpacity(theme.color.black, 0.15)};
  ${fadeInAnimation()};
`

export const StyledSectionTitle = styled.h2`
  ${typography.h2};
  text-align: center;
  margin-bottom: 1.5rem;
  margin-top: 3rem;
`

export const Info = styled.div`
  ${({
    theme: {
      color: { grape, white },
    },
  }) => css`
    background-color: ${grape};
    color: ${white};
  `}

  padding: 1rem;
  ${flexColumn()};
`

export const LoadingContainer = styled.div`
  ${flexCenter()};
  flex: 1;
`

export const Title = styled.h3`
  ${typography.h1()};
`

export const Paragraph = styled.p`
  font-size: ${({ theme }) => theme.fontSize.big};
`

export const PrePostPriceText = styled.p`
  font-size: ${({ theme }) => theme.fontSize.normal};
  color: ${({ theme }) => theme.color.doveGray};
`

export const Image = styled.img`
  height: 19.5rem;
  width: 20.5rem;
  object-fit: cover;
  margin: auto;
`

export const PlanTitle = styled.h4`
  font-size: ${({ theme }) => theme.fontSize.gigantic};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  display: flex;
  align-items: center;
  gap: 0.33rem;
`

export const PriceInterval = styled.p`
  color: ${({ theme }) => theme.color.black};
  font-size: ${({ theme }) => theme.fontSize.big};
`

export const Bullets = styled.ul`
  flex: 1;
  ${flexColumn};
  gap: 0.5rem;
  width: 100%;
`

export const Bullet = styled.li`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`

export const PlanHeader = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 16px;
  border-radius: 8px;
  text-align: center;
  gap: 10px;
  background-color: ${({ theme }) => theme.color.lightLavender};
  p {
    color: ${({ theme }) => theme.color.black};
    font-weight: ${({ theme }) => theme.fontWeight.regular};
    font-size: ${({ theme }) => theme.fontSize.large};
  }
  span {
    color: ${({ theme }) => theme.color.doveGray};
    font-weight: ${({ theme }) => theme.fontWeight.regular};
    font-size: ${({ theme }) => theme.fontSize.normal};
  }
`

const checkSize = '1.5rem'

export const Check = styled.span`
  position: relative;
  width: ${checkSize};
  height: ${checkSize};
  border-radius: 50%;
  min-width: ${checkSize};

  &:before {
    ${checkmarkStick()};
    height: 5px;
    rotate: -45deg;
    top: 10px;
    left: 11px;
  }

  &:after {
    ${checkmarkStick()};
    height: 8px;
    rotate: 45deg;
    bottom: 9px;
    left: 10.5px;
  }
`

export const ButtonsRow = styled.div`
  ${flexRow()}
  gap: 1rem;
  margin: 1rem 0;
  background-size: 60px;
`

export const ButtonSelectionSec = styled.div`
  max-width: 25rem;
  min-width: 15rem;
  ${flexColumn()}
  gap: 0.5rem;
  justify-content: start !important;
  height: 100%;
  flex: 1 1 0;
  text-align: center;
`

export const SaveAnyLine = styled.p`
  color: #3366cc;
  font-weight: ${({ theme }) => theme.fontWeight.medium};
`

export const TimeWhenBilled = styled.span`
  text-decoration: underline;
  font-weight: ${({ theme }) => theme.fontWeight.medium};
`

export const Or = styled.p`
  position: absolute;
  margin-bottom: 4rem;
  font-size: ${({ theme }) => theme.fontSize.normal};
  font-weight: ${({ theme }) => theme.fontWeight.big};
`

export const Subscribe = styled(Button)`
  min-width: 180px;
  height: 44px;
`

export const MobileSliderContainer = styled.div`
  margin: auto;
  width: 100%;
  padding: 0.5em;
`

export const Dot = styled.span<{ isActive: boolean }>`
  height: 20px;
  width: 20px;
  border-radius: 50%;
  display: inline-block;
  background-color: ${({ isActive }) =>
    isActive ? theme.color.grape : theme.color.placeboFuchsia};
  box-shadow: 2px 2px 0px ${theme.color.janePurple};
`

export const StyledHeader = styled.h2`
  text-align: center;
  margin: 3rem 0;
  font-size: ${({ theme }) => theme.fontSize.gigantic};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
`

export const DialogGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 0px 20px;
  padding: 0 20px;
  ${media.tablet(
    () => css`
      display: block;
      padding: 0;
    `,
  )};
`

export const DialogContainer = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  margin-bottom: 15px;
  align-items: end;
`

export const StyledImage = styled.img`
  height: 30px;
  width: 30px;
`

export const Dialog = styled.p<{ color: Color }>`
  margin: 2px;
  padding: 9px 14px;
  background-color: ${({ theme, color }) => theme.color[color]};
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.03em;
  text-align: left;

  &:first-child {
    border-radius: 21px 21px 21px 5px;
  }
  &:last-child {
    border-radius: 5px 21px 21px 21px;
    width: fit-content;
  }
  &:only-child {
    border-radius: 21px 21px 21px 21px !important;
  }
`

export const StyledPartnersCard = styled.div`
  width: 100%;
  height: auto;
  padding: 16px;
  border-radius: 18px;
  background-color: ${({ theme }) => theme.color.white};
  position: relative;
  margin-bottom: 1.5rem;
  margin-top: 3rem;
  p {
    color: #808080;
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: -0.03em;
    text-align: left;
  }
`

export const StyledPartnersList = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  row-gap: 10px;
  column-gap: 20px;
  padding: 5px 20px;
`

export const StyledPartnerImg = styled.img`
  height: 40px;
  object-fit: contain;
  max-width: 60px;
`
export const StyledSparkleImg = styled.img`
  position: absolute;
  left: -12px;
  bottom: -15px;
`

export const StyledPricingGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 60%;
  grid-template-rows: 1fr;
  gap: 0px 20px;
  position: relative;
  div {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  ${media.tablet(
    () => css`
      display: flex;
      flex-direction: column-reverse;
      gap: 20px 0px;
    `,
  )};
`
export const StyledPricingBanner = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-start !important;
  flex-direction: column;
  gap: 50px 0px;
  border-radius: 18px;
  background-color: ${({ theme }) => theme.color.lightLavender};
  padding: 32px 32px 0 32px;
  p {
    color: ${({ theme }) => theme.color.grape};
    font-size: 22px;
    font-weight: 500;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;
    z-index: 2;
  }

  ${media.tablet(
    () => css`
      display: none !important;
    `,
  )};
`

export const StyledBannerImage = styled.img`
  width: 100%;
  max-width: 400px;
  height: auto;
  z-index: 1;
  bottom: 0;
  border-radius: 0 0 9rem 9rem;
`

const statusHeight = '1.5rem'
const statusCircleSize = statusHeight
const activeLeftOffset = `calc(100% - ${statusCircleSize})`
const activeLeftHoverOffset = `calc(97% - ${statusCircleSize})`

export const Status = styled.button<{ isAnnual: boolean }>`
  position: relative;
  margin: 0px 15px;
  ${transitions({})('padding')};
  padding: ${({ isAnnual }) =>
    `0.25rem ${isAnnual ? statusHeight : '0.25rem'} 0.25rem ${
      isAnnual ? '0.25rem' : statusHeight
    }`};
  font-size: ${({ theme }) => theme.fontSize.small};
  width: 3rem;
  border-radius: 32px;
  height: ${statusHeight};
  flex-shrink: 0;

  ${({
    isAnnual,
    theme: {
      color: { grape, lightLavender, alto, black },
    },
  }) =>
    isAnnual
      ? css`
          background-color: ${lightLavender};
          color: ${grape};
        `
      : css`
          background-color: ${alto};
          color: ${black};
        `}

  &:before {
    box-shadow: 0px 2px 4px 0px #00000040;
    content: '';
    ${transitions({})('left')};
    position: absolute;
    background-color: ${({ theme, isAnnual }) =>
      isAnnual ? theme.color.grape : theme.color.grape};
    border-radius: 50%;
    height: ${statusCircleSize};
    width: ${statusCircleSize};
    left: ${({ isAnnual }) => (isAnnual ? activeLeftOffset : '0%')};
    top: 50%;
    transform: translateY(-50%);
  }

  &:hover {
    &:before {
      left: ${({ isAnnual }) => (isAnnual ? activeLeftHoverOffset : '3%')};
    }
  }
`
export const StyledStatusLabel = styled.p<{ active: boolean }>`
  color: ${({ theme, active }) =>
    active ? theme.color.black : theme.color.doveGray};
  text-align: center;
`
export const StyledSaveLabel = styled.p`
  color: ${({ theme }) => theme.color.cerise};
  font-size: ${({ theme }) => theme.fontWeight.regular};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  position: absolute;
  top: -2em;
  right: 3rem;
  ${media.desktop(
    () => css`
      right: 0rem;
    `,
  )}
  svg {
    position: relative;
    left: -20px;
  }
`

export const CrossedPrice = styled.span`
  font-size: ${({ theme }) => theme.fontSize.large};
  font-weight: ${({ theme }) => theme.fontWeight.light};
  text-decoration: line-through;
  margin-top: 0.5rem;
`

const fadeInOut = keyframes`
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
`

export const Link = styled(RouterLink)<{ isLoading: boolean }>`
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }

  ${({ isLoading }) =>
    !isLoading
      ? css``
      : css`
          pointer-events: none;
          animation: ${fadeInOut} 1s ease infinite;
        `}
`

export const PointOutText = styled.p`
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  font-size: ${({ theme }) => theme.fontSize.big};
  text-align: center;
`

export const CardsContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 1rem;
`
