import type {
  CourseCountry,
  GetForOpenMarketplaceInput,
} from '@graphql/generated/types'
import { useOpenMarketplaceCoursesLazyQuery } from '@graphql/generated/types'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import call from '@/assets/images/landing/call.svg'
import { Loader, Pagination } from '@/components'
import { useInitialPage } from '@/components/Pagination/useInitialPage'
import { paginationLimit, schoolioBookStoreUrl } from '@/core/const'
import { useTitle } from '@/core/hooks'
import { usePurchaseSession } from '@/pages/parent/Marketplace/usePurchaseSession'

import { Footer } from '../Landing/components/Footer'
import { Nav } from '../Landing/components/Nav'
import {
  CallIcon,
  GetHelpContainer,
  GetHelpLine,
  GetHelpLink,
  GetHelpText,
} from '../Landing/Landing.styled'
import { Courses } from './components/Courses'
import type { Price, Unit } from './components/Filters'
import { Filters } from './components/Filters'
import {
  Info,
  Main,
  Right,
  StyledMaxWidthContainer,
} from './Marketplace.styled'

export type SearchSuccessParams = {
  gradeId: string
  subjectIds: string[]
  countries: CourseCountry
  price: Price[]
  unit: Unit
}

export function Marketplace() {
  useTitle('marketplace')
  const { t } = useTranslation('landing')

  const [filters, setFilters] = useState<GetForOpenMarketplaceInput>()
  const [openMarketplaceCourses, { data, loading, refetch }] =
    useOpenMarketplaceCoursesLazyQuery({
      fetchPolicy: 'no-cache',
    })

  const [isRefetchLoading, setIsRefetchLoading] = useState(false)
  const { page } = useInitialPage()

  const { loading: purchaseLoading } = usePurchaseSession()

  const onSearchStart = ({
    countries,
    subjectIds,
    gradeId,
    price,
    unit,
  }: SearchSuccessParams) => {
    if (!page) {
      return
    }

    const input: GetForOpenMarketplaceInput = {
      gradeId,
      subjectIds,
      countries,
      limit: paginationLimit,
      page,
      price,
      unit,
    }

    setFilters(input)

    openMarketplaceCourses({
      variables: {
        input,
      },
    })
  }

  const onPageChange = (page: number) => {
    if (!filters) {
      return
    }

    setIsRefetchLoading(true)
    refetch({
      input: {
        ...filters,
        page,
      },
    }).finally(() => {
      setIsRefetchLoading(false)
    })
  }

  return (
    <Main>
      <GetHelpLine>
        <GetHelpContainer>
          <GetHelpText>{t('notSureWhereTo')}</GetHelpText>
          <GetHelpLink
            target="_blank"
            href={`${schoolioBookStoreUrl}/concierge/`}
          >
            {t('getHelp')}
          </GetHelpLink>
          <CallIcon src={call} alt="call icon" />
        </GetHelpContainer>
      </GetHelpLine>

      <Nav />
      <StyledMaxWidthContainer>
        <Filters onSearchStart={onSearchStart} />
        <Right>
          {loading && (
            <Info>
              <Loader color="grape" />
            </Info>
          )}
          {!loading && data && (
            <Courses
              isLoading={isRefetchLoading || purchaseLoading}
              courses={data.openMarketplaceCourses.courses}
              totalCount={data.openMarketplaceCourses.pagination.total}
            />
          )}
          {!loading && data?.openMarketplaceCourses.pagination && (
            <Pagination
              {...data.openMarketplaceCourses.pagination}
              onPageChange={onPageChange}
            />
          )}
        </Right>
      </StyledMaxWidthContainer>

      <Footer />
    </Main>
  )
}
