class Session {
  private get accessKey() {
    return 'accessToken'
  }

  private get refreshKey() {
    return 'refreshToken'
  }

  private get rememberMeKey() {
    return 'rememberMe'
  }

  set(accessToken: string, refreshToken?: string) {
    localStorage.setItem(this.accessKey, accessToken)

    if (refreshToken) {
      localStorage.setItem(this.refreshKey, refreshToken)
    }
  }

  setRefreshToken(refresh: string) {
    localStorage.setItem(this.refreshKey, refresh)
  }

  get() {
    const isSessionRemembered = this.getRememberMe()

    return {
      accessToken: localStorage.getItem(this.accessKey),
      refreshToken: isSessionRemembered
        ? localStorage.getItem(this.refreshKey)
        : '',
    }
  }

  setRememberMe(remember: boolean) {
    localStorage.setItem(this.rememberMeKey, String(remember))
  }

  getRememberMe(): boolean | undefined {
    const remember = localStorage.getItem(this.rememberMeKey)

    if (remember === 'true') {
      return true
    }

    if (remember === 'false') {
      return false
    }
  }

  clear() {
    localStorage.removeItem(this.accessKey)
    localStorage.removeItem(this.refreshKey)
  }
}

export const session = new Session()
