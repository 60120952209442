import styled from 'styled-components'

import { flexColumn, typography } from '@/styles/utils'

export const Container = styled.aside`
  display: flex;
  width: 20.875rem;
  background-color: ${({ theme }) => theme.color.mangolia};
  padding: 1rem 2rem;
  gap: 1rem;
  ${flexColumn()};
`

export const Title = styled.h1`
  ${typography.h2};
  font-size: ${({ theme }) => theme.fontSize.great};
`

export const SubTitle = styled.h3`
  ${typography.bodyBold()};
`

export const Form = styled.form`
  ${flexColumn()};
`
