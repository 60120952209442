import type {
  GetChildProfileOutput,
  GetParentProfileOutput,
  SignInUser,
} from '@graphql/generated/types'
import { atom } from 'recoil'

export type AuthUser =
  | Pick<SignInUser, '_id' | 'username' | 'role' | 'email' | 'isOnFreemium'>
  | undefined

export const authUser = atom<AuthUser>({
  key: 'authUser',
  default: undefined,
})

export const userChildProfile = atom<GetChildProfileOutput | undefined>({
  key: 'userChildProfile',
  default: undefined,
})

export const userParentProfile = atom<
  (GetParentProfileOutput & { currency: string }) | undefined
>({
  key: 'userParentProfile',
  default: undefined,
})
