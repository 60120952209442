import chevronDown from '@/assets/images/landing/chevron-down.svg'

import type { AccordionHeaderSize } from './HeaderAccordion.styled'
import {
  ChevronImage,
  FaqTitle,
  HeaderContainer,
} from './HeaderAccordion.styled'

type HeaderAccordionProps = {
  name: string
  isExpanded: boolean
  headerSize: AccordionHeaderSize
}

export function HeaderAccordion({
  name,
  isExpanded,
  headerSize,
}: HeaderAccordionProps) {
  return (
    <HeaderContainer size={headerSize}>
      <FaqTitle>{name}</FaqTitle>
      <ChevronImage isExpanded={isExpanded} src={chevronDown} />
    </HeaderContainer>
  )
}
