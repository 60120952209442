import { useTranslation } from 'react-i18next'

import boy from '@/assets/images/landing/boy-unique.webp'
import circles from '@/assets/images/landing/nested-circles.svg'

import {
  LinesContainer,
  MainTitle,
  MaxWidthContainer,
} from '../../Landing.styled'
import { LineListing } from '../LineListing'
import {
  ImageBoy,
  ImageCircles,
  ImageContainer,
  ImageTextFlexContainer,
  Main,
  TagLine,
} from './UniqueNeeds.styled'

const lines = [
  'biteSizedLearning',
  'audioVideo',
  'activityVarity',
  'mixAndMatchGrade',
  'chooseDigital',
  'customScheduling',
]

export function UniqueNeeds() {
  const { t } = useTranslation('landing')

  return (
    <MaxWidthContainer>
      <Main>
        <MainTitle hasNoMarginBottomMobile marginBottom={1}>
          {t('titles.haveAUnique')}
        </MainTitle>
        <TagLine>{t('lines.experienceThe')}</TagLine>

        <ImageTextFlexContainer>
          <LinesContainer>
            <LineListing showSignupBtn lines={lines} color="Blue" />
          </LinesContainer>

          <ImageContainer>
            <ImageBoy src={boy} loading="lazy" alt="boy" />
            <ImageCircles src={circles} />
          </ImageContainer>
        </ImageTextFlexContainer>
      </Main>
    </MaxWidthContainer>
  )
}
