import type { ApexOptions } from 'apexcharts'
import { formatISO } from 'date-fns'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useSubscriptionsByDateQuery } from '@/graphql/generated/types'
import { theme } from '@/styles/theme'

import type { DateQuery } from '../../overview'

const useTimeStatsSubs = (date: DateQuery, status: string | undefined) => {
  const { data, loading } = useSubscriptionsByDateQuery({
    variables: { ...date, status },
  })

  const { t } = useTranslation('admin', { keyPrefix: 'students' })

  const graphRender: ApexOptions = useMemo(() => {
    if (data) {
      const graph = data.subscriptionsByDate
      const States = Array.from(new Set(graph.map((x) => x.status)))
      return {
        series: States.map((state) => {
          const dataNew = graph.filter((x) => x.status === state)
          return {
            name: state,
            data: dataNew.map((x) => ({
              x: new Date(x.date).getTime(),
              y: x.value,
            })),
          }
        }),
        chart: {
          type: 'area',
          stacked: false,
          height: 600,
          zoom: {
            enabled: true,
            autoScaleYaxis: true,
          },
          toolbar: {
            show: true,
            autoSelected: 'zoom',
          },
        },
        title: {
          text: t('graphs.timeSubsGraph', {
            startDate: formatISO(new Date(date.startDate), {
              representation: 'date',
            }),
            endDate: formatISO(new Date(date.endDate), {
              representation: 'date',
            }),
          }),
          align: 'left',
          style: {
            fontWeight: theme.fontWeight.medium,
            fontFamily: 'Fredoka',
            color: theme.color.slateGray,
          },
        },
        yaxis: {
          labels: {},
        },
        fill: {
          type: 'gradient',
          gradient: {
            shadeIntensity: 1,
            inverseColors: false,
            opacityFrom: 0.5,
            opacityTo: 0,
            stops: [0, 90, 100],
          },
        },
        dataLabels: {
          enabled: false,
        },
        tooltip: {
          shared: true,
          y: {
            formatter(val: number) {
              return val.toFixed(0)
            },
          },
        },
        xaxis: {
          type: 'datetime',
          title: {
            text: 'Time Period',
          },
        },
        noData: {
          text: `No new subscriptions`,
          align: 'center',
          verticalAlign: 'middle',
        },
      }
    }

    return {}
  }, [data])
  return { graphRender, loading }
}

export default useTimeStatsSubs
