import PhoneInputWithCountry from 'react-phone-number-input/react-hook-form'
import styled, { css } from 'styled-components'

import type { InputAppearance } from '@/components'
import { Icon } from '@/components'
import {
  changeOpacity,
  flexColumn,
  oneLineText,
  transitions,
  typography,
} from '@/styles/utils'

export type WithError = { error: boolean }
type WithSearchIcon = { searchIcon?: boolean }
export type WithAppearance = { appearance: InputAppearance }

export const Container = styled.div<{ disabled: boolean }>`
  position: relative;
  ${flexColumn()};
  gap: 0.563rem;
  ${typography.body()};
  ${transitions({})('opacity')};

  ${({ disabled }) =>
    disabled &&
    css`
      input {
        opacity: 0.5;
      }
    `}
`

export const FileContainer = styled(Container)<{ disabled: boolean }>`
  overflow: hidden;

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
      cursor: not-allowed;

      ${File} {
        pointer-events: none;
      }
    `}
`

export const Label = styled.label`
  color: ${({ theme }) => theme.color.black};
`

export const placeholderStyles = () => css`
  color: ${({ theme }) => theme.color.nobel};
`

export const inputStyles = () => css<
  WithError & WithSearchIcon & WithAppearance
>`
  max-width: 100%;
  padding: 0.625rem ${({ searchIcon }) => (searchIcon ? 2 : 1)}rem 0.625rem 1rem;
  overflow: hidden;
  background: ${({ theme }) => theme.color.white};
  color: ${({ theme }) => theme.color.black};
  border-radius: 8px;
  border: 1px solid transparent;
  margin-bottom: ${({ theme }) => theme.fontSize.large};
  ${({ appearance }) =>
    appearance === 'default'
      ? css`
          box-shadow: 4px 20px 60px 0
            ${({ theme }) => changeOpacity(theme.color.black, 0.1)};
        `
      : css`
          border: 1px solid ${({ theme }) => theme.color.nobel};
        `}

  ${transitions({})('border')}

  ${({ error, theme }) =>
    error &&
    css`
      border: 1px solid ${theme.color.cerise};
    `}
  

  &:hover, &:active, &:focus {
    border: 1px solid ${({ theme }) => theme.color.grape};
  }

  &::placeholder {
    ${placeholderStyles()}
  }

  &:disabled {
    cursor: not-allowed;
  }
`

export const Input = styled.input<WithError & WithSearchIcon & WithAppearance>`
  ${inputStyles};
`

export const TextArea = styled.textarea<
  WithError & WithSearchIcon & WithAppearance
>`
  ${inputStyles};
`

export const PhoneNumberInput = styled(PhoneInputWithCountry)<
  WithError & WithSearchIcon & WithAppearance
>`
  ${inputStyles};

  .PhoneInputInput {
    padding: 0;
    margin: 0;
    border: none;
  }
`

export const Error = styled.small`
  position: absolute;
  ${typography.bodySmall};
  color: ${({ theme }) => theme.color.cerise};
  bottom: 0;
  ${oneLineText()};
`

export const Search = styled(Icon)`
  position: absolute;
  right: 7px;
  top: 50%;
  transform: translateY(-50%);

  path {
    fill: ${({ theme }) => theme.color.grape};
  }
`

export const File = styled.label<
  WithError & WithSearchIcon & { isEmpty: boolean } & WithAppearance
>`
  ${inputStyles()};
  ${placeholderStyles()};
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;

  ${({
    isEmpty,
    theme: {
      color: { black },
    },
  }) =>
    !isEmpty &&
    css`
      color: ${black};
    `}
`

export const FileName = styled.span`
  display: block;
  ${oneLineText()};
`

export const Delete = styled.button`
  min-width: 0.825rem;
  width: 0.825rem;
`
