import styled, { css } from 'styled-components'

import { Button, Icon } from '@/components'
import { ConfirmationModal } from '@/components/Modal/ConfirmationModal/ConfirmationModal'
import { media } from '@/styles/media'
import {
  flexCenter,
  flexColumn,
  flexRow,
  oneLineText,
  transitions,
} from '@/styles/utils'

export const StyledModal = styled(ConfirmationModal)`
  height: auto;
  ${media.mobile(
    () => css`
      max-width: 25rem;
      width: 22rem;
    `,
  )};
`

export const PrePostPriceText = styled.p`
  font-size: ${({ theme }) => theme.fontSize.normal};
  color: ${({ theme }) => theme.color.doveGray};
`

export const Container = styled.div`
  height: 22.125rem;
  width: 29.75rem;
  border-radius: 18px;
  padding: 1rem;
  background-color: ${({ theme }) => theme.color.mangolia};
  overflow: hidden;
  ${flexColumn()};

  ${media.mobile(
    () => css`
      width: 100%;
      height: 22.125rem;
    `,
  )}
`

export const Header = styled.div`
  background-color: ${({ theme }) => theme.color.aliceBlue};
  padding: 1rem 0rem;
  border-radius: 8px;
  ${flexRow()};
  justify-content: center;
  gap: 1rem;
`

export const BillingText = styled.span`
  font-size: ${({ theme }) => theme.fontSize.big};
`

export const Body = styled.div`
  height: 100%;
`

const statusHeight = '1.25rem'
const statusCircleSize = `calc(${statusHeight} - 6px)`

export const IntervalStatus = styled.button<{ isYearly: boolean }>`
  position: relative;
  ${transitions({})('padding')};
  padding: ${({ isYearly }) =>
    `0.25rem ${isYearly ? statusHeight : '0.25rem'} 0.25rem ${
      isYearly ? '0.25rem' : statusHeight
    }`};
  font-size: ${({ theme }) => theme.fontSize.small};
  width: 3rem;
  border-radius: 32px;
  height: ${statusHeight};
  flex-shrink: 0;
  ${oneLineText()};
  ${({
    isYearly,
    theme: {
      color: { plumsTheWorld, greenKelp, alto, black },
    },
  }) =>
    isYearly
      ? css`
          background-color: ${plumsTheWorld};
          color: ${greenKelp};
        `
      : css`
          background-color: ${alto};
          color: ${black};
        `}

  &:before {
    content: '';
    ${transitions({})('left')};
    position: absolute;
    background-color: ${({ theme }) => theme.color.grape};
    border-radius: 50%;
    height: ${statusCircleSize};
    width: ${statusCircleSize};
    left: ${({ isYearly }) => (isYearly ? '65%' : '8%')};
    top: 50%;
    transform: translateY(-50%);
  }

  &:hover {
    &:before {
      left: ${({ isYearly }) => (isYearly ? '60%' : '15%')};
    }
  }
`

export const LoadingContainer = styled.div`
  ${flexCenter()};
  flex: 1;
`
export const PriceBulletsContainer = styled.div`
  height: 100%;
  ${flexColumn()};
`

export const Bullets = styled.ul`
  flex: 1;
  ${flexColumn};
  gap: 0.3rem;
  width: 100%;
`

export const Bullet = styled.li`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`

export const Check = styled(Icon)`
  max-width: 1.25rem;
  height: auto;
`

export const Price = styled.p`
  font-size: ${({ theme }) => theme.fontSize.huge};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  text-align: center;
  padding-top: 0.5rem;
  padding-bottom: 1rem;
`

export const CrossedPrice = styled.span`
  font-size: ${({ theme }) => theme.fontSize.large};
  font-weight: ${({ theme }) => theme.fontWeight.light};
  text-decoration: line-through;
`

export const Paragraph = styled.p`
  font-size: ${({ theme }) => theme.fontSize.normal};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  margin-bottom: 0.3rem;
`

export const Subscribe = styled(Button)``
