import Chart from 'react-apexcharts'

import { Loader } from '@/components'

import type { DateQuery } from '../../overview'
import { Card } from '../ChildRepots.styled'
import usePieChartStudents from './usePieChartStudents'

export function ChildPieChart({ date }: { date: DateQuery }) {
  const { graphRender, loading } = usePieChartStudents(
    date.startDate,
    date.endDate,
  )

  return (
    <Card>
      {loading ? (
        <Loader size="4em" color="codeCurrent" />
      ) : (
        <Chart
          series={graphRender.series}
          options={graphRender}
          height={graphRender.chart?.height ?? 'auto'}
          type="donut"
        />
      )}
    </Card>
  )
}
