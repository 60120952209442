import Chart from 'react-apexcharts'
import styled from 'styled-components'

import { Loader } from '@/components'

import type { DateQuery } from '../../overview'
import usePayingChart from './usePayingChart'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1em;
  width: 100%;
  background-color: ${({ theme }) => theme.color.white};
  border-radius: 1rem;
  padding: 1rem;
`

export default function ParentPayingChart({ date }: { date: DateQuery }) {
  const { loading, renderGraph } = usePayingChart(date)
  return (
    <Container>
      {loading ? (
        <Loader size="4em" color="codeCurrent" />
      ) : (
        <Chart
          series={renderGraph.series}
          type="area"
          options={renderGraph}
          height={renderGraph.chart?.height}
        />
      )}
    </Container>
  )
}
