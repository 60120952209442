import { Analytics } from '@/core/services/analytics/analytics'
import type {
  AdDrivenSiteVisitProps,
  CancelSubscriptionData,
  OpenCourseInfoProps,
  ParentAcquireMarketplaceItemProps,
  ParentAddChildProps,
  ParentChangeChildPasswordProps,
  ParentCreateCustomLessonProps,
  ParentDeleteChildProps,
  ParentEditChildProfileProps,
  ParentEditLessonProps,
  ParentFreeTrialInitProps,
  ParentInteractWithMarketplaceProps,
  ParentMarketplacePurchaseFailProps,
  ParentMarketplacePurchaseSuccessProps,
  ParentOpenLessonPageProps,
  ParentProps,
  ParentRegistrationEventProps,
  ParentRegistrationFailEventProps,
  ParentRescheduleCourseProps,
  ParentRescheduleLessonProps,
  ParentRescheduleUnitProps,
  ParentScheduleCourseProps,
  ParentScheduleLessonProps,
  ParentScheduleUnitProps,
  ParentSubscribeChildFailProps,
  ParentUnscheduleCourseProps,
  ParentUnscheduleLessonProps,
  ParentUnscheduleUnitProps,
  ParentUpgradeSubscriptionToAnnualProps,
  ParentViewMarketplaceItemProps,
} from '@/core/services/analytics/types'
import { ParentEvent } from '@/core/services/analytics/types'

export class ParentAnalytics extends Analytics {
  public emitAdDrivenSiteVisit(props: AdDrivenSiteVisitProps) {
    this.emitUnauthorizedEvent(ParentEvent.adDrivenSiteVisit, props)
  }

  public emitOpenMarketplaceClicked() {
    this.emitUnauthorizedEvent(ParentEvent.ClickedOpenMarketplace)
  }

  public emitRegistrationStart() {
    this.emitUnauthorizedEvent(ParentEvent.RegistrationStart)
  }

  public emitRegistration(props: ParentRegistrationEventProps) {
    this.emitUnauthorizedEvent(ParentEvent.Registration, props)
  }

  public emitRegistrationFail(props: ParentRegistrationFailEventProps) {
    this.emitUnauthorizedEvent(ParentEvent.RegistrationFail, props)
  }

  public emitEmailVerify() {
    this.emitUnauthorizedEvent(ParentEvent.EmailVerify)
  }

  public emitHomePageVisited() {
    this.emitEvent(ParentEvent.HomePageVisited)
  }

  public emitFreeTrialInit(props: ParentFreeTrialInitProps) {
    this.emitEvent(ParentEvent.FreeTrialInit, props)
  }

  public emitSubscribeChildFail(props: ParentSubscribeChildFailProps) {
    this.emitEvent(ParentEvent.SubscribeChildFail, props)
  }

  public emitUpgradeSubscriptionToAnnual(
    props: ParentUpgradeSubscriptionToAnnualProps,
  ) {
    this.emitEvent(ParentEvent.UpgradeSubscriptionToAnnual, props)
  }

  public emitCancelSubscriptionStarted(props: CancelSubscriptionData) {
    this.emitEvent(ParentEvent.cancelSubscriptionStarted, props)
  }

  public emitCancelSubscriptionFinished(props: CancelSubscriptionData) {
    this.emitEvent(ParentEvent.cancelSubscriptionFinished, props)
  }

  public emitOpenMarketplace() {
    this.emitEvent(ParentEvent.OpenMarketplace)
  }

  public emitOpenFullCalendar() {
    this.emitEvent(ParentEvent.OpenFullCalendar)
  }

  public emitInteractWithMarketplace(
    props: ParentInteractWithMarketplaceProps,
  ) {
    this.emitEvent(ParentEvent.InteractWithMarketplace, props)
  }

  public emitScheduleLesson(props: ParentScheduleLessonProps) {
    this.emitEvent(ParentEvent.ScheduleLesson, props)
  }

  public emitUnscheduleLesson(props: ParentUnscheduleLessonProps) {
    this.emitEvent(ParentEvent.UnscheduleLesson, props)
  }

  public emitRescheduleLesson(props: ParentRescheduleLessonProps) {
    this.emitEvent(ParentEvent.RescheduleLesson, props)
  }

  public emitScheduleCourse(props: ParentScheduleCourseProps) {
    this.emitEvent(ParentEvent.ScheduleCourse, props)
  }

  public emitScheduleUnit(props: ParentScheduleUnitProps) {
    this.emitEvent(ParentEvent.ScheduleUnit, props)
  }

  public emitUnscheduleCourse(props: ParentUnscheduleCourseProps) {
    this.emitEvent(ParentEvent.UnscheduleCourse, props)
  }

  public emitRescheduleCourse(props: ParentRescheduleCourseProps) {
    this.emitEvent(ParentEvent.RescheduleCourse, props)
  }

  public emitRescheduleUnit(props: ParentRescheduleUnitProps) {
    this.emitEvent(ParentEvent.RescheduleUnit, props)
  }

  public emitUnscheduleUnit(props: ParentUnscheduleUnitProps) {
    this.emitEvent(ParentEvent.UnscheduleUnit, props)
  }

  public emitEditProfile() {
    this.emitEvent(ParentEvent.EditProfile)
  }

  public emitEditChildProfile(props: ParentEditChildProfileProps) {
    this.emitEvent(ParentEvent.EditChildProfile, props)
  }

  public emitChangeChildPassword(props: ParentChangeChildPasswordProps) {
    this.emitEvent(ParentEvent.ChangeChildPassword, props)
  }

  public emitAddChild(props: ParentAddChildProps) {
    this.emitEvent(ParentEvent.AddChild, props)
  }

  public emitDeleteChild(props: ParentDeleteChildProps) {
    this.emitEvent(ParentEvent.DeleteChild, props)
  }

  public emitViewNonAutoGradedWork() {
    this.emitEvent(ParentEvent.ViewNonAutoGradedWork)
  }

  public emitViewMarketplaceItem(props: ParentViewMarketplaceItemProps) {
    this.emitEvent(ParentEvent.ViewMarketplaceItem, props)
  }

  public emitAcquireMarketplaceItem(props: ParentAcquireMarketplaceItemProps) {
    this.emitEvent(ParentEvent.AcquireMarketplaceItem, props)
  }

  public emitMarketplacePurchaseSuccess(
    props: ParentMarketplacePurchaseSuccessProps,
  ) {
    this.emitEvent(ParentEvent.MarketplacePurchaseSuccess, props)
  }

  public emitMarketplacePurchaseFail(
    props: ParentMarketplacePurchaseFailProps,
  ) {
    this.emitEvent(ParentEvent.MarketplacePurchaseFail, props)
  }

  public emitCreateCustomCourse() {
    this.emitEvent(ParentEvent.CreateCustomCourse)
  }

  public emitCreateCustomLesson(props: ParentCreateCustomLessonProps) {
    this.emitEvent(ParentEvent.CreateCustomLesson, props)
  }

  public emitEditLesson(props: ParentEditLessonProps) {
    this.emitEvent(ParentEvent.EditLesson, props)
  }

  public emitOpenResources() {
    this.emitEvent(ParentEvent.OpenResources)
  }

  public emitOpenAnalytics() {
    this.emitEvent(ParentEvent.OpenAnalytics)
  }

  public emitOpenDetailedAnalytics() {
    this.emitEvent(ParentEvent.OpenDetailedAnalytics)
  }

  public emitOpenLesson(props: ParentOpenLessonPageProps) {
    this.emitEvent(ParentEvent.OpenLessonPage, props)
  }

  public emitLessonVideoWatched() {
    this.emitEvent(ParentEvent.LessonVideoWatched)
  }

  public emitLessonVideoWatchedComplete() {
    this.emitEvent(ParentEvent.LessonVideoWatchedComplete)
  }

  public emitDisableAutoPilot(props: ParentEditChildProfileProps) {
    this.emitEvent(ParentEvent.DisableAutoPilot, props)
  }

  public emitEnableAutoPilot(props: ParentEditChildProfileProps) {
    this.emitEvent(ParentEvent.EnableAutoPilot, props)
  }

  public emitSkippedTutorial() {
    this.emitEvent(ParentEvent.SkippedTutorial)
  }

  public emitWatchedTutorial() {
    this.emitEvent(ParentEvent.WatchedTutorial)
  }

  public emitOpenCourseInfo(props: OpenCourseInfoProps) {
    this.emitEvent(ParentEvent.openCourseInfo, props)
  }

  public emitOpenDigitalAd(props: ParentProps) {
    this.emitEvent(ParentEvent.digitalAdOpened, props)
  }
}

export const parentAnalytics = new ParentAnalytics()
