import Angry from '@/assets/images/emojis/angry.gif'
import Calm from '@/assets/images/emojis/calm.gif'
import Confused from '@/assets/images/emojis/confused.gif'
import Nervous from '@/assets/images/emojis/curious.gif'
import Distracted from '@/assets/images/emojis/distracted.gif'
import Excited from '@/assets/images/emojis/excited.gif'
import Happy from '@/assets/images/emojis/happy.gif'
import Curious from '@/assets/images/emojis/nervous.gif'
import Sad from '@/assets/images/emojis/sad.gif'
import Silly from '@/assets/images/emojis/silly.gif'
import LogoBig from '@/assets/images/logo-big.png'
import Rocket from '@/assets/images/rocket.png'
import TransitionBottom from '@/assets/images/transition-bottom.png'
import TransitionTop from '@/assets/images/transition-top.png'

export const imagesToPreload = [
  LogoBig,
  Rocket,
  TransitionTop,
  TransitionBottom,
  Angry,
  Calm,
  Confused,
  Curious,
  Distracted,
  Excited,
  Happy,
  Nervous,
  Sad,
  Silly,
]
