import styled, { keyframes } from 'styled-components'

import type { Color, Size } from '@/styles/theme'
import { fadeInAnimation, flexCenter } from '@/styles/utils'

const roll = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`

const Container = styled.div`
  ${flexCenter()};
  ${fadeInAnimation()};
`

const Spinner = styled.span<Required<LoaderProps>>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  border: 3px solid ${({ theme, color }) => theme.color[color]};
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  animation: ${roll} 1000ms cubic-bezier(0.75, 0.19, 0.58, 0.84) infinite;
`

type LoaderProps = {
  size?: Size
  color?: Color
}

export function Loader({ size = '1.4rem', color = 'white' }: LoaderProps) {
  return (
    <Container data-testid="loader">
      <Spinner size={size} color={color} />
    </Container>
  )
}
