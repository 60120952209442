import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { useAuthUser } from '@/store/user'

const Footer = styled.div`
  border-top: 1px solid ${({ theme }) => theme.color.alto}};
  display: flex;
  justify-content: center;
  padding-bottom: 1em;
  padding-top: 1em;
`
const LogOut = styled.p`
  color: ${({ theme }) => theme.color.white};
`

const LogoutLink = styled(Link)``

export function NavbarFooter() {
  const { clearUser } = useAuthUser()
  const { t } = useTranslation('admin', {
    keyPrefix: 'navigation',
  })
  return (
    <Footer>
      <LogoutLink to="" onClick={clearUser}>
        {/* <Icon icon="closeSquare" /> */}
        <LogOut>{t('signOut')}</LogOut>
      </LogoutLink>
    </Footer>
  )
}
