import type { ComponentPropsWithRef } from 'react'
import styled, { css } from 'styled-components'

import type { Size } from '@/styles/theme'
import { changeOpacity, flexCenter, transitions } from '@/styles/utils'

export const bodyOpenClassName = 'modal-open'
export const closeTimeout = 200
const closeIconSize = '1.1rem'

export type ClosePosition = {
  top: Size
  right: Size
}

export const Overlay = styled.div<ComponentPropsWithRef<'div'>>`
  z-index: 100;
  position: fixed;
  inset: 0;
  background-color: ${({ theme }) => changeOpacity(theme.color.black, 0.55)};
  ${flexCenter()}
  opacity: 0;
  ${transitions({ duration: closeTimeout })('opacity')}

  &.ReactModal__Overlay--after-open {
    opacity: 1;
  }

  &.ReactModal__Overlay--before-close {
    opacity: 0;
  }
`
export const Content = styled.div`
  position: absolute;
  outline: none;

  opacity: 0;
  transform: scale(0.9);

  ${transitions({ duration: closeTimeout })('transform', 'opacity')};

  &.ReactModal__Content--after-open {
    opacity: 1;
    transform: scale(1);
  }

  &.ReactModal__Content--before-close {
    opacity: 0;
    transform: scale(0.9);
  }
`

export const CloseIcon = styled.button<{ position: ClosePosition }>`
  position: absolute;
  width: ${closeIconSize};
  height: ${closeIconSize};
  ${flexCenter()};
  ${({ position }) => css`
    right: ${position.right};
    top: ${position.top};
  `}
`
