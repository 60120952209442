import type { ComponentType } from 'react'
import { lazy } from 'react'

export function lazyImport<
  T extends ComponentType<any>,
  I extends { [K2 in K]: T },
  K extends keyof I,
>(factory: () => Promise<I>, name: K): I {
  return Object.create({
    [name]: lazy(async () =>
      factory().then((module) => ({ default: module[name] })),
    ),
  }) as I
}
