import { joiResolver } from '@hookform/resolvers/joi/dist/joi'
import html2canvas from 'html2canvas'
import Joi from 'joi'
import { useEffect, useState } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { Icon, Input } from '@/components'
import { Label } from '@/components/Input/Input.styled'
import { TextArea } from '@/components/Input/TextArea'
import { getUserAgentData } from '@/core/utils/getUserFeedbackRelatedData'
import { wait } from '@/core/utils/wait'
import { validators } from '@/core/validation'
import { useSaveUnauthFeedbackMutation } from '@/graphql/generated/types'

import { PhoneNumber } from '../Input/PhoneNumber'
import type { ScreenshotType } from '../UserFeedback/UserFeedback'
import { maxScreenshotsAllowed } from '../UserFeedback/UserFeedback'
import {
  AddScreenshot,
  Close,
  Container,
  Header,
  Open,
  ReportForm,
  Screenshot,
  ScreenshotContainer,
  ScreenshotDelete,
  Screenshots,
  ScreenshotsContainer,
  Title,
} from '../UserFeedback/UserFeedback.styled'
import { HalfContainer, SubmitButton } from './UserFeedbackUnauth.styled'

type ReportSchema = {
  title: string
  message: string
  firstName: string
  lastName: string
  email: string
  phone: string | undefined
  screenshots: ScreenshotType[]
}

const schema = Joi.object<ReportSchema>({
  title: validators.stringRequired(),
  message: validators.stringRequired(),
  firstName: validators.name(),
  lastName: validators.name(),
  email: validators.email(),
  phone: validators.phoneNumberOptional(),
  screenshots: validators.reportScreenshots(),
})

export function UserFeedbackUnauth() {
  const { t } = useTranslation('common', {
    keyPrefix: 'userFeedback',
  })

  const [isVisible, setIsVisible] = useState(true)
  const [isOpen, setIsOpen] = useState(false)
  const {
    register,
    handleSubmit,
    reset,
    getValues,
    control,
    formState: { errors },
  } = useForm<ReportSchema>({
    resolver: joiResolver(schema),
    defaultValues: {
      title: '',
      message: '',
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      screenshots: [],
    },
  })

  const { fields, append, remove } = useFieldArray({
    name: 'screenshots',
    control,
  })

  const [saveUnauthFeedback, { loading }] = useSaveUnauthFeedbackMutation({
    fetchPolicy: 'no-cache',
    onCompleted() {
      toast.success(t('yourFeedbackWasSent'))
      onToggle()
      reset()
    },
  })

  useEffect(() => {
    if (isOpen && getValues().screenshots.length === 0) {
      onAddScreenshot()
    }
  }, [isOpen])

  const onToggle = () => {
    setIsOpen((prev) => !prev)
  }

  const onSubmit = ({
    message,
    title,
    screenshots,
    firstName,
    lastName,
    email,
    phone,
  }: ReportSchema) => {
    saveUnauthFeedback({
      variables: {
        input: {
          title,
          message,
          firstName,
          lastName,
          email,
          phone: phone ?? '',
          screenshots: screenshots.map(
            ({ blob }, index) => new File([blob], `${index}.png`),
          ),
          userAgent: getUserAgentData(),
          url: window.location.href,
          submissionCategoryLabel: '',
          submissionCategoryValue: '',
        },
      },
    })
  }

  const onAddScreenshot = async () => {
    setIsVisible(false)
    await wait(100)

    const canvas = await html2canvas(document.body, {
      useCORS: true,
    })
    const response = await fetch(canvas.toDataURL())
    const blob = await response.blob()

    append({
      blob,
      src: URL.createObjectURL(blob),
    })

    setIsVisible(true)
  }

  return (
    <Container isVisible={isVisible}>
      {isOpen ? (
        <ReportForm onSubmit={handleSubmit(onSubmit)}>
          <Header>
            <Title>{t('report')}</Title>
            <Close onClick={onToggle}>
              <Icon icon="close" />
            </Close>
          </Header>
          <Input
            {...register('title')}
            label={t('title')}
            placeholder={t('notAbleToAccess')}
            error={errors.title?.message}
          />
          <HalfContainer>
            <Input
              label={t('firstName')}
              placeholder={t('firstNamePlaceholder')}
              error={errors.firstName?.message}
              {...register('firstName')}
            />
            <Input
              label={t('lastName')}
              placeholder={t('lastNamePlaceholder')}
              error={errors.lastName?.message}
              {...register('lastName')}
            />
          </HalfContainer>
          <Input
            label={t('email')}
            placeholder={t('emailPlaceholder')}
            error={errors.email?.message}
            {...register('email')}
          />
          <PhoneNumber<ReportSchema>
            control={control}
            name="phone"
            label={t('phone')}
            placeholder={t('phonePlaceholder')}
            error={errors.phone?.message}
          />
          <TextArea
            label={t('message')}
            placeholder={t('yourMessage')}
            {...register('message')}
            error={errors.message?.message}
          />
          <ScreenshotsContainer>
            <Label>{t('screenshots')}</Label>
            <Screenshots>
              {fields.map(({ src, id }, index) => (
                <ScreenshotContainer key={id}>
                  <ScreenshotDelete
                    onClick={(event) => {
                      event.preventDefault()
                      remove(index)
                    }}
                  >
                    <Icon icon="delete" />
                  </ScreenshotDelete>
                  <Screenshot src={src} alt={'screenshot ' + String(index)} />
                </ScreenshotContainer>
              ))}
              {fields.length < maxScreenshotsAllowed && (
                <AddScreenshot
                  onClick={(event) => {
                    event.preventDefault()
                    onAddScreenshot()
                  }}
                >
                  {t('addScreenshot')}
                </AddScreenshot>
              )}
            </Screenshots>
          </ScreenshotsContainer>
          <SubmitButton loading={loading}>{t('submit')}</SubmitButton>
        </ReportForm>
      ) : (
        <Open rounded onClick={onToggle}>
          <Icon icon="chatSquareTextFill" />
        </Open>
      )}
    </Container>
  )
}
