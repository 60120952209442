import { Route, Routes } from 'react-router-dom'

import { AdminContainer } from './AdminContainer'
import { AdminRoute } from './adminRoute'
import { ChildReports } from './child'
import { Location } from './location'
import { Overview } from './overview'
import { ParentReports } from './parent'

export function AdminRoutes() {
  return (
    <Routes>
      <Route element={<AdminContainer />}>
        <Route path={AdminRoute.Default} element={<Overview />} />
        <Route path={AdminRoute.Location} element={<Location />} />
        <Route path={AdminRoute.Educator} element={<ParentReports />} />
        <Route path={AdminRoute.Student} element={<ChildReports />} />
      </Route>
    </Routes>
  )
}

export default AdminRoutes
