import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Slider from 'react-slick'

import boyCalculate from '@/assets/images/landing/boy-calculate.webp'
import boyMusic from '@/assets/images/landing/boy-music.webp'
import boyPlane from '@/assets/images/landing/boy-plane.webp'
import girlPuzzle from '@/assets/images/landing/girl-puzzle.webp'

import { MainTitle, MaxWidthContainer } from '../../Landing.styled'
import type { CardProps } from './components/Card'
import { Card } from './components/Card'
import { Dot, Main } from './Loved.styled'

const cardData: CardProps[] = [
  {
    color: 'Blue',
    imgSrc: boyCalculate,
    line: 'schoolioIsTheBest',
    personName: 'personNameSierra',
    isReverse: false,
  },
  {
    color: 'Green',
    imgSrc: boyPlane,
    line: 'iUsedSchoolio',
    personName: 'personNameMelissa',
    isReverse: true,
  },
  {
    color: 'Purple',
    imgSrc: boyMusic,
    line: 'theCurriculamIs',
    personName: 'personNameKatherine',
    isReverse: false,
  },
  {
    color: 'Red',
    imgSrc: girlPuzzle,
    line: 'iLoveThisProgram',
    personName: 'personNameMelanie',
    isReverse: true,
  },
  {
    color: 'Blue',
    imgSrc: boyCalculate,
    line: 'previewProgram',
    personName: 'personNameKate',
    isReverse: false,
  },
  {
    color: 'Green',
    imgSrc: boyPlane,
    line: 'howComprehensive',
    personName: 'personNameTiffany',
    isReverse: true,
  },
  {
    color: 'Purple',
    imgSrc: boyMusic,
    line: 'schedulingLayout',
    personName: 'personNameAshley',
    isReverse: false,
  },
  {
    color: 'Red',
    imgSrc: girlPuzzle,
    line: 'newDigital',
    personName: 'personNameLisa',
    isReverse: true,
  },
]

export function Loved() {
  const { t } = useTranslation('landing')
  const [currentSlide, setCurrentSlide] = useState(0)
  // const [windowWidth, setWindowWidth] = useState(0)
  // const resizeWindow = useCallback(() => {
  //   setWindowWidth(window.innerWidth)
  // }, [])

  // useEffect(() => {
  //   resizeWindow()
  //   window.addEventListener('resize', resizeWindow)
  //   return () => {
  //     window.removeEventListener('resize', resizeWindow)
  //   }
  // }, [])

  const settings = {
    dots: true,
    dotsClass: 'slick-dots',
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2500,
    beforeChange(prev: number, next: number) {
      setCurrentSlide(next)
    },
    customPaging(i: number) {
      return <Dot isActive={i === currentSlide} sequence={i + 1} />
    },
  }

  return (
    <MaxWidthContainer>
      <Main>
        <MainTitle marginBottom={6}>{t('titles.lovedByLearners')}</MainTitle>

        {/* {windowWidth >= 1025 && (
          <>
            {cardData.map(function (card) {
              return (
                <Card
                  key={card.line}
                  color={card.color}
                  imgSrc={card.imgSrc}
                  line={t(`lines.${card.line}`)}
                  personName={t(`lines.${card.personName}`)}
                  isReverse={card.isReverse}
                />
              )
            })}
          </>
        )} */}

        <div className="slider-container">
          {/* {windowWidth < 1025 && ( */}
          <Slider {...settings}>
            {cardData.map(function (card) {
              return (
                <Card
                  key={card.line}
                  color={card.color}
                  imgSrc={card.imgSrc}
                  line={t(`lines.${card.line}`)}
                  personName={t(`lines.${card.personName}`)}
                  isReverse={card.isReverse}
                />
              )
            })}
          </Slider>
          {/* )} */}
        </div>
      </Main>
    </MaxWidthContainer>
  )
}
