import { useTranslation } from 'react-i18next'

import { Error as StyledError } from '@/components/Input/Input.styled'
import { validationLengths } from '@/core/validation'

export type ErrorType =
  | string
  | {
      message?: string
      field: string
    }

type ErrorProps = {
  error: ErrorType
  label?: string
  className?: string
}

export const getIsError = (error: ErrorType | undefined) => {
  if (!error) {
    return false
  }

  return typeof error === 'string' ? Boolean(error) : Boolean(error.message)
}

export function Error({ error, label, className }: ErrorProps) {
  const { t } = useTranslation('error')

  return (
    <>
      {error && typeof error === 'string' && (
        <StyledError className={className}>
          {t(error, { ...validationLengths, field: label })}
        </StyledError>
      )}
      {error && typeof error !== 'string' && error.message && (
        <StyledError className={className}>
          {t(error.message, { ...validationLengths, field: error.field })}
        </StyledError>
      )}
    </>
  )
}
