import { useEffect, useMemo, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'

import { parentAnalytics } from '@/core/services/analytics'
import { mediaPath } from '@/core/services/mediaPath'
import { throttle } from '@/core/utils/throttle'
import { useCourseDetailsForMarketplaceLazyQuery } from '@/graphql/generated/types'

import { Nav } from '../Landing/components/Nav'
import {
  Content,
  Course as CourseDiv,
  CourseName,
  IFrame,
  Image,
  InnerContainer,
  Left,
  Lessons,
  Main,
  Name,
  TitleUnitsAndLessons,
  TopRow,
  Unit,
  UnitSec,
  VideoContainer,
} from './CourseInfo.styled'

export function CourseInfo() {
  const [courseId, setCourseId] = useState<string>('')
  const [urlToSet, setUrlToSet] = useState<string>('')
  const { t } = useTranslation('parent', {
    keyPrefix: 'marketplace',
  })
  const [isCardAbsolute, setIsCardAbsolute] = useState(false)

  useEffect(() => {
    const pathname = window.location.href
    const match = /course-info\/([^/?]+)/.exec(pathname)
    if (match) {
      setCourseId(match[1])
      parentAnalytics.emitOpenCourseInfo({
        courseId: match[1],
        link: pathname,
      })
    }

    setUrlToSet(getBaseUrl(pathname))
  }, [])

  const [courseDetails, { data }] = useCourseDetailsForMarketplaceLazyQuery()

  useEffect(() => {
    if (!courseId) {
      return
    }

    courseDetails({
      variables: {
        id: courseId,
      },
    })
  }, [courseId])

  const extractVideoId = (url: string) => {
    // Regular expression pattern to match the 'v' query parameter
    const regex = /[?&]v=([^&]+)/

    const match = regex.exec(url)

    if (match) {
      const id = match[1]
      return id
    }

    return ''
  }

  const onScroll = (event: Event) => {
    if (isCardAbsolute) {
      return
    }

    const target = event.target as HTMLBodyElement

    const threshold = 186

    setIsCardAbsolute(target.scrollTop > threshold)
  }

  const throttledOnScroll = useMemo(() => throttle(onScroll, 25), [])

  useEffect(() => {
    document.body.addEventListener('scroll', throttledOnScroll)
    return () => {
      document.body.removeEventListener('scroll', throttledOnScroll)
    }
  }, [])

  const getBaseUrl = (url: string) => {
    try {
      const urlObj = new URL(url)
      return `${urlObj.origin}${urlObj.pathname}`
    } catch (error: unknown) {
      return url
    }
  }

  return (
    <Main>
      <Nav />
      <Helmet>
        <meta property="og:url" content={urlToSet} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Course Information" />
        <meta
          property="og:image"
          content={mediaPath.build({
            url: data?.courseDetailsForMarketplace.image,
            folderKey: 'course',
          })}
        />
      </Helmet>

      <InnerContainer>
        <CourseName>{data?.courseDetailsForMarketplace.name}</CourseName>

        <TopRow isAbsolute={isCardAbsolute}>
          <Left>
            <CourseDiv isAbsolute={isCardAbsolute}>
              <Image
                src={mediaPath.build({
                  url: data?.courseDetailsForMarketplace.image,
                  folderKey: 'course',
                })}
              />
              <div>
                <Name>{data?.courseDetailsForMarketplace.name}</Name>
              </div>
            </CourseDiv>
          </Left>

          <UnitSec>
            <TitleUnitsAndLessons>{t('unitsAndLessons')}</TitleUnitsAndLessons>

            {data?.courseDetailsForMarketplace.units.map(
              ({ _id, name, lessons }) => (
                <Unit
                  key={_id}
                  header={`${name} (${lessons.length} ${t(
                    lessons.length > 1 ? 'lessons' : 'lesson',
                  )})`}
                  defaultExpanded={false}
                >
                  <Lessons>
                    {lessons.map(({ _id, name }) => (
                      <li key={_id}>{name}</li>
                    ))}
                  </Lessons>
                </Unit>
              ),
            )}

            {data?.courseDetailsForMarketplace.courseInfoVideoLink &&
              extractVideoId(
                data.courseDetailsForMarketplace.courseInfoVideoLink,
              ) && (
                <VideoContainer>
                  <TitleUnitsAndLessons>Description Video</TitleUnitsAndLessons>
                  <IFrame
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen;"
                    src={`https://www.youtube.com/embed/${extractVideoId(
                      data.courseDetailsForMarketplace.courseInfoVideoLink,
                    )}?rel=0`}
                  />
                </VideoContainer>
              )}

            <TitleUnitsAndLessons>
              {t('courseDescription')}
            </TitleUnitsAndLessons>

            <Content
              dangerouslySetInnerHTML={{
                __html: data?.courseDetailsForMarketplace.description ?? '',
              }}
            />
          </UnitSec>
        </TopRow>
      </InnerContainer>
    </Main>
  )
}
