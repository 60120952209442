import type { Pagination as PaginationType } from '@graphql/generated/types'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'

import {
  Container,
  Counter,
  Next,
  Previous,
  StyledPagination,
} from './Pagination.styled'

type PaginationProps = PaginationType & {
  onPageChange: (page: number) => void
  shouldScroll?: boolean
}

export function Pagination({
  limit,
  page,
  pages,
  total,
  onPageChange: onPageChangeHandler,
  shouldScroll = true,
}: PaginationProps) {
  const { t } = useTranslation('common', {
    keyPrefix: 'pagination',
  })

  const onPageChange = ({ selected }: { selected: number }) => {
    if (shouldScroll) {
      document.body.scroll(0, 0)
    }

    const newPage = selected + 1
    setSearchParams(
      {
        page: String(newPage),
      },
      {
        replace: true,
      },
    )
    onPageChangeHandler(newPage)
  }

  const [, setSearchParams] = useSearchParams()

  if (pages === 0) {
    return null
  }

  return (
    <Container>
      <span>
        <Counter>
          {t('counter', {
            current: Math.min(page * limit, total),
            total,
          })}
        </Counter>{' '}
        {t('shown')}
      </span>
      <StyledPagination
        forcePage={page - 1}
        pageCount={pages}
        previousLabel={<Previous />}
        nextLabel={<Next />}
        pageRangeDisplayed={5}
        onPageChange={onPageChange}
      />
    </Container>
  )
}
