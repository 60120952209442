import { useMemo } from 'react'
import styled, { css } from 'styled-components'

import type { CurrentStep } from '@/pages/auth/SignUp'
import { media } from '@/styles/media'
import { transitions } from '@/styles/utils'

const stepSize = '1.5rem'

const Container = styled.div`
  display: flex;
  align-items: center;

  ${media.tablet(
    () => css`
      background-color: ${({ theme }) => theme.color.white};
      padding: 1rem;
      border-radius: 18px;
    `,
  )}

  ${media.mobile(
    () => css`
      padding: 0.5rem;
      border-radius: 14.5px;
    `,
  )}
`

const checkmarkStick = css`
  position: absolute;
  content: '';
  display: block;
  width: 1.5px;
  transform-origin: bottom center;
  border-radius: 3px;
  background-color: ${({ theme }) => theme.color.white};
`

const activeStyle = () => css`
  background-color: ${({ theme }) => theme.color.grape};
  border: 1px solid ${({ theme }) => theme.color.lilacBush};
`

const passedStyle = () => css`
  ${activeStyle()};
  border: 1px solid ${({ theme }) => theme.color.grape};
  &:before {
    ${checkmarkStick};
    height: 6px;
    rotate: -45deg;
    top: 9px;
    left: 9px;
  }

  &:after {
    ${checkmarkStick};
    height: 10px;
    rotate: 45deg;
    bottom: 7.5px;
    left: 8px;
  }
`

const Step = styled.div<{ state: StepState }>`
  position: relative;
  width: ${stepSize};
  height: ${stepSize};
  border-radius: 50%;
  ${transitions({})('background-color', 'border')};
  background-color: ${({ theme }) => theme.color.moonRaker};
  ${({ state }) => state === 'active' && activeStyle()};
  ${({ state }) => state === 'passed' && passedStyle()};
`

const Line = styled.div<{ passed: boolean }>`
  width: 6rem;
  height: 1px;
  ${transitions({})('background-color')};
  background-color: ${({ theme, passed }) =>
    passed ? theme.color.grape : theme.color.coldPurple};
`

type StepState = 'empty' | 'active' | 'passed'

type StepperProps = {
  currentStep: CurrentStep
}

export function Stepper({ currentStep }: StepperProps) {
  const { initial, finish } = useMemo<Record<CurrentStep, StepState>>(() => {
    if (currentStep === 'initial') {
      return {
        initial: 'active',
        details: 'empty',
        finish: 'empty',
      }
    }

    return {
      initial: 'passed',
      details: 'passed',
      finish: 'passed',
    }
  }, [currentStep])

  return (
    <Container>
      <Step state={initial} />
      <Line passed={currentStep === 'finish'} />
      <Step state={finish} />
    </Container>
  )
}
