/* eslint-disable complexity */
import styled, { css } from 'styled-components'

import { media } from '@/styles/media'

import type { LandingColors } from '../../Landing.styled'

export const Main = styled.div`
  margin: 9.75rem 0;
  border-radius: 4rem;
  width: 100%;

  ${media.tablet(
    () => css`
      margin: 8rem 1;
    `,
  )};
`

export const Cards = styled.div``

export const ImageContainer = styled.div`
  flex: 1 1 0;
`

export const Image = styled.img`
  min-height: 20rem;
  object-fit: cover;

  ${media.tablet(
    () => css`
      min-height: none;
      height: auto;
    `,
  )};
`

export const TextContainer = styled.div`
  flex: 1 1 0;
  text-align: left;
`

export const ColorText = styled.p<{ color: LandingColors }>`
  padding: 2rem;
  color: ${({ color, theme }) =>
    (color === 'Purple' && theme.color.violetMagican) ||
    (color === 'Red' && theme.color.manchesterNights) ||
    (color === 'Green' && theme.color.obscureOlive) ||
    (color === 'Blue' && theme.color.wideSky) ||
    theme.color.black};
  font-size: ${({ theme }) => theme.landingSpecialFontSize.bigger};
  font-weight: ${({ theme }) => theme.fontWeight.regular};

  ${media.tablet(
    () => css`
      font-size: ${({ theme }) => theme.landingSpecialFontSize.normal};
    `,
  )};
`

export const Dot = styled.span<{ sequence: number; isActive: boolean }>`
  height: 20px;
  width: 20px;
  border-radius: 50%;
  display: inline-block;

  background-color: ${({ sequence, isActive, theme }) =>
    (sequence === 3 &&
      (isActive ? theme.color.violetMagican : theme.color.placeboFuchsia)) ||
    (sequence === 4 &&
      (isActive ? theme.color.manchesterNights : theme.color.peachBreeze)) ||
    (sequence === 2 &&
      (isActive ? theme.color.alpineMeadow : theme.color.babyTooth)) ||
    (sequence === 1 &&
      (isActive ? theme.color.wideSky : theme.color.aliceBlue)) ||
    (sequence === 7 &&
      (isActive ? theme.color.violetMagican : theme.color.placeboFuchsia)) ||
    (sequence === 8 &&
      (isActive ? theme.color.manchesterNights : theme.color.peachBreeze)) ||
    (sequence === 6 &&
      (isActive ? theme.color.alpineMeadow : theme.color.babyTooth)) ||
    (sequence === 5 &&
      (isActive ? theme.color.wideSky : theme.color.aliceBlue)) ||
    theme.color.black};

  box-shadow: 2px 2px 0px
    ${({ sequence, isActive, theme }) =>
      (sequence === 3 &&
        (isActive ? theme.color.fadingHorizon : theme.color.white)) ||
      (sequence === 4 &&
        (isActive ? theme.color.indianRed : theme.color.white)) ||
      (sequence === 2 &&
        (isActive ? theme.color.bowserShell : theme.color.white)) ||
      (sequence === 1 &&
        (isActive ? theme.color.codeCurrent : theme.color.white)) ||
      (sequence === 7 &&
        (isActive ? theme.color.fadingHorizon : theme.color.white)) ||
      (sequence === 8 &&
        (isActive ? theme.color.indianRed : theme.color.white)) ||
      (sequence === 6 &&
        (isActive ? theme.color.bowserShell : theme.color.white)) ||
      (sequence === 5 &&
        (isActive ? theme.color.codeCurrent : theme.color.white)) ||
      theme.color.white};
`
