import type { LandingColors } from '@/pages/common/Landing/Landing.styled'

import {
  CardContainer,
  ColorText,
  Image,
  ImageContainer,
  TextContainer,
} from './Card.styled'

export type CardProps = {
  color: LandingColors
  imgSrc: string
  line: string
  personName: string
  isReverse: boolean
}

export function Card({
  color,
  imgSrc,
  line,
  personName,
  isReverse,
}: CardProps) {
  return (
    <>
      {isReverse && (
        <CardContainer isReverse color={color}>
          <TextContainer>
            <ColorText color={color}>
              {'“'} {line} {'”'}
              <br />
              {personName}
            </ColorText>
          </TextContainer>
          <ImageContainer>
            <Image src={imgSrc} />
          </ImageContainer>
        </CardContainer>
      )}

      {!isReverse && (
        <CardContainer color={color}>
          <ImageContainer>
            <Image src={imgSrc} />
          </ImageContainer>
          <TextContainer>
            <ColorText color={color}>
              {'“'} {line} {'”'}
              <br />
              {personName}
            </ColorText>
          </TextContainer>
        </CardContainer>
      )}
    </>
  )
}
