import styled, { css } from 'styled-components'

import {
  changeOpacity,
  fadeInAnimation,
  flexCenter,
  flexColumn,
  transitions,
  typography,
} from '@/styles/utils'

export const Item = styled.button`
  width: 6.125rem;
  ${transitions({})('transform')};
  color: ${({ theme }) => theme.color.white};

  &:hover {
    transform: scale(1.2);
  }
`

export const MoodContainer = styled.div<{ isLoading: boolean }>`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  row-gap: 1rem;
  ${({ isLoading }) =>
    isLoading &&
    css`
      pointer-events: none;
    `}
`

export const Img = styled.img`
  width: 100%;
  object-fit: cover;
`

export const Container = styled.div`
  overflow: hidden;
  position: relative;
  ${flexColumn()};
  align-items: center;
  background: ${({ theme }) => changeOpacity(theme.color.blueViolet, 0.85)};
  padding: 2rem;
  border-radius: 20px;
  box-shadow: 1px 1px 6px 1px
    ${({ theme }) => changeOpacity(theme.color.black, 0.2)};
  color: ${({ theme }) => theme.color.white};
  backdrop-filter: blur(5px);
`

export const Title = styled.h2`
  ${typography.h1()};
`

export const Paragraph = styled.p`
  ${typography.h2()}
  font-weight: ${({ theme }) => theme.fontWeight.regular}
`

export const LoadingContainer = styled.div`
  position: absolute;
  inset: 0;
  background-color: ${({ theme }) => changeOpacity(theme.color.black, 0.5)};
  ${flexCenter()};
  ${fadeInAnimation()};
`

export const Skip = styled.button`
  display: flex;
  ${flexCenter()};
  color: ${({ theme }) => theme.color.white};
  grid-area: 5 / 3;
`
