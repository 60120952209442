import styled from 'styled-components'

import { Button } from '@/components/Button'
import { flexColumn } from '@/styles/utils'

export const Description = styled.p`
  max-width: 27rem;
  text-align: center;
`

export const Buttons = styled.div`
  ${flexColumn()};
  gap: 0.1rem;
`

export const Cancel = styled(Button)`
  color: ${({ theme }) => theme.color.grape};

  && {
    border: none;
  }
`

export const Email = styled.span`
  font-weight: ${({ theme }) => theme.fontWeight.medium};
`
