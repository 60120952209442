import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1em;
  width: 100%;
  background-color: ${({ theme }) => theme.color.white};
  border-radius: 1rem;
  padding: 1rem;
`
export const GridRow = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(186px, 1fr));
  gap: 1rem;
  align-items: center;
`
