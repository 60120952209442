import styled from 'styled-components'

import { flexColumn, scrollbar } from '@/styles/utils'

export const DateRangeContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1em;
  padding: 1em;
  border-radius: 0.75em;
  min-width: fit-content;
  width: auto;
  column-gap: 1em;
  justify-content: center;
  align-content: center;
  background-color: ${({ theme }) => theme.color.white};
`

export const DateColumn = styled.div`
  ${flexColumn()}
  gap: 1em;
`

export const DatePickerContainer = styled.div`
  .react-datepicker {
    font-family: 'Fredoka', sans-serif;
    border: 0;
    box-shadow: 2px 4px 10px ${({ theme }) => theme.color.greyTransperent};
    border-radius: 16px;

    &__day--selected {
      background-color: ${({ theme }) => theme.color.grape};
    }
  }
  .react-datepicker__header {
    border-bottom: 0 !imporant;
    background: ${({ theme }) => theme.color.white};
    border-top-left-radius: 16px;
    border-bottom: 0;
  }

  .react-datepicker-time__header {
    font-weight: ${({ theme }) => theme.fontWeight.regular};
    font-size: ${({ theme }) => theme.fontSize.normal};
    color: ${({ theme }) => theme.color.doveGray};
  }

  .react-datepicker__month-year-dropdown {
    background-color: ${({ theme }) => theme.color.white};
    height: 20em;
    overflow-y: scroll;
    ${scrollbar('white', 'alto')}
  }
  .react-datepicker__time-container {
    border: 0;

    .react-datepicker__time-list-item--selected {
      background-color: ${({ theme }) => theme.color.grape} !important;
    }
  }

  .react-datepicker__header:not(.react-datepicker__header--has-time-select) {
    border-top-right-radius: 16px;
  }

  .react-datepicker__navigation {
    top: 8px;
  }
  .react-datepicker__year-read-view--down-arrow,
  .react-datepicker__month-read-view--down-arrow,
  .react-datepicker__month-year-read-view--down-arrow,
  .react-datepicker__navigation-icon::before {
    border-color: ${({ theme }) => theme.color.black};
    border-width: 2px 2px 0 0;
    right: -16px;
    top: 5px;
  }

  .react-datepicker__month-year-read-view--selected-month-year {
    font-weight: ${({ theme }) => theme.fontWeight.regular};
    font-size: ${({ theme }) => theme.fontSize.normal};
    letter-spacing: -0.03rem;
    color: ${({ theme }) => theme.color.doveGray};
  }
  .react-datepicker__current-month {
    visibility: hidden;
  }
  .react-datepicker__header__dropdown--scroll {
    top: -21px;
    position: relative;
  }
  .react-datepicker__year-dropdown,
  .react-datepicker__month-dropdown,
  .react-datepicker__month-year-dropdown {
    width: 65%;
    left: 20%;
    top: 0;
  }
  .react-datepicker__time-list {
    ${scrollbar('white', 'alto')}
  }
`
