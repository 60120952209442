import { useRecoilState } from 'recoil'

import { courseDetails, defaultValue } from './atoms'

export const useCourseDetails = (courseId?: string) => {
  const [details, setCourseDetails] = useRecoilState(courseDetails)

  const clearCourseDetails = () => {
    setCourseDetails(defaultValue)
  }

  const toggleExpandedUnit = (unitId: string) => {
    if (!courseId) {
      return
    }

    setCourseDetails((prevState) => {
      const isExist = Boolean(
        prevState?.[courseId]?.expandedUnits?.find((id) => id === unitId),
      )

      return {
        ...prevState,
        [courseId]: {
          expandedUnits: isExist
            ? prevState?.[courseId]?.expandedUnits?.filter(
                (id) => id !== unitId,
              ) ?? []
            : [...(prevState?.[courseId]?.expandedUnits ?? []), unitId],
        },
      }
    })
  }

  return {
    details: courseId ? details?.[courseId] : undefined,
    toggleExpandedUnit,
    clearCourseDetails,
  }
}
