import styled, { css, keyframes } from 'styled-components'

import TransitionBottom from '@/assets/images/transition-bottom.png'
import TransitionTop from '@/assets/images/transition-top.png'
import { media } from '@/styles/media'
import { absoluteCenter, flexColumn } from '@/styles/utils'

type WithDuration = {
  duration: number
}

type WithDelay = {
  delay: number
}

const moveTop = keyframes`
  0% {
    opacity: 0;
    background-position: 0 25%;
  }
  2% {
    opacity: 1;
  }
  50% {
    opacity: 1;
    background-position: 25% 0;
  }
  100% {
    opacity: 1;
    background-position: 0 25%;
  }
`

const moveBottom = keyframes`
  0% {
    transform: translateY(100px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
`

const fadeInRocket = keyframes`
  0% {
    bottom: -100%;
    opacity: 0;
  }
  100% {
    bottom: 0;
    opacity: 1;
  }
`

const fadeInContent = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`

export const Container = styled.div`
  position: absolute;
  overflow: hidden;
  z-index: 100;
  inset: 0;
`

export const SchoolioLogo = styled.img`
  width: 20rem;
`

export const Top = styled.section<WithDelay>`
  position: absolute;
  width: 100%;
  height: 90%;
  background: url(${TransitionTop}) no-repeat center;
  background-size: 180%;
  opacity: 0;
  animation: ${moveTop} 60000ms linear ${({ delay }) => delay}ms infinite;
  z-index: -1;

  ${media.tablet(
    () => css`
      background-size: 220%;
    `,
  )}
`

export const Bottom = styled.section<WithDuration>`
  position: absolute;
  width: 100%;
  height: 20%;
  background: url(${TransitionBottom}) no-repeat center;
  background-size: cover;
  bottom: 0;
  animation: ${moveBottom} ${({ duration }) => duration}ms linear 1 forwards;
  z-index: 2;

  ${media.tablet(
    () => css`
      height: 40%;
    `,
  )}
`

export const StyledRocket = styled.img<WithDuration & WithDelay>`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  animation: ${fadeInRocket} ${({ duration }) => duration}ms linear
    ${({ delay }) => delay}ms 1 forwards;
  z-index: 3;
`

export const Content = styled.section<WithDuration & WithDelay>`
  ${absoluteCenter()};
  z-index: 4;
  ${flexColumn()};
  align-items: center;
  color: ${({ theme }) => theme.color.white};
  text-align: center;
  gap: 1rem;
  opacity: 0;
  animation: ${fadeInContent} ${({ duration }) => duration}ms linear
    ${({ delay }) => delay}ms 1 forwards;
`
