import { css } from 'styled-components'

import MuseoSans300 from '@/assets/fonts/MuseoSans-300.woff2'
import MuseoSans500 from '@/assets/fonts/MuseoSans-500.woff2'
import MuseoSans700 from '@/assets/fonts/MuseoSans-700.woff2'
import MuseoSans900 from '@/assets/fonts/MuseoSans-900.woff2'
import SketchnoteText from '@/assets/fonts/SketchnoteText.woff2'

export const fonts = css`
  @font-face {
    font-family: MuseoSans;
    src: url(${MuseoSans300}) format('woff2');
    font-weight: 300;
  }

  @font-face {
    font-family: MuseoSans;
    src: url(${MuseoSans500}) format('woff2');
    font-weight: 500;
  }

  @font-face {
    font-family: MuseoSans;
    src: url(${MuseoSans700}) format('woff2');
    font-weight: 700;
  }

  @font-face {
    font-family: MuseoSans;
    src: url(${MuseoSans900}) format('woff2');
    font-weight: 900;
  }

  @font-face {
    font-family: SketchnoteText;
    src: url(${SketchnoteText}) format('woff2');
    font-weight: normal;
  }
`
