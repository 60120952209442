import { css } from 'styled-components'

import type { Size } from '@/styles/theme'

// eslint-disable-next-line @typescript-eslint/ban-types
type ArgumentTypes<F extends Function> = F extends (
  ...arguments_: infer A
) => unknown
  ? A
  : never
type CssArguments = ArgumentTypes<typeof css>

type Breakpoint = 'largeDesktop' | 'desktop' | 'tablet' | 'mobile'

export const breakpoints: Record<Breakpoint, Size> = {
  largeDesktop: '1440px',
  desktop: '1220px',
  tablet: '1024px',
  mobile: '700px',
}

export const media = {
  largeDesktop: (...arguments_: CssArguments) => css`
    @media (max-width: ${breakpoints.largeDesktop}) {
      ${css(...arguments_)}
    }
  `,
  desktop: (...arguments_: CssArguments) => css`
    @media (max-width: ${breakpoints.desktop}) {
      ${css(...arguments_)}
    }
  `,
  tablet: (...arguments_: CssArguments) => css`
    @media (max-width: ${breakpoints.tablet}) {
      ${css(...arguments_)}
    }
  `,
  tabletMin: (...arguments_: CssArguments) => css`
    @media (min-width: ${breakpoints.tablet}) {
      ${css(...arguments_)}
    }
  `,
  mobile: (...arguments_: CssArguments) => css`
    @media (max-width: ${breakpoints.mobile}) {
      ${css(...arguments_)}
    }
  `,
  custom:
    (maxOrMinWidth: string, applyIfSmaller = true) =>
    (...arguments_: CssArguments) => {
      const query = applyIfSmaller
        ? `(max-width: ${maxOrMinWidth})`
        : `(min-width: ${maxOrMinWidth})`
      return css`
        @media ${query} {
          ${css(...arguments_)}
        }
      `
    },

  bigHeight: (...arguments_: CssArguments) => css`
    @media (max-height: 1200px) {
      ${css(...arguments_)}
    }
  `,

  mediumHeight: (...arguments_: CssArguments) => css`
    @media (max-height: 1100px) {
      ${css(...arguments_)}
    }
  `,

  smallHeight: (...arguments_: CssArguments) => css`
    @media (max-height: 950px) {
      ${css(...arguments_)}
    }
  `,

  customHeight:
    (maxOrMinHeight: string, applyIfSmaller = true) =>
    (...arguments_: CssArguments) => {
      const query = applyIfSmaller
        ? `(max-height: ${maxOrMinHeight})`
        : `(min-height: ${maxOrMinHeight})`
      return css`
        @media ${query} {
          ${css(...arguments_)}
        }
      `
    },
}
