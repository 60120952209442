import { NavLink } from 'react-router-dom'
import styled, { css } from 'styled-components'

import {
  containerMaxWidth,
  parentHeaderHeight,
  parentHeaderHeightMobile,
} from '@/styles/components'
import { media } from '@/styles/media'
import {
  changeOpacity,
  flexCenter,
  transitions,
  typography,
} from '@/styles/utils'

const imageSize = '2rem'
const counterSize = '1rem'

export const Header = styled.header`
  position: absolute;
  left: 0;
  right: 0;
  height: ${parentHeaderHeight};
  background-color: ${({ theme }) => theme.color.haiti};
  z-index: 100;

  ${media.mobile(
    () => css`
      height: ${parentHeaderHeightMobile};
    `,
  )}
`

export const Logo = styled.img`
  width: 7rem;
  height: auto;
  cursor: pointer;
`

export const Nav = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  max-width: ${containerMaxWidth};
  padding: 0 2rem;
  margin: auto;
`

export const Links = styled.div`
  display: flex;
  align-items: center;
  gap: 1.25rem;

  ${media.mobile(
    () => css`
      display: none;
    `,
  )}
`

export const RightPanel = styled.div`
  display: flex;
  align-content: center;
  gap: 2rem;
`

export const Link = styled(NavLink)<{ $active: boolean; $isDisabled: boolean }>`
  display: flex;
  align-items: center;
  gap: 0.25rem;
  ${typography.bodyBold()};
  color: ${({ theme }) => theme.color.white};
  text-decoration: none;
  border-radius: 100px;
  padding: 1rem 1.5rem;
  ${transitions({})('background-color')};

  path {
    ${transitions({})('fill', 'background-color')};
    fill: ${({ theme }) => theme.color.white};
  }

  div[data-tip] {
    display: flex;
    align-items: center;
    gap: 0.25rem;
  }

  ${({
    $active,
    theme: {
      color: { white, electricViolet },
    },
  }) =>
    $active &&
    css`
      background-color: ${changeOpacity(white, 0.05)};
      path {
        fill: ${electricViolet};
      }
    `}

  ${({ $isDisabled }) =>
    $isDisabled &&
    css`
      color: ${({ theme }) => changeOpacity(theme.color.white, 0.5)};
      cursor: not-allowed;

      path {
        fill: ${({ theme }) => changeOpacity(theme.color.white, 0.5)};
      }
    `}
`

export const CommunityLink = styled.a`
  text-decoration: none;
  color: ${({ theme }) => theme.color.white};
  background-color: ${({ theme }) => theme.color.forestGreen};
  padding: 0.5rem 0.75rem;
  border-radius: 10px;
`

export const LinkText = styled.span`
  ${media.tablet(
    () => css`
      display: none;
    `,
  )}
`

export const Image = styled.img`
  width: ${imageSize};
  min-width: ${imageSize};
  height: ${imageSize};
  border-radius: 50%;
`

export const Button = styled.button`
  ${transitions({})('opacity')};
  &:disabled {
    opacity: 0.5;
  }
`

export const Counter = styled.span`
  position: relative;
  right: -10px;
  top: -30px;
  border-radius: 50%;
  ${flexCenter()};
  width: ${counterSize};
  height: ${counterSize};
  ${({
    theme: {
      color: { white, grape },
      fontWeight: { medium },
      fontSize: { tiny },
    },
  }) => css`
    color: ${white};
    background-color: ${grape};
    font-weight: ${medium};
    font-size: ${tiny};
  `}
`
