import type { GetChildProfileOutput } from '@graphql/generated/types'
import { useRecoilState } from 'recoil'

import { childAnalytics } from '@/core/services/analytics'

import { userChildProfile as userProfileAtom } from './atoms'

export const useChildProfile = () => {
  const [userProfile, setUserProfile] = useRecoilState(userProfileAtom)

  const setProfile = (profile: GetChildProfileOutput) => {
    childAnalytics.setProfile({
      ...profile,
      role: profile.role.role,
      grade: profile.grade?.slug,
    })

    setUserProfile(profile)
  }

  const setLastMoodCheck = (
    lastMoodCheck: GetChildProfileOutput['lastMoodCheck'],
  ) => {
    if (userProfile) {
      setUserProfile({
        ...userProfile,
        lastMoodCheck,
      })
    }
  }

  return {
    profile: userProfile,
    setProfile,
    setLastMoodCheck,
  }
}
