import { usePrivacyPolicyQuery } from '@/graphql/generated/types'
import { MaxWidthContainer } from '@/styles/components'

import { Container, Content, Main, Title } from './PrivacyPolicy.styles'

export function PrivacyPolicy() {
  const { data, loading } = usePrivacyPolicyQuery()

  return (
    <Main>
      <MaxWidthContainer>
        <Container>
          {data && !loading && (
            <>
              <Title
                dangerouslySetInnerHTML={{
                  __html: data.privacyPolicy.title,
                }}
              />
              <Content
                dangerouslySetInnerHTML={{
                  __html: data.privacyPolicy.content,
                }}
              />
            </>
          )}
        </Container>
      </MaxWidthContainer>
    </Main>
  )
}
