import React from 'react'
import { useTranslation } from 'react-i18next'

import checkMark from '@/assets/images/landing/check-mark.svg'
import fire from '@/assets/images/landing/fire.webp'
import cornerLines from '@/assets/images/landing/lines2.svg'
import fireLines from '@/assets/images/landing/lines3.svg'

import { Button, MaxWidthContainer } from '../../Landing.styled'
import {
  BulletLine,
  BulletPoints,
  Checkmark,
  ImageCornerLines,
  ImageFire,
  ImageFireLines,
  Main,
  Tagline,
  UnlimitedContainer,
  UnlimitedHeader,
} from './UnlimitedTrial.styled'

const lines = [
  'noCommitments',
  'openAndGo',
  'allCoreSubject',
  'customizableToYour',
]

export function UnlimitedTrial() {
  const { t } = useTranslation('landing')

  const signUp = () => {
    document.documentElement.scrollTop = 0
    document.body.scrollTop = 0
    // navigate(route.signUp())
  }

  return (
    <MaxWidthContainer>
      <Main>
        <UnlimitedContainer>
          <ImageFire src={fire} />
          <ImageFireLines src={fireLines} />
          <ImageCornerLines src={cornerLines} />
          <UnlimitedHeader>{t('titles.unlimitedFree')}</UnlimitedHeader>

          {lines.map(function (line) {
            return (
              <BulletPoints key={line}>
                <Checkmark src={checkMark} />
                <BulletLine>{t(`lines.${line}`)}</BulletLine>
              </BulletPoints>
            )
          })}

          <Button
            isFullWidthMobile
            bgColor="Orange"
            marginTop={3.5}
            onClick={signUp}
          >
            {t('buttons.btnGetStarted')}
          </Button>

          <Tagline>{t('lines.freeTrialCancelAnytime')}</Tagline>
        </UnlimitedContainer>
      </Main>
    </MaxWidthContainer>
  )
}
