import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import { Button, Loader } from '@/components'
import { mediaPath } from '@/core/services/mediaPath'
import { useGetOneTimeAdvertisementAndMarkReadQuery } from '@/graphql/generated/types'
import { Main as StyledMain } from '@/pages/parent/ParentContainer'
import { route } from '@/pages/routes'
import { changeOpacity, darken, flexCenter, typography } from '@/styles/utils'

const Main = styled(StyledMain)`
  ${flexCenter()};
  gap: 1rem;
  padding: 1rem;
`

const Title = styled.h1`
  ${typography.h1()};
  color: ${({ theme }) => darken(theme.color.grape, 0.25)};
`

const Text = styled.p`
  ${typography.h3};
  max-width: 700px;
  margin-bottom: 2rem;
`

const Close = styled(Button)`
  ${typography.h2()};
  padding: 1.5rem;
`

export const LoaderContainer = styled.div`
  pointer-events: none;
  ${flexCenter()};
  background-color: ${({ theme }) =>
    changeOpacity(theme.color.blueChalk, 0.75)};
  position: absolute;
  inset: 0;
`

const AdImage = styled.img`
  max-width: 700px;
`

export function DigitalAd() {
  const { t } = useTranslation('parent', {
    keyPrefix: 'ads',
  })

  const navigate = useNavigate()

  const onCloseAd = () => {
    navigate(route.parent.default())
  }

  const { data: oneTimeAdvertisementData, loading: isOneTimeAdvertisement } =
    useGetOneTimeAdvertisementAndMarkReadQuery({
      fetchPolicy: 'no-cache',
    })

  useEffect(() => {
    if (!oneTimeAdvertisementData) {
      return
    }

    if (oneTimeAdvertisementData.getOneTimeAdvertisementAndMarkRead === null) {
      onCloseAd()
    }
  }, [oneTimeAdvertisementData])

  return (
    <Main>
      {isOneTimeAdvertisement && (
        <LoaderContainer>
          <Loader />
        </LoaderContainer>
      )}

      <Title>
        {oneTimeAdvertisementData?.getOneTimeAdvertisementAndMarkRead?.title}
      </Title>

      <AdImage
        src={mediaPath.build({
          url: oneTimeAdvertisementData?.getOneTimeAdvertisementAndMarkRead
            ?.advertisementImage,
          folderKey: 'adNotification',
        })}
      />

      <Text
        dangerouslySetInnerHTML={{
          __html:
            oneTimeAdvertisementData?.getOneTimeAdvertisementAndMarkRead
              ?.content ?? '',
        }}
      />
      <Close onClick={onCloseAd}>{t('close')}</Close>
    </Main>
  )
}
