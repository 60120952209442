export function throttle<Arguments extends unknown[]>(
  function_: (...arguments_: Arguments) => unknown,
  timeout: number,
): (...arguments_: Arguments) => void {
  let ready = true
  return (...arguments_) => {
    if (!ready) {
      return
    }

    ready = false
    function_(...arguments_)
    setTimeout(() => {
      ready = true
    }, timeout)
  }
}
