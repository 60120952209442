import Chart from 'react-apexcharts'
import { useTranslation } from 'react-i18next'
import type { SingleValue } from 'react-select'
import styled from 'styled-components'

import type { Option } from '@/components'
import { Loader, Select } from '@/components'

import useCountries from '../hooks/useCountries'
import { Container, GridRow } from './Location.styled'
import { useCityStateChart } from './useCityStateChart'

const Main = styled.div`
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  row-gap: 1.2em;
  width: 100%;
  height: 100vh;
`

export function Location() {
  const { loading, setFilterQuery, filterQuery, graph } = useCityStateChart()
  const countries = useCountries()

  const { t } = useTranslation('admin', { keyPrefix: 'location' })
  return (
    <Main>
      <Container>
        <GridRow>
          <Select
            name="country"
            label={t('filterStates')}
            options={countries.map((country) => ({
              label: country,
              value: country,
            }))}
            defaultValue={{ label: 'Canada', value: 'Canada' }}
            appearance="grey"
            onChange={(e: SingleValue<Option>) => {
              setFilterQuery({ ...filterQuery, country: e?.value ?? '' })
            }}
          />
        </GridRow>
        {loading ? (
          <Loader color="codeCurrent" size="4em" />
        ) : (
          <Chart
            series={graph.series}
            options={graph}
            height={graph.chart?.height ?? 'auto'}
            type="bar"
          />
        )}
      </Container>
    </Main>
  )
}
