import type { PropsWithChildren, ReactNode } from 'react'
import { useId } from 'react'
import type { TooltipProps } from 'react-tooltip'
import ReactTooltip from 'react-tooltip'
import styled from 'styled-components'

type TooltipedProps = {
  content: ReactNode
} & Omit<TooltipProps, 'className'>

const StyledReactTooltip = styled(ReactTooltip)`
  &&& {
    font-size: ${({ theme }) => theme.fontSize.normal};
  }
  max-width: 50rem;
`

const TooltipContainer = styled.div`
  max-width: max-content;
`

export function Tooltiped({
  children,
  content,
  effect = 'solid',
  ...rest
}: PropsWithChildren<TooltipedProps>) {
  const id = useId()

  if (!content) {
    return children as JSX.Element
  }

  return (
    <>
      <TooltipContainer data-tip data-for={id}>
        {children}
      </TooltipContainer>
      <StyledReactTooltip id={id} effect={effect} {...rest}>
        {content}
      </StyledReactTooltip>
    </>
  )
}
