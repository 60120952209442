import type { Control } from 'react-hook-form'
import { Controller } from 'react-hook-form'
import type { FieldPath, FieldValues } from 'react-hook-form/dist/types'

import type { SelectProps } from '@/components'
import { Select } from '@/components'

type ControlledSelectProps<TFieldValues extends FieldValues = FieldValues> =
  SelectProps & {
    control: Control<TFieldValues>
    name: FieldPath<TFieldValues>
  }

export function ControlledSelect<
  TFieldValues extends FieldValues = FieldValues,
>({
  control,
  name,
  options,
  ...selectProps
}: ControlledSelectProps<TFieldValues>) {
  return (
    <Controller<TFieldValues>
      name={name}
      control={control}
      render={({ field: { ref: _, ...rest } }) => (
        <Select
          filterOption={(options, input: string) =>
            options.label.toLowerCase().startsWith(input.toLowerCase())
          }
          {...selectProps}
          {...rest}
          options={options}
        />
      )}
    />
  )
}
