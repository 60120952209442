import { useTranslation } from 'react-i18next'

import book from '@/assets/images/landing/book.webp'
import laptop from '@/assets/images/landing/laptop.webp'
import line from '@/assets/images/landing/line-green.svg'

import {
  LinesContainer,
  MainTitle,
  MaxWidthContainer,
} from '../../Landing.styled'
import { LineListing } from '../LineListing'
import { ImageBook, ImageLaptop, ImageLine, Main } from './Flexible.styled'

const lines = ['printAndGo', 'tabletFriendly', 'addYourOwn', 'switchUpYour']

export function Flexible() {
  const { t } = useTranslation('landing')

  return (
    <MaxWidthContainer>
      <Main>
        <ImageBook src={book} />
        <ImageLaptop src={laptop} />
        <ImageLine src={line} />
        <MainTitle>{t('titles.flexibleToYour')}</MainTitle>

        <LinesContainer>
          <LineListing showSignupBtn lines={lines} color="Blue" />
        </LinesContainer>
      </Main>
    </MaxWidthContainer>
  )
}
