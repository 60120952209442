import type { ApexOptions } from 'apexcharts'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useLocationReportsByDateQuery } from '@/graphql/generated/types'
import { theme } from '@/styles/theme'

import type { DateQuery } from '../Overview'

const useReportingChart = (dateFilter: DateQuery, country: string) => {
  const { data, loading } = useLocationReportsByDateQuery({
    variables: {
      endDate: dateFilter.endDate,
      startDate: dateFilter.startDate,
      country: country === '' ? null : country,
    },
  })

  const { t } = useTranslation('admin', { keyPrefix: 'location' })

  const renderGraph: ApexOptions = useMemo(() => {
    if (data) {
      const graph: ApexOptions = {
        series: [
          {
            name: 'New Educator Registrations',
            data: data.locationReportsByDate.map((x) => ({
              x: new Date(x.date).getTime(),
              y: x.value,
            })),
          },
        ],
        chart: {
          type: 'area',
          stacked: false,
          height: 500,
          zoom: {
            type: 'x',
            enabled: true,
            autoScaleYaxis: true,
          },
          toolbar: {
            show: true,
            autoSelected: 'zoom',
          },
        },
        title: {
          text: t('graphs.countryBased', { country }),
          align: 'left',
          style: {
            fontWeight: theme.fontWeight.medium,
            fontFamily: 'Fredoka',
            color: theme.color.slateGray,
          },
        },
        yaxis: {
          labels: {},
          title: {
            text: 'Users',
          },
        },
        dataLabels: {
          enabled: false,
        },
        fill: {
          type: 'gradient',
          gradient: {
            shadeIntensity: 1,
            inverseColors: false,
            opacityFrom: 0.5,
            opacityTo: 0,
            stops: [0, 90, 100],
          },
        },
        tooltip: {
          shared: true,
          y: {
            formatter(val: number) {
              return val.toFixed(0)
            },
          },
        },
        xaxis: {
          type: 'datetime',
          title: {
            text: 'Time Period',
          },
        },
        noData: {
          text: `No new members have joined from ${country} this month as to date`,
          align: 'center',
          verticalAlign: 'middle',
        },
      }

      return graph
    }

    return {}
  }, [data])

  return {
    renderGraph,
    loading,
  }
}

export default useReportingChart
