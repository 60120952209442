import 'react-phone-number-input/style.css'

import { useId, useMemo } from 'react'
import type { Control } from 'react-hook-form'
import type { FieldPath, FieldValues } from 'react-hook-form/dist/types'
import type { Props } from 'react-phone-number-input/react-hook-form'

import type { InputProps } from '@/components'
import { Error, getIsError } from '@/components/Input/Error'

import { Container, Label, PhoneNumberInput } from './Input.styled'

type PhoneNumberProps<TFieldValues extends FieldValues = FieldValues> = {
  control: Control<TFieldValues>
  name: FieldPath<TFieldValues>
} & Props<InputProps, TFieldValues>

export function PhoneNumber<TFieldValues extends FieldValues = FieldValues>({
  id,
  error,
  label,
  disabled = false,
  appearance = 'default',
  ...rest
}: PhoneNumberProps<TFieldValues>) {
  const generatedId = useId()

  const inputId = generatedId ?? id

  const isError = useMemo(() => getIsError(error), [error])

  return (
    <Container disabled={disabled}>
      {label && <Label htmlFor={inputId}>{label}</Label>}
      {/* @ts-expect-error control type mismatch */}
      <PhoneNumberInput
        appearance={appearance}
        defaultCountry="CA"
        error={isError}
        {...rest}
      />
      {error && <Error error={error} label={label} />}
    </Container>
  )
}
