import { useState } from 'react'

import type { DateQuery } from '../overview'

function getDate(): string {
  const currentUTCDate = new Date()
  currentUTCDate.setUTCDate(1)
  // currentUTCDate.setUTCHours(0, 0, 0, 0)

  return currentUTCDate.toDateString()
}

function getDateToday(): string {
  const today = new Date() // Current date and time
  return today.toDateString()
}

const useDateQuery = () => {
  const [date, setDate] = useState<DateQuery>({
    startDate: getDate(),
    endDate: getDateToday(),
  })
  return { date, setDate }
}

export default useDateQuery
