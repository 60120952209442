import { routeKey } from '@/pages/parent/parentRoute'
import type { Route } from '@/pages/routes'
import { replaceParams } from '@/pages/routes'

export enum DashboardRoute {
  Home = '/',
  SubscribeSuccess = '/thankyou',
  CourseDetails = '/course/:id',
  ChildProfile = '/child-profile',
  DeletedCourses = '/deleted-courses',
}

const buildPath = (route: string) => `${routeKey}${route}`

export const dashboardRoute: Route<typeof DashboardRoute> = {
  home: () => buildPath(DashboardRoute.Home),
  subscribeSuccess: () => buildPath(DashboardRoute.SubscribeSuccess),
  courseDetails: (id) =>
    buildPath(replaceParams(DashboardRoute.CourseDetails, { id })),
  childProfile: () => buildPath(DashboardRoute.ChildProfile),
  deletedCourses: () => buildPath(DashboardRoute.DeletedCourses),
}
