import { useTranslation } from 'react-i18next'

import { Button } from '@/components'
import { Form } from '@/pages/auth/Auth.styled'

import { Content, NextSteps, Paragraph, Step, Title } from './Finish.styled'

type FinishProps = {
  hide: boolean
}

export function Finish({ hide }: FinishProps) {
  const { t } = useTranslation('signUp')
  return (
    <Form as="div" hide={hide}>
      <Content>
        <Title>{t('thanksForSigningUp')}</Title>
        <NextSteps>
          <Step>1</Step>
          <Paragraph>{t('clickToLogin')}</Paragraph>
        </NextSteps>
        <NextSteps>
          <Step>2</Step>
          <Paragraph>{t('logIn')}</Paragraph>
        </NextSteps>
      </Content>
      <Button disabled>{t('verifyEmail')}</Button>
    </Form>
  )
}
