import styled from 'styled-components'

import { Button } from '@/components'

export const HalfContainer = styled.div`
  display: flex;
  gap: 1.25rem;
  & > div {
    width: 47%;
  }
`

export const SubmitButton = styled(Button)`
  overflow: unset;
  height: 3rem !important;
`
