/* eslint-disable complexity */
import { differenceInCalendarDays, format } from 'date-fns'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Loader } from '@/components'
import { globalTrialDays } from '@/core/const'
import { parentAnalytics } from '@/core/services/analytics'
import {
  MsCategory,
  useCreatePaymentSessionMutation,
  useSubscriptionPlansMscBasedQuery,
} from '@/graphql/generated/types'
import { useChild } from '@/store/parent/child'

import {
  LoadingContainer,
  Status,
  StyledStatusLabel,
  Subscribe,
  TimeWhenBilled,
} from '../SubscribeCard.styled'
import {
  BestValueTag,
  ButtonSelectionSec,
  CrossedOutPrice,
  FeaturesTitle,
  ListItem,
  ListSec,
  PlanPostPriceText,
  PlanPrePriceText,
  PlanPrice,
  PlanTitle,
  SeeLessMoreButton,
  SwitcherDiv,
} from './MSCSubscriptionCards.styled'

export const coreBullets = [
  'allFullAccess',
  'coreAccess',
  'coreMath',
  'coreUnlimited',
  'createCustom',
  'autoPilotScheduling',
  'multimediaLessonsActivities',
  'successCoachCheckIns',
  'annotatableWorksheetsAgain',
  'noAdsAgain',
  'siblingDiscount',
]

export const comboBullets = [
  'allFullAccess',
  'fullAccessAllSubjects',
  'fullAccessFutureReadiness',
  'createCustom',
  'autoPilotScheduling',
  'multimediaLessonsActivities',
  'successCoachCheckIns',
  'annotatableWorksheetsAgain',
  'noAdsAgain',
  'siblingDiscount',
]

export const futureReadinessBullets = [
  'allFullAccess',
  'entrepreneurship101',
  'socialEmotionalLearning',
  'inDepthFinancialLiteracy',
  'safeSocialMedia',
  'smartConsumerism',
  'introToAIChatGPT',
  'investingBorrowing',
  'shoppingOnline',
  'annotatableWorksheetsAgain',
  'noAdsAgain',
  'siblingDiscount',
]

export function MSCSubscriptionCards() {
  const { t } = useTranslation('parent', {
    keyPrefix: 'home',
  })

  const { data: dataMSCPlans, loading: loadingMSCPlans } =
    useSubscriptionPlansMscBasedQuery()

  const [isAnnual, setIsAnnual] = useState<boolean>(false)
  const [showAll, setShowAll] = useState(false)

  const handleToggle = () => {
    setShowAll(!showAll)
  }

  const { current } = useChild()

  const [createPaymentSession, { loading: createSessionLoading }] =
    useCreatePaymentSessionMutation({
      onCompleted({ createPaymentSession }) {
        window.location.href = createPaymentSession
      },
    })

  const extractPrice = (priceString: string) => {
    const match = /\$([\d.]+)/.exec(priceString)
    return match ? parseFloat(match[1]) : 0
  }

  const prioritySequence = [
    MsCategory.Core,
    MsCategory.Combo,
    MsCategory.FutureReadiness,
  ]

  const filteredPlans = dataMSCPlans?.subscriptionPlansMSCBased
    .filter((plan) =>
      isAnnual ? plan.interval === 'Year' : plan.interval === 'Month',
    )
    .sort((a, b) => {
      const aPriority = a.MSCategory
        ? prioritySequence.indexOf(a.MSCategory)
        : prioritySequence.length
      const bPriority = b.MSCategory
        ? prioritySequence.indexOf(b.MSCategory)
        : prioritySequence.length
      return aPriority - bPriority
    })

  let corePlanPrice = 0
  let futureReadinessPlanPrice = 0

  filteredPlans?.forEach((plan) => {
    if (plan.MSCategory === 'Core') corePlanPrice = extractPrice(plan.price)
    if (plan.MSCategory === 'FutureReadiness')
      futureReadinessPlanPrice = extractPrice(plan.price)
  })

  const comboIdealPrice = corePlanPrice + futureReadinessPlanPrice

  return (
    <div>
      <SwitcherDiv>
        <StyledStatusLabel active={!isAnnual}>
          {`${t('month')} ${t('billing')}`}
        </StyledStatusLabel>
        <Status
          isAnnual={isAnnual}
          onClick={() => {
            setIsAnnual(!isAnnual)
          }}
        />
        <StyledStatusLabel active={isAnnual}>
          {`${t('yearly')} ${t('billing')}`}
        </StyledStatusLabel>
      </SwitcherDiv>

      {!loadingMSCPlans && (
        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
          {filteredPlans?.slice(0, 3).map((plan, index) => (
            <div
              key={plan._id}
              style={{
                border: '1px solid #ccc',
                padding: '0',
                width: '30%',
                margin: index === 1 ? '0px' : '20px',
              }}
            >
              <PlanTitle bgColor={index === 0 ? '0' : index === 1 ? '1' : '2'}>
                {t(`${plan.MSCategory ?? ''}`)}

                <PlanPrice>
                  {plan.price}
                  {plan.MSCategory === 'Combo' && (
                    <>
                      {' '}
                      <CrossedOutPrice>
                        <del>${comboIdealPrice}</del>
                      </CrossedOutPrice>
                    </>
                  )}
                </PlanPrice>
                {plan.MSCategory === 'Combo' && (
                  <BestValueTag>BEST VALUE</BestValueTag>
                )}
              </PlanTitle>
              <PlanPrePriceText>{plan.prePriceText}</PlanPrePriceText>
              <PlanPostPriceText>{plan.postPriceText}</PlanPostPriceText>

              <ButtonSelectionSec>
                <Subscribe
                  loading={createSessionLoading}
                  onClick={() => {
                    if (current?.isTrialAvailable) {
                      const trialDays = differenceInCalendarDays(
                        current.trialEndAt
                          ? new Date(current.trialEndAt)
                          : new Date().setDate(
                              new Date().getDate() + globalTrialDays,
                            ),
                        new Date(),
                      )
                      parentAnalytics.emitFreeTrialInit({
                        trialDays,
                        interval: plan.interval,
                      })
                    }

                    createPaymentSession({
                      variables: {
                        childId: current?._id ?? '',
                        priceId: plan.stripePriceId,
                      },
                    })
                  }}
                >
                  {t(
                    current?.isTrialAvailable ? 'startFreeTrial' : 'subscribe',
                  )}
                </Subscribe>

                {current?.isTrialAvailable && current.trialEndAt && (
                  <p>
                    {t('freeUntil')}{' '}
                    <TimeWhenBilled>
                      {format(new Date(current.trialEndAt), 'MMMM do')}
                    </TimeWhenBilled>
                  </p>
                )}
                {current?.isTrialAvailable && !current.trialEndAt && (
                  <p>
                    {t('freeUntil')}{' '}
                    <TimeWhenBilled>
                      {format(
                        new Date().setDate(
                          new Date().getDate() + globalTrialDays,
                        ),
                        'MMMM do',
                      )}
                    </TimeWhenBilled>
                  </p>
                )}
              </ButtonSelectionSec>

              {plan.MSCategory === MsCategory.Core && (
                <ListSec>
                  {showAll && (
                    <>
                      <FeaturesTitle>Features</FeaturesTitle>

                      {coreBullets.map((bullet) => (
                        <ListItem key={bullet}>{t(`${bullet}`)}</ListItem>
                      ))}
                    </>
                  )}

                  <SeeLessMoreButton onClick={handleToggle}>
                    {showAll ? 'See less...' : 'See more features...'}
                  </SeeLessMoreButton>
                </ListSec>
              )}

              {plan.MSCategory === MsCategory.FutureReadiness && (
                <ListSec>
                  {showAll && (
                    <>
                      <FeaturesTitle>Features</FeaturesTitle>

                      {futureReadinessBullets.map((bullet) => (
                        <ListItem key={bullet}>{t(`${bullet}`)}</ListItem>
                      ))}
                    </>
                  )}

                  <SeeLessMoreButton onClick={handleToggle}>
                    {showAll ? 'See less...' : 'See more features...'}
                  </SeeLessMoreButton>
                </ListSec>
              )}

              {plan.MSCategory === MsCategory.Combo && (
                <ListSec>
                  {showAll && (
                    <>
                      <FeaturesTitle>Features</FeaturesTitle>

                      {comboBullets.map((bullet) => (
                        <ListItem key={bullet}>{t(`${bullet}`)}</ListItem>
                      ))}
                    </>
                  )}

                  <SeeLessMoreButton onClick={handleToggle}>
                    {showAll ? 'See less...' : 'See more features...'}
                  </SeeLessMoreButton>
                </ListSec>
              )}
            </div>
          ))}
        </div>
      )}

      {loadingMSCPlans && (
        <LoadingContainer>
          <Loader color="grape" />
        </LoadingContainer>
      )}
    </div>
  )
}
