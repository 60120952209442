import { useEffect, useState } from 'react'

const loadingAnimation = {
  duration: 250,
  delay: 250,
}

export const useLoader = (isLoading: boolean) => {
  const [loadingPersist, setLoadingPersist] = useState(true)

  useEffect(() => {
    if (!isLoading) {
      const { duration, delay } = loadingAnimation
      const extraTime = 100

      setTimeout(() => {
        setLoadingPersist(false)
      }, duration + delay + extraTime)
    }
  }, [isLoading, setLoadingPersist])

  return { loadingPersist, loadingAnimation }
}
