import { atom } from 'recoil'
import { recoilPersist } from 'recoil-persist'

const { persistAtom } = recoilPersist()

export type CourseDetails = Record<
  string,
  {
    expandedUnits: string[]
  }
>

export const defaultValue = undefined

export const courseDetails = atom<CourseDetails | undefined>({
  key: 'courseDetails',
  default: defaultValue,
  effects_UNSTABLE: [persistAtom],
})
