import { selector } from 'recoil'

import { child } from './atoms'

export const isTrialAvailableSelector = selector({
  key: 'isChildTrialAvailable',
  get({ get }) {
    const currentChild = get(child).current

    if (!currentChild) {
      return false
    }

    if (!currentChild.trialEndAt || currentChild.trialEndAt === null)
      return true

    return Boolean(new Date(currentChild.trialEndAt) >= new Date())
  },
})
