import ReactPaginate from 'react-paginate'
import styled from 'styled-components'

import { Icon } from '@/components'
import { flexCenter, flexColumn, transitions } from '@/styles/utils'

export const Container = styled.div`
  ${flexColumn()};
  align-items: center;
  gap: 1rem;
`

export const Counter = styled.span``

export const StyledPagination = styled(ReactPaginate)`
  ${flexCenter};
  gap: 1rem;

  li {
    border-radius: 50%;
    width: 1.5rem;
    height: 1.5rem;
    ${flexCenter()};
    ${transitions({})('opacity')};
    cursor: pointer;

    &.selected {
      cursor: default;
      color: ${({ theme }) => theme.color.white};
      background-color: ${({ theme }) => theme.color.grape};
    }

    &.disabled {
      &.previous,
      &.next {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }
  }
`

export const Previous = styled(Icon).attrs(() => ({
  icon: 'arrow',
}))`
  path {
    fill: ${({ theme }) => theme.color.grape};
  }
`

export const Next = styled(Previous)`
  transform: rotate(180deg);
`
