import styled, { css } from 'styled-components'

import Logo from '@/assets/images/logo-with-rocket.png'
import { Icon } from '@/components'

const Header = styled.div<{ collapsed: boolean }>`
  display: grid;
  grid-template-columns: ${({ collapsed }) =>
    collapsed ? '1fr' : 'auto auto'};
  gap: 0.05em;
  padding: 0.75em;
  align-items: center;
  justify-content: center;
  min-height: 4em;
  ${({ collapsed }) =>
    collapsed
      ? css`
          justify-items: end;
        `
      : ''}
`
const Chevron = styled.div<{ collapsed: boolean }>`
  transition: transform ease-in-out 300ms;
  padding: 0.75em;
  ${({ collapsed }) =>
    collapsed
      ? css`
          transform: rotate(90deg);
        `
      : css`
          transform: rotate(270deg);
        `}

  &:hover {
    cursor: pointer;
  }
`

export const LogoStyled = styled.img`
  width: 7rem;
  height: auto;
  cursor: pointer;
`

export function NavbarHeader({
  isCollapsed,
  toggleCollapse,
}: {
  isCollapsed: boolean
  toggleCollapse: () => void
}) {
  return (
    <Header collapsed={isCollapsed}>
      {isCollapsed ? null : <LogoStyled src={Logo} />}
      <Chevron collapsed={isCollapsed} onClick={toggleCollapse}>
        <Icon icon="chevron" />
      </Chevron>
    </Header>
  )
}
