import type { ApexOptions } from 'apexcharts'
import { formatISO } from 'date-fns'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useChildMetricStatsQuery } from '@/graphql/generated/types'
import { theme } from '@/styles/theme'

const usePieChartStudents = (startDate: string, endDate: string) => {
  const { data, loading } = useChildMetricStatsQuery({
    variables: {
      startDate,
      endDate,
    },
  })
  const { t } = useTranslation('admin', { keyPrefix: 'students' })
  const graphRender: ApexOptions = useMemo(() => {
    if (data) {
      const graph = data.childMetricStats

      return {
        title: {
          text: t('graphs.totalSubs', {
            startDate: formatISO(new Date(startDate), {
              representation: 'date',
            }),
            endDate: formatISO(new Date(endDate), {
              representation: 'date',
            }),
          }),
          align: 'left',
          margin: 10,
          offsetX: 0,
          offsetY: 0,
          floating: false,
          style: {
            fontWeight: theme.fontWeight.medium,
            fontFamily: 'Fredoka',
            color: theme.color.slateGray,
          },
        },
        colors: [
          '#28a74a',
          '#4581c3',
          '#8F1E21',
          '#DB5A5E',
          '#FFC300',
          '#99ccff',
          '#FF5733',
        ],
        series: [
          graph.activeSubscriptions,
          graph.cancelledSubscriptions,
          graph.trialSubscriptions,
          graph.pastDueSubscriptions,
        ],
        chart: {
          type: 'donut',
          height: '380',
          redrawOnWindowResize: true,
          toolbar: {
            show: true,
          },
        },
        labels: ['Active', 'Cancelled', 'Trial', 'Past Due'],
        legend: {
          show: true,
          position: 'bottom',
        },
        fill: {
          opacity: 1,
        },
        noData: {
          text: 'Cureently no data',
          align: 'center',
          verticalAlign: 'middle',
        },
        xaxis: {
          type: 'category',
          show: true,
        },
        plotOptions: {
          pie: {
            customScale: 1,
            donut: {
              size: '30%',
              labels: {
                show: true,
                total: {
                  show: true,
                  showAlways: true,
                },
              },
            },
          },
        },
        dataLabels: {
          formatter(val, opts): any {
            return opts.w.config.series[opts.seriesIndex]
          },
        },
      }
    }

    return {}
  }, [data])

  return {
    loading,
    graphRender,
  }
}

export default usePieChartStudents
