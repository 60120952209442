import styled from 'styled-components'

import { Main as StyledMain, MaxWidthContainer } from '@/styles/components'

export const Container = styled(MaxWidthContainer)`
  padding: 2rem 8rem;
`

export const Main = styled(StyledMain)`
  background-color: ${({ theme }) => theme.color.blueChalk};
  padding-top: 1rem;
`

export const Title = styled.h1`
  padding-bottom: 0.5rem;
`

export const Content = styled.div``
