import styled from 'styled-components'

import { MaxWidthContainer } from '@/pages/parent/ParentContainer'
import { Main as StyledMain } from '@/styles/components'
import { flexCenter, flexColumn, typography } from '@/styles/utils'

export const Main = styled(StyledMain)`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  background-color: ${({ theme }) => theme.color.blueChalk};
  justify-content: center;
  padding-top: 0px;
`

export const StyledMaxWidthContainer = styled(MaxWidthContainer)`
  padding: 0;
  flex-direction: row;
`

export const Right = styled.div`
  ${flexColumn};
  flex: 1;
  padding: 2rem 2rem 4rem 2rem;
  gap: 2rem;
`

export const Info = styled.div`
  flex: 1;
  ${flexCenter};
  ${typography.bodyBold()};
  text-align: center;
`
