import type { GraphQLErrors } from '@apollo/client/errors'
import type { ErrorResponse } from '@apollo/client/link/error'
import { AuthQuery } from '@graphql/apolloClient'
import type { GraphQLError } from 'graphql'
import type { UseFormSetError } from 'react-hook-form'
import type { FieldValues } from 'react-hook-form/dist/types'
import type { FieldPath } from 'react-hook-form/dist/types/path'

type Response = {
  statusCode?: number
  message?: string
}

type ExceptionWithFields = {
  response?: {
    fields?: Record<string, string>
  }
}

export const getErrorMessage = ({
  response,
  graphQLErrors,
}: ErrorResponse): string | undefined => {
  const lastGraphqlError = graphQLErrors
    ? graphQLErrors[graphQLErrors.length - 1]
    : undefined
  const lastResponseError = response?.errors
    ? response.errors[response.errors.length - 1]
    : undefined

  const graphqlErrorMessage = lastGraphqlError?.message
  const responseErrorMessage = lastResponseError?.message
  const extensionCode = lastGraphqlError?.extensions?.code as string | undefined

  return graphqlErrorMessage ?? responseErrorMessage ?? extensionCode
}

const findUnauthorizedError = (error: GraphQLError) =>
  (error?.extensions?.response as Response)?.statusCode === 401

const isUnauthenticatedError = ({
  graphQLErrors,
  response,
}: Omit<ErrorResponse, 'networkError' | 'operation' | 'forward'>) => {
  const isGraphqlContainError = Boolean(
    graphQLErrors?.find(findUnauthorizedError),
  )
  const isResponseContainError = Boolean(
    response?.errors?.find(findUnauthorizedError),
  )

  return isGraphqlContainError || isResponseContainError
}

export const getIsUnauthenticatedError = ({
  response,
  graphQLErrors,
  operation: { operationName },
}: ErrorResponse): boolean => {
  if (operationName === AuthQuery.SignIn) {
    return false
  }

  return isUnauthenticatedError({ graphQLErrors, response })
}

export const getIsRefreshTokenError = ({
  graphQLErrors,
  response,
  operation: { operationName },
}: ErrorResponse): boolean => {
  if (operationName !== AuthQuery.Refresh) {
    return false
  }

  return isUnauthenticatedError({ graphQLErrors, response })
}

export const getFieldErrors = (graphQLErrors: GraphQLErrors) =>
  graphQLErrors.reduce(
    (fields, error) => ({
      ...fields,
      ...(error.extensions?.exception as ExceptionWithFields)?.response?.fields,
    }),
    {},
  ) as Record<string, string>

export const setFieldErrors = <Schema extends FieldValues>(
  graphQLErrors: GraphQLErrors,
  setErrorCallback: UseFormSetError<Schema>,
) => {
  const fields = getFieldErrors(graphQLErrors) as Record<
    FieldPath<Schema>,
    string
  >

  for (const key in fields) {
    const fieldKey = key as FieldPath<Schema>

    setErrorCallback(
      fieldKey,
      {
        message: fields[fieldKey],
      },
      { shouldFocus: true },
    )
  }
}
