import { useState } from 'react'

export const useRecaptcha = (action: string) => {
  const [isRecaptchaLoading, setIsRecaptchaLoading] = useState(false)

  const endLoading = () => {
    setIsRecaptchaLoading(false)
  }

  const getToken = async () => {
    setIsRecaptchaLoading(true)
    return new Promise<string>((resolve, reject) => {
      grecaptcha.enterprise.ready(function () {
        grecaptcha.enterprise
          .execute(import.meta.env.VITE_RECAPTCHA_SITE_KEY, { action })
          .then((token) => {
            endLoading()
            resolve(token)
          })
          .catch((err) => {
            endLoading()
            reject(err)
          })
      })
    })
  }

  return { isLoading: isRecaptchaLoading, getToken }
}
