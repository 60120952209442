import { useAssignCoursesMutation } from '@graphql/generated/types'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import { Button, Icon } from '@/components'
import { purchaseSessionIdKey } from '@/core/const'
import { usePopup } from '@/core/hooks'
import { parentAnalytics } from '@/core/services/analytics'
import { convertToAssignCourseInput } from '@/pages/parent/components/Navbar/components/Cart/convertToAssignCourseInput'
import { route } from '@/pages/routes'
import { useMarketplaceCart } from '@/store/parent/marketplaceCart'
import { useParentProfile } from '@/store/user'

import {
  Actions,
  Add,
  Body,
  CartButton,
  Close,
  Container,
  Header,
  Info,
  StyledPopup,
  Title,
  Total,
} from './Cart.styled'
import { StudentCourse } from './StudentCourse'

type CartProps = {
  isChildSubscribed: boolean
  isChildOnFreemium: boolean
}

export function Cart({ isChildSubscribed, isChildOnFreemium }: CartProps) {
  const { t } = useTranslation('parent', {
    keyPrefix: 'cart',
  })
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const {
    courses,
    coursesArray,
    total,
    deleteCourse,
    clearMarketplaceCart,
    clearOnlyFreeCourses,
  } = useMarketplaceCart()
  const { profile } = useParentProfile()
  const { isOpen, onToggle } = usePopup()

  const [assignCourses, { loading }] = useAssignCoursesMutation({
    onCompleted({ assignCourses }) {
      onToggle()
      if (assignCourses.paymentUrl && assignCourses.sessionId) {
        addItemsToAnalytics(true)
        clearOnlyFreeCourses()
        localStorage.setItem(purchaseSessionIdKey, assignCourses.sessionId)
        window.location.href = assignCourses.paymentUrl
        return
      }

      addItemsToAnalytics()
      clearMarketplaceCart()
      toast.success(t('coursesAddedSuccess'))
      navigate(route.parent.dashboard.home())
    },
  })

  const totalCourses = Object.values(courses).reduce((total, course) => {
    total += course.length
    return total
  }, 0)

  const isEmpty = coursesArray.length === 0
  const isMarketplacePage = pathname === route.parent.marketplace()

  const addItemsToAnalytics = (freeOnly = false) => {
    coursesArray.forEach(([childId, childCourses]) => {
      childCourses?.forEach((course) => {
        if (freeOnly && course.price <= 0) {
          parentAnalytics.emitAcquireMarketplaceItem({
            childId,
            childName: course.child.fullName,
            childGrade: course.child.grade,
            coursePrice: course.price,
            courseId: course._id,
            courseName: course.name,
            subject: course.subject?.slug ?? '',
            grade: course.grade?.slug ?? '',
          })
        }
      })
    })
  }

  const onAdd = () => {
    assignCourses({
      variables: {
        childCourseIds: convertToAssignCourseInput(coursesArray),
      },
    })
  }

  return (
    <Container
      onClick={(event) => {
        event.stopPropagation()
      }}
    >
      <CartButton
        disabled={!isChildSubscribed && !isChildOnFreemium}
        count={totalCourses}
        onClick={onToggle}
      >
        <Icon icon="cart" />
      </CartButton>
      {isOpen && (
        <StyledPopup>
          <Header>
            <Title>{t('shoppingCart')}</Title>
            <Close onClick={onToggle}>
              <Icon icon="close" />
            </Close>
          </Header>
          <Body>
            {isEmpty && (
              <Info>
                <p>{t('noCourses')}</p>
                {!isMarketplacePage && (
                  <Button
                    thin
                    rounded
                    onClick={() => {
                      navigate(route.parent.marketplace())
                      onToggle()
                    }}
                  >
                    {t('goToMarketplace')}
                  </Button>
                )}
              </Info>
            )}
            {!isEmpty && profile && (
              <>
                {coursesArray.map(([childId, childCourses]) => (
                  <StudentCourse
                    key={childId}
                    currency={profile.currency}
                    childFullName={childCourses?.[0]?.child?.fullName ?? ''}
                    courses={childCourses}
                    onDelete={(id) => {
                      deleteCourse(childId, id)
                    }}
                  />
                ))}
                <Total>
                  {t('total')}: {total} {profile?.currency}
                </Total>
              </>
            )}
          </Body>
          {!isEmpty && (
            <Actions>
              <Button
                thin
                rounded
                appearance="outline"
                onClick={() => {
                  clearMarketplaceCart()
                  onToggle()
                }}
              >
                {t('clearAll')}
              </Button>
              <Add thin rounded loading={loading} onClick={onAdd}>
                {t('add')}
              </Add>
            </Actions>
          )}
        </StyledPopup>
      )}
    </Container>
  )
}
