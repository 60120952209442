import type { ReactNode } from 'react'

import type { ModalProps } from '@/components'
import { Button, Modal } from '@/components'
import type { useModalControls } from '@/components/Modal/useModalControls'

import {
  Actions,
  Body,
  ConfirmButton,
  Container,
  Header,
} from './ConfirmationModal.styled'

type ConfirmationModalProps = {
  body: ReactNode
  onConfirm: VoidFunction
  headerLabel: string
  cancelLabel?: string
  confirmLabel: string
  isConfirmationBtnDisabled?: boolean
  isConfirmationBtnLoading?: boolean
  className?: string
} & Pick<ReturnType<typeof useModalControls>, 'isOpen' | 'onClose'> &
  Pick<ModalProps, 'shouldCloseOnEsc' | 'shouldCloseOnOverlayClick'>

export function ConfirmationModal({
  body,
  headerLabel,
  cancelLabel,
  confirmLabel,
  isOpen,
  isConfirmationBtnDisabled = false,
  isConfirmationBtnLoading = false,
  onConfirm,
  onClose,
  className,
  ...rest
}: ConfirmationModalProps) {
  return (
    <Modal
      shouldCloseOnOverlayClick
      isOpen={isOpen}
      closePosition={{
        top: '1.5rem',
        right: '1rem',
      }}
      onRequestClose={onClose}
      {...rest}
    >
      <Container
        className={className}
        onClick={(event) => {
          event.stopPropagation()
        }}
      >
        <Header>{headerLabel}</Header>
        <Body>
          {body}
          <Actions>
            {cancelLabel && (
              <Button rounded appearance="outline" onClick={onClose}>
                {cancelLabel}
              </Button>
            )}
            <ConfirmButton
              rounded
              loading={isConfirmationBtnLoading}
              disabled={isConfirmationBtnDisabled}
              onClick={onConfirm}
            >
              {confirmLabel}
            </ConfirmButton>
          </Actions>
        </Body>
      </Container>
    </Modal>
  )
}
