import styled, { css } from 'styled-components'

import type { ButtonAppearance } from '@/components'
import {
  flexCenter,
  oneLineText,
  transitions,
  typography,
} from '@/styles/utils'

const defaultButton = css`
  color: rgb(253, 249, 243);
  background-color: ${({ theme }) => theme.color.grape};
`

const outlineButton = css`
  background-color: ${({ theme }) => theme.color.white};
  border: 1px solid ${({ theme }) => theme.color.grape};
`

export const Button = styled.button<{
  appearance: ButtonAppearance
  rounded: boolean
  thin: boolean
}>`
  ${typography.bodyBold};
  ${oneLineText()};
  height: ${({ thin }) => (thin ? 2 : 3)}rem;
  font-size: ${({
    theme: {
      fontSize: { small, normal },
    },
    thin,
  }) => (thin ? small : normal)};
  max-width: 100%;
  padding: 0 ${({ thin }) => (thin ? 1.5 : 1)}rem;
  ${flexCenter()};
  border-radius: ${({ rounded }) => (rounded ? 27 : 8)}px;

  ${transitions({})('background-color', 'opacity')};

  &:disabled {
    opacity: 0.5;
  }

  ${({ appearance }) =>
    appearance === 'default' ? defaultButton : outlineButton}
`
