import { ReactComponent as Arrow } from '@/assets/icons/arrow.svg'
import { ReactComponent as ArrowPurple } from '@/assets/icons/arrow-purple.svg'
import { ReactComponent as AutoPilot } from '@/assets/icons/autoPilot.svg'
import { ReactComponent as Book } from '@/assets/icons/book.svg'
import { ReactComponent as Calendar } from '@/assets/icons/calendar.svg'
import { ReactComponent as Camera } from '@/assets/icons/camera.svg'
import { ReactComponent as Cart } from '@/assets/icons/cart.svg'
import { ReactComponent as ChatSquareDots } from '@/assets/icons/chat-square-dots.svg'
import { ReactComponent as ChatSquareTextFill } from '@/assets/icons/chat-square-text-fill.svg'
import { ReactComponent as CheckCircle } from '@/assets/icons/check-circle.svg'
import { ReactComponent as CheckMarkPurple } from '@/assets/icons/check-purple.svg'
import { ReactComponent as CheckMarkWhite } from '@/assets/icons/check-white.svg'
import { ReactComponent as CheckMarkSquareFilled } from '@/assets/icons/checkmark-square-fill.svg'
import { ReactComponent as Chevron } from '@/assets/icons/chevron.svg'
import { ReactComponent as Close } from '@/assets/icons/close.svg'
import { ReactComponent as CloseSquare } from '@/assets/icons/close-square.svg'
import { ReactComponent as CrossRed } from '@/assets/icons/cross-red.svg'
import { ReactComponent as CurvedArrow } from '@/assets/icons/curved-arrow.svg'
import { ReactComponent as Dashboard } from '@/assets/icons/dashboard.svg'
import { ReactComponent as Delete } from '@/assets/icons/delete.svg'
import { ReactComponent as EBook } from '@/assets/icons/ebook.svg'
import { ReactComponent as Edit } from '@/assets/icons/edit.svg'
import { ReactComponent as Ellipsis } from '@/assets/icons/ellipsis.svg'
import { ReactComponent as ExclamationTriangleFill } from '@/assets/icons/exclamation-triangle-fill.svg'
import { ReactComponent as Expand } from '@/assets/icons/expand.svg'
import { ReactComponent as facebookSvg } from '@/assets/icons/facebook-svg-type.svg'
import { ReactComponent as arrowUp } from '@/assets/icons/file-arrow-up-fill.svg'
import { ReactComponent as Folder } from '@/assets/icons/folder.svg'
import { ReactComponent as Gear } from '@/assets/icons/gear.svg'
import { ReactComponent as Hidden } from '@/assets/icons/hidden.svg'
import { ReactComponent as Info } from '@/assets/icons/info.svg'
import { ReactComponent as Key } from '@/assets/icons/key.svg'
import { ReactComponent as List } from '@/assets/icons/list-ul.svg'
import { ReactComponent as Marketplace } from '@/assets/icons/marketplace.svg'
import { ReactComponent as Note } from '@/assets/icons/note.svg'
import { ReactComponent as Notification } from '@/assets/icons/notification.svg'
import { ReactComponent as Play } from '@/assets/icons/play.svg'
import { ReactComponent as PlayPurple } from '@/assets/icons/play-purple.svg'
import { ReactComponent as PlayNoCircle } from '@/assets/icons/playNoCircle.svg'
import { ReactComponent as Plus } from '@/assets/icons/plus.svg'
import { ReactComponent as PlusPurple } from '@/assets/icons/plus-purple.svg'
import { ReactComponent as Quote } from '@/assets/icons/quote.svg'
import { ReactComponent as Resources } from '@/assets/icons/resources.svg'
import { ReactComponent as Rocket } from '@/assets/icons/rocket.svg'
import { ReactComponent as RocketLaunch } from '@/assets/icons/rocket-launch.svg'
import { ReactComponent as Save } from '@/assets/icons/save.svg'
import { ReactComponent as Search } from '@/assets/icons/search.svg'
import { ReactComponent as Shop } from '@/assets/icons/shop.svg'
import { ReactComponent as Star } from '@/assets/icons/star.svg'
import { ReactComponent as Student } from '@/assets/icons/student.svg'
import { ReactComponent as Success } from '@/assets/icons/success.svg'
import { ReactComponent as Switch } from '@/assets/icons/switch.svg'
import { ReactComponent as User } from '@/assets/icons/user.svg'
import { ReactComponent as Visible } from '@/assets/icons/visible.svg'

export const iconsMap = {
  switch: Switch,
  play: Play,
  success: Success,
  arrow: Arrow,
  expand: Expand,
  close: Close,
  book: Book,
  ebook: EBook,
  save: Save,
  note: Note,
  closeSquare: CloseSquare,
  search: Search,
  edit: Edit,
  delete: Delete,
  student: Student,
  marketplace: Marketplace,
  notification: Notification,
  cart: Cart,
  user: User,
  ellipsis: Ellipsis,
  calendar: Calendar,
  camera: Camera,
  star: Star,
  rocket: Rocket,
  key: Key,
  resources: Resources,
  dashboard: Dashboard,
  list: List,
  chatSquareDots: ChatSquareDots,
  chatSquareTextFill: ChatSquareTextFill,
  exclamationTriangleFill: ExclamationTriangleFill,
  plus: Plus,
  gear: Gear,
  checkMarkSquareFilled: CheckMarkSquareFilled,
  autoPilot: AutoPilot,
  playNoCircle: PlayNoCircle,
  checkMarkPurple: CheckMarkPurple,
  curvedArrow: CurvedArrow,
  rocketLaunch: RocketLaunch,
  checkMarkWhite: CheckMarkWhite,
  crossRed: CrossRed,
  checkCircle: CheckCircle,
  folder: Folder,
  chevron: Chevron,
  playPurple: PlayPurple,
  arrowPurple: ArrowPurple,
  plusPurple: PlusPurple,
  visible: Visible,
  hidden: Hidden,
  info: Info,
  shop: Shop,
  shareArrowUp: arrowUp,
  facebook: facebookSvg,
  quote: Quote,
}

type IconProps = {
  icon: keyof typeof iconsMap
  className?: string
}

export type IconName = IconProps['icon']

export function Icon({ icon, className }: IconProps) {
  const Icon = iconsMap[icon]

  return <Icon className={className} />
}
