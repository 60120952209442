import styled, { css } from 'styled-components'

import thunder from '@/assets/images/landing/thunder.svg'
import { media } from '@/styles/media'
import { absoluteCenter, flexColumn } from '@/styles/utils'

import { imageTextFlex } from '../../Landing.styled'

export const Main = styled.div`
  padding: 12.5rem 0;
  margin-top: 9.75rem;
  border-radius: 4rem;
  display: inline-block;
  position: relative;
  width: 100%;

  background: url(${thunder}) no-repeat,
    ${({ theme }) => theme.color.babyBlossom};

  background-size: 10rem;
  background-position: right 3rem;

  ${media.tablet(
    () => css`
      background-size: 5rem;
      margin-top: 20rem;
    `,
  )};

  ${media.mobile(
    () => css`
      background-size: 4rem;
      background-position: right 15rem;
      padding: 8rem 0;
      margin-top: 15rem;
    `,
  )};
`

export const TagLine = styled.div`
  color: ${({ theme }) => theme.color.fieldPoppy};
  font-size: ${({ theme }) => theme.landingSpecialFontSize.huge};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
`

export const ImageTextFlexContainer = styled.div`
  ${imageTextFlex()}

  ${media.tablet(
    () => css`
      ${flexColumn()}
      flex-direction: column-reverse;
    `,
  )};
`

export const ImageContainer = styled.div`
  max-width: 45rem;
  width: 100%;
  margin: auto;
  position: relative;

  ${media.mobile(
    () => css`
      padding: 0 1rem;
    `,
  )};
`

export const PlayIcon = styled.img`
  position: absolute;
  ${absoluteCenter()}

  ${media.mobile(
    () => css`
      height: 4rem;
      width: 4rem;
    `,
  )};
`

export const ListContainer = styled.div`
  max-width: 28rem;
  margin: auto;
  position: relative;
`

const imageSizeBig = 28
const imageSizeMid = 20
const imageSizeSmall = 14

const imageStyling = () => css`
  position: absolute;
  height: ${imageSizeBig}rem;
  width: auto;

  ${media.tablet(
    () => css`
      height: ${imageSizeMid}rem;
    `,
  )}

  ${media.mobile(
    () => css`
      height: ${imageSizeSmall}rem;
    `,
  )}
`

export const ImageBoy = styled.img`
  ${imageStyling()}

  top: -${imageSizeBig}rem;
  right: 0rem;

  ${media.tablet(
    () => css`
      top: -${imageSizeMid}rem;
    `,
  )}

  ${media.mobile(
    () => css`
      top: -${imageSizeSmall}rem;
    `,
  )}
`

export const ImageGirl = styled.img`
  ${imageStyling()}
  top: -20rem;

  ${media.tablet(
    () => css`
      top: -${imageSizeSmall}rem;
    `,
  )}

  ${media.mobile(
    () => css`
      top: -10rem;
    `,
  )}
`

export const ImageSpiral = styled.img`
  position: absolute;
  bottom: -5rem;
  left: -5rem;
  height: 12rem;
  width: auto;
  pointer-events: none;

  ${media.tablet(
    () => css`
      height: 8rem;
      left: 0rem;
      bottom: -3rem;
    `,
  )}

  ${media.mobile(
    () => css`
      height: 6rem;
      left: 0rem;
      bottom: -3rem;
    `,
  )}
`
export const VideoFrame = styled.iframe`
  width: 100%;
  max-width: 45rem;
  aspect-ratio: 79/49;
  box-shadow: 8px 8px 0px ${({ theme }) => theme.color.pecanVener};
  border-radius: 2rem;
`
