import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { Button, Tooltiped } from '@/components'
import { useModalControls } from '@/components/Modal/useModalControls'
import { mediaPath } from '@/core/services/mediaPath'
import { route } from '@/pages/routes'
import { useParentProfile } from '@/store/user'

import { CourseDetailsModal } from './CourseDetailsModal'
import {
  Actions,
  Course as CourseDiv,
  Image,
  Info,
  Name,
  Price,
} from './Courses.styled'

export type CourseDetails = {
  id: string
  name: string
  isMetaverse: boolean
}

type CourseProps = {
  _id: string
  isDisabled: boolean
  image: string
  name: string
  price: number
  isMetaverse: boolean
}

export function Course({
  _id,
  isDisabled,
  image,
  name,
  price,
  isMetaverse,
}: CourseProps) {
  const { t } = useTranslation('parent', {
    keyPrefix: 'marketplace',
  })
  const navigate = useNavigate()

  const { t: tCommon } = useTranslation('common')

  const { profile } = useParentProfile()

  const { onOpen, ...rest } = useModalControls()
  const [courseDetails, setCourseDetails] = useState<CourseDetails>()

  return (
    <>
      <CourseDetailsModal course={courseDetails} {...rest} />
      <Tooltiped
        key={_id}
        content={isDisabled ? t('bundleAndSingleProhibited') : undefined}
      >
        <CourseDiv isDisabled={isDisabled}>
          <Image
            src={mediaPath.build({
              url: image,
              folderKey: 'course',
            })}
          />
          <div>
            <Name>{isMetaverse ? t(name) : name}</Name>
            <Price>
              {price > 0
                ? `${price} ${profile?.currency ?? ''}`
                : tCommon('includedInSubscription')}
            </Price>
          </div>
          <Actions>
            <Button
              thin
              rounded
              onClick={() => {
                navigate(route.landing())
                document.documentElement.scrollTop = 0
                document.body.scrollTop = 0
              }}
            >
              {t('add')}
            </Button>
            <Info
              onClick={() => {
                setCourseDetails({
                  id: _id,
                  name,
                  isMetaverse,
                })
                onOpen()
              }}
            >
              i
            </Info>
          </Actions>
        </CourseDiv>
      </Tooltiped>
    </>
  )
}
