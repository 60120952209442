import { Sidebar } from 'react-pro-sidebar'
import styled from 'styled-components'

export const StyledSideBar = styled(Sidebar)`
  .ps-sidebar-root {
    border-right: none;
    color: ${({ theme }) => theme.color.white};
  }
`

export const NavigationContainer = styled.div`
  flex: 1;
  margin-bottom: 32px;
`

export const NavigationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`
