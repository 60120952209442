import { useState } from 'react'
import Chart from 'react-apexcharts'
import { useTranslation } from 'react-i18next'
import type { SingleValue } from 'react-select'

import type { Option } from '@/components'
import { Loader, Select } from '@/components'

import useCountries from '../../hooks/useCountries'
import type { DateQuery } from '../Overview'
import useReportingChart from './useReportingChart'
import { Container, FlexRow } from './Userstatistics.styled'

export function UserStatistics({ dateFilter }: { dateFilter: DateQuery }) {
  const [country, setCountry] = useState<string>('Canada')
  const { loading, renderGraph } = useReportingChart(dateFilter, country)
  const countries = useCountries()

  const { t } = useTranslation('admin', { keyPrefix: 'location' })

  return (
    <Container>
      <FlexRow>
        <Select
          name="country"
          label={t('filterCountry')}
          options={countries.map((country) => ({
            label: country,
            value: country,
          }))}
          defaultValue={{
            label: 'Canada',
            value: 'Canada',
          }}
          appearance="grey"
          onChange={(e: SingleValue<Option>) => {
            setCountry(e?.value ?? '')
          }}
        />
      </FlexRow>
      {loading ? (
        <Loader size="4em" color="codeCurrent" />
      ) : (
        <Chart
          series={renderGraph.series}
          type="area"
          options={renderGraph}
          height={renderGraph.chart?.height}
        />
      )}
    </Container>
  )
}
