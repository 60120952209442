import styled from 'styled-components'

import { Button } from '@/components'
import { changeOpacity, flexColumn, flexRow, typography } from '@/styles/utils'

export const FlexColumn = styled.div`
  ${flexColumn()};
  align-items: center;
  padding: 1em;
  gap: 0.75em;
  width: 100%;
  border-radius: 0.75em;
`

export const FlexRow = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(186px, 1fr));
  width: 100%;
  gap: 1em;
`
export const MetricCard = styled.div`
  ${flexColumn()};
  justify-content: center;
  background-color: ${({ theme }) => theme.color.white};
  border-radius: 0.5em;
  padding: 0.25em;
  row-gap: 0.1em;
  align-items: center;
  text-align: center;
  box-shadow: 0 4px 6px ${({ theme }) => changeOpacity(theme.color.black, 0.25)};
`

export const MetricValue = styled.p`
  ${typography.bodyBold()};
`
export const MetricTitle = styled.p`
  ${typography.bodySmall()};
  color: ${({ theme }) => theme.color.doveGray};
`

export const Row = styled.div`
  ${flexRow};
  gap: 1rem;
`

export const DownloadButton = styled(Button)`
  padding: 0rem;
  height: auto;
  background-color: ${({ theme }) => theme.color.white};
  border-radius: 0;
`

export const DownloadIcon = styled.img``
