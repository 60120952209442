import {
  CourseCountry,
  useGradesQuery,
  useSubjectsQuery,
} from '@graphql/generated/types'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import type { Option } from '@/components'
import type {
  Price,
  Unit,
} from '@/pages/parent/Marketplace/components/Filters/Filters'

export const useGradesSubjectsChildrenOptions = () => {
  const { data: subjectsData, loading: subjectsLoading } = useSubjectsQuery()
  const { data: gradesData, loading: gradesLoading } = useGradesQuery()

  const { t } = useTranslation('common')

  const gradeOptions: Option[] = useMemo(
    () =>
      gradesData?.grades.map(({ _id, title }) => ({
        label: title,
        value: _id,
      })) ?? [],
    [gradesData],
  )

  const subjectOptions: Option[] = useMemo(
    () =>
      subjectsData?.subjects.map(({ _id, title }) => ({
        label: title,
        value: _id,
      })) ?? [],
    [subjectsData],
  )

  const countryOptions = useMemo<Array<Option<CourseCountry>>>(
    () =>
      Object.values(CourseCountry).map((country) => ({
        value: country,
        label: t(`countries.${country.toLowerCase()}`),
      })),
    [],
  )

  const priceOptions = useMemo<Array<Option<Price>>>(
    () => [
      {
        value: 'free',
        label: t('free'),
      },
      {
        value: 'paid',
        label: t('paid'),
      },
    ],
    [],
  )

  const unitOptions = useMemo<Array<Option<Unit>>>(
    () => [
      {
        value: 'allUnits',
        label: t('allUnits'),
      },
      {
        value: 'individualUnits',
        label: t('individualUnits'),
      },
    ],
    [],
  )

  return {
    gradeOptions,
    subjectOptions,
    countryOptions,
    priceOptions,
    unitOptions,
    isLoading: subjectsLoading || gradesLoading,
  }
}
