import React from 'react'
import { useTranslation } from 'react-i18next'

import { type LandingColors, Button } from '../../Landing.styled'
import { ButtonContainer } from '../PerfectFor/PerfectFor.styled'
import { Container, HrLine, Words } from './LineListing.styled'

type LineListingProps = {
  lines: string[]
  color: LandingColors
  showSignupBtn?: boolean
}

export function LineListing({
  lines,
  color = 'Orange',
  showSignupBtn = false,
}: LineListingProps) {
  const { t } = useTranslation('landing')

  const signUp = () => {
    document.documentElement.scrollTop = 0
    document.body.scrollTop = 0

    // window.location.reload()
    // navigate(route.signUp())
    // window.location.reload()
  }

  return (
    <Container>
      {lines.map(function (line) {
        return (
          <React.Fragment key={line}>
            <Words>{t(`lines.${line}`)}</Words>
            <HrLine bgColor={color} />
          </React.Fragment>
        )
      })}

      {showSignupBtn && (
        <ButtonContainer>
          <Button
            isFullWidthMobile
            marginTop={2}
            bgColor={color}
            onClick={signUp}
          >
            {t('buttons.btnGetStarted')}
          </Button>
        </ButtonContainer>
      )}
    </Container>
  )
}
