import { createGlobalStyle } from 'styled-components'

import { scrollbar } from '@/styles/utils'

import { fonts } from './fonts'

export const CssGlobal = createGlobalStyle`

  ${fonts}

  :root {
    --toastify-font-family: 'Fredoka', sans-serif;
  }

  ::-webkit-scrollbar {
    width: 5px;
  }

  ${scrollbar('portGore', 'grape')};
  
  html {
    font-family: 'Fredoka', sans-serif;
    overflow-y: overlay;
  }
  
  * {
    scroll-behavior: smooth;
    letter-spacing: -0.03em;
  }
  
  body {
    background-color: ${({ theme }) => theme.color.portGore};
  }
  
  ul, li {
    cursor: default;
    padding: 0;
    margin: 0;
    list-style: none;
  }
  
  h1, h2, h3, h4, h5, h6, p, span, small, a {
    line-height: 125%;
  }
  
  button {
    border: none;
    cursor: pointer;
    padding: 0;
    background: none;
    user-select: none;
    
    &:disabled {
      cursor: not-allowed;
    }
  }
  
  input:focus,
  input:focus-within,
  input:focus-visible,
  textarea:focus,
  textarea:focus-visible,
  textarea:focus-within {
    outline: none;
  }
  
  img {
    user-select: none;
  }

  .grecaptcha-badge {
    visibility: hidden;
  }

  .bf-fixed {
    right: auto;
    left: 1rem;
  }

  .slick-prev:before,
  .slick-next:before {
    color: ${({ theme }) => theme.color.grape};
  }
`
