import styled, { css } from 'styled-components'

import { Button } from '@/components'
import { media } from '@/styles/media'
import { flexCenter, flexColumn, oneLineText, typography } from '@/styles/utils'

export const Container = styled.div`
  height: 17.125rem;
  width: 29.75rem;
  border-radius: 18px;
  background-color: ${({ theme }) => theme.color.mangolia};
  overflow: hidden;
  ${flexColumn()};

  ${media.mobile(
    () => css`
      width: 100%;
    `,
  )}
`

export const Header = styled.header`
  height: 4.438rem;
  ${flexCenter()};
  ${typography.h3};
  background-color: ${({ theme }) => theme.color.white};
  ${oneLineText()};
`

export const Body = styled.div`
  padding: 1rem;
  ${flexCenter()};
  flex-direction: column;
  gap: 1.5rem;
  flex: 1;
  text-align: center;
`

export const Actions = styled.div`
  display: flex;
  gap: 0.5rem;
`

export const ConfirmButton = styled(Button)`
  min-width: 6.643rem;
`
