import { useTranslation } from 'react-i18next'

import {
  Button,
  LinesContainer,
  MainTitle,
  MaxWidthContainer,
} from '../../Landing.styled'
import { LineListing } from '../LineListing'
import { ButtonContainer } from '../PerfectFor/PerfectFor.styled'
import { Main, TagLine } from './educationJourney.styled'

const lines: string[] = [
  'charting',
  'seekingAFlexible',
  'activelyEngage',
  'afterSchoolioSupport',
]

export function EducationJourney() {
  const { t } = useTranslation('landing')

  const signUp = () => {
    document.documentElement.scrollTop = 0
    document.body.scrollTop = 0
    // navigate(route.signUp())
    // window.location.reload()
  }

  return (
    <MaxWidthContainer>
      <Main>
        <MainTitle marginTop={0}>{t('titles.enrichYourChild')}</MainTitle>

        <TagLine>{t('lines.schoolioFor')}</TagLine>
        <LinesContainer>
          <LineListing lines={lines} color="Green" />
        </LinesContainer>

        <ButtonContainer>
          <Button
            isFullWidthMobile
            marginTop={2}
            bgColor="Green"
            onClick={signUp}
          >
            {t('buttons.btnGetStarted')}
          </Button>
        </ButtonContainer>
      </Main>
    </MaxWidthContainer>
  )
}
