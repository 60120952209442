import type { Route } from '@/pages/routes'

export enum AdminRoute {
  Default = '/overview',
  Location = '/location',
  Educator = '/educators',
  Student = '/students',
}

export const routeKey = '/admin'

const buildPath = (route: string) => `${routeKey}${route}`

export const adminRoute: Route<typeof AdminRoute> = {
  default: () => buildPath(AdminRoute.Default),
  location: () => buildPath(AdminRoute.Location),
  educator: () => buildPath(AdminRoute.Educator),
  student: () => buildPath(AdminRoute.Student),
}
