import type { HTMLProps, PropsWithChildren } from 'react'
import { forwardRef, useId } from 'react'

import {
  Checkbox as StyledCheckbox,
  Container,
  HiddenInput,
  Label,
  Text,
} from './Checkbox.styled'

type CheckboxProps = Omit<
  HTMLProps<HTMLInputElement> & {
    error?: string
  },
  'type' | 'hidden' | 'as'
>

export const Checkbox = forwardRef<
  HTMLInputElement,
  PropsWithChildren<CheckboxProps>
>(({ id, error = '', children, checked, ...rest }, ref) => {
  const generatedId = useId()

  const inputId = generatedId ?? id

  return (
    <Container>
      <Label htmlFor={inputId}>
        <HiddenInput
          {...rest}
          ref={ref}
          hidden
          checked={checked}
          id={inputId}
          type="checkbox"
        />
        <StyledCheckbox
          isError={Boolean(error)}
          tabIndex={0}
          role="checkbox"
          aria-checked={checked}
          aria-labelledby={inputId}
        />
        <Text>{children}</Text>
      </Label>
    </Container>
  )
})
