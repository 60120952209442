import { type MarketplaceCoursesCourse } from '@graphql/generated/types'
import { useTranslation } from 'react-i18next'

import { Loader } from '@/components'
import { metaverseName } from '@/core/const'

import { Course } from './Course'
import {
  Bold,
  Container,
  Count,
  LoaderContainer,
  StyledCourses,
} from './Courses.styled'

export type CourseDetails = {
  id: string
  name: string
  isMetaverse: boolean
}

type CoursesProps = {
  courses: MarketplaceCoursesCourse[]
  totalCount: number
  isLoading: boolean
}

export function Courses({ courses, totalCount, isLoading }: CoursesProps) {
  const { t } = useTranslation('parent', {
    keyPrefix: 'marketplace',
  })

  return (
    <Container>
      {isLoading && (
        <LoaderContainer>
          <Loader color="grape" />
        </LoaderContainer>
      )}
      <Count>
        <Bold>{totalCount}</Bold> {t('coursesFound')}
      </Count>
      <StyledCourses>
        {courses.map(
          ({ _id, name, image, grade: _grade, subject: _subject, price }) => {
            const isMetaverse = name === metaverseName

            const isDisabled = false

            return (
              <Course
                key={_id}
                _id={_id}
                isDisabled={isDisabled}
                image={image}
                name={name}
                price={price}
                isMetaverse={isMetaverse}
              />
            )
          },
        )}
      </StyledCourses>
    </Container>
  )
}
