import { useTranslation } from 'react-i18next'

import heroImg from '@/assets/images/landing/hero-img.webp'

import { Button, MaxWidthContainer } from '../../Landing.styled'
import {
  HeroImage,
  HeroImgContainer,
  InnerContainer,
  Main,
  SchoolioDigital,
  SchoolYourWayTxt,
  SmallTagline,
  Spacer,
  Tagline,
  TradeMark,
} from './SchoolioYourWay.styled'

export function SchoolYourWay() {
  const { t } = useTranslation('landing')

  const signUp = () => {
    document.documentElement.scrollTop = 0
    document.body.scrollTop = 0
    // navigate(route.signUp())
  }

  return (
    <MaxWidthContainer>
      <Main>
        <InnerContainer>
          <SchoolYourWayTxt>{t('titles.schoolYourWay')}</SchoolYourWayTxt>
          <SchoolioDigital>
            {t('titles.familyMembershipPlan')}{' '}
            <sup>
              <TradeMark>&trade;</TradeMark>
            </sup>
          </SchoolioDigital>
          <Tagline>
            Unleash a truly personalized, all-in-one, 1<sup>st</sup> to 8
            <sup>th</sup> grade learning platform.
          </Tagline>
          <Button bgColor="Orange" marginTop={2} onClick={signUp}>
            {t('buttons.btnGetStarted')}
          </Button>
          <SmallTagline>{t('lines.freeTrialCancelAnytime')}</SmallTagline>
          <Spacer />
          <HeroImgContainer>
            <HeroImage src={heroImg} alt="digital images" />
          </HeroImgContainer>
        </InnerContainer>
      </Main>
    </MaxWidthContainer>
  )
}
