import 'react-datepicker/dist/react-datepicker.css'

import styled from 'styled-components'

import { DateRangePicker } from '../components'
import useDateQuery from '../hooks/useDateQuery'
import { UserStatistics } from '../overview/UserCountryStatistics'
import ParentPayingChart from './components/ParentPayingChart'
import ParentPieChart from './components/ParentPieChart'
import { FlexRow } from './ParentReports.styled'

const Main = styled.div`
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  row-gap: 1.2em;
  width: 100%;
  height: 100vh;
`

export function ParentReports() {
  const { date, setDate } = useDateQuery()
  return (
    <Main>
      <FlexRow>
        <DateRangePicker onChange={setDate} />
        <ParentPieChart date={date} />
      </FlexRow>
      <UserStatistics dateFilter={date} />
      <ParentPayingChart date={date} />
    </Main>
  )
}
