import { parentAnalytics } from './analytics'

export class AdUserManager {
  private get adUrlKey() {
    return 'adUrl'
  }

  handleUrl() {
    const landingUrl = window.location.href
    const urlExistInLocal = this.checkIfUrlExist()

    const containsUtmSource = landingUrl.includes('utm_source')
    if (!urlExistInLocal && containsUtmSource) {
      localStorage.setItem(this.adUrlKey, landingUrl)
      const realAdUrl = localStorage.getItem(this.adUrlKey)
      if (!realAdUrl) {
        return
      }

      parentAnalytics.emitAdDrivenSiteVisit({
        adUrl: realAdUrl,
        utmSource: this.extractUtmSource(realAdUrl),
        utmCampaign: this.extractUtmCampaign(realAdUrl),
      })
    }
  }

  checkIfUrlExist() {
    const adUrl = localStorage.getItem(this.adUrlKey)
    return Boolean(adUrl)
  }

  getAdUrl() {
    return this.checkIfUrlExist() ? localStorage.getItem(this.adUrlKey) : ''
  }

  clearAdUrl() {
    localStorage.removeItem(this.adUrlKey)
  }

  extractUtmCampaign(url: string) {
    const { searchParams } = new URL(url)
    const utmCampaign = searchParams.get('utm_campaign')
    return utmCampaign ?? ''
  }

  extractUtmSource(url: string) {
    const { searchParams } = new URL(url)
    const utmSource = searchParams.get('utm_source')
    return utmSource ?? ''
  }
}

export const adUserManager = new AdUserManager()
