import { useMemo } from 'react'

import { useUserCountriesReportQuery } from '@/graphql/generated/types'

const useCountries = () => {
  const { data, loading } = useUserCountriesReportQuery()

  const countries = useMemo(() => {
    if (data) return data.userCountriesReport.map((x) => x.country)

    return ['Loading Countries']
  }, [data])

  if (loading) return ['Loading Countries']

  return countries
}

export default useCountries
