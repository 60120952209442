import styled from 'styled-components'

import { flexColumn, flexRow } from '@/styles/utils'

export type BgColors = '0' | '1' | '2'

export const SwitcherDiv = styled.div`
  ${flexRow}
  text-align: center;
  justify-content: center;
  margin-bottom: 1rem;
`

export const PlanTitle = styled.h3<{
  bgColor: BgColors
}>`
  padding: 16px;
  background-color: ${({ bgColor }) =>
    (bgColor === '0' && '#ecda94') ||
    (bgColor === '1' && '#bdd9a5') ||
    (bgColor === '2' && '#a6cdff') ||
    '#a6cdff'};
  text-align: center;
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  min-height: 7rem;
`

export const PlanPrice = styled.h4`
  padding: 8px;
  font-weight: ${({ theme }) => theme.fontWeight.big};
  font-size: ${({ theme }) => theme.fontSize.bigger};
`

export const BestValueTag = styled.p`
  font-weight: ${({ theme }) => theme.fontWeight.light};
  font-size: ${({ theme }) => theme.fontSize.tiny};
  background-color: ${({ theme }) => theme.color.slateGray};
  color: ${({ theme }) => theme.color.white};
  padding: 4px 8px;
  width: fit-content;
  margin: auto;
  border-radius: 10px;
`

export const PlanPrePriceText = styled.p`
  padding: 16px 16px 0px 16px;
`

export const PlanPostPriceText = styled.p`
  padding: 16px 16px 16px 16px;
`

export const ButtonSelectionSec = styled.div`
  max-width: 25rem;
  min-width: 10rem;
  ${flexColumn()}
  gap: 0.5rem;
  justify-content: start !important;
  flex: 1 1 0;
  text-align: center;
  padding: 0px 16px 16px 16px;
`

export const FeaturesTitle = styled.h4``

export const ListSec = styled.div`
  padding: 0px 16px 16px 16px;
  ${flexColumn}
  gap: 0.8rem;
  color: gray;
`

export const ListItem = styled.li`
  list-style: disc;
`

export const SeeLessMoreButton = styled.button`
  text-align: left;
  color: gray;
  text-decoration: underline;
`

export const CrossedOutPrice = styled.small`
  font-weight: ${({ theme }) => theme.fontWeight.regular};
`
