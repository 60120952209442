import { useTermsAndConditionsQuery } from '@/graphql/generated/types'
import { MaxWidthContainer } from '@/styles/components'

import {
  Container,
  Content,
  Main,
  Title,
} from '../PrivacyPolicy/PrivacyPolicy.styles'

export function TermsAndConditions() {
  const { data, loading } = useTermsAndConditionsQuery()

  return (
    <Main>
      <MaxWidthContainer>
        <Container>
          {data && !loading && (
            <>
              <Title
                dangerouslySetInnerHTML={{
                  __html: data.termsAndConditions.title,
                }}
              />
              <Content
                dangerouslySetInnerHTML={{
                  __html: data.termsAndConditions.content,
                }}
              />
            </>
          )}
        </Container>
      </MaxWidthContainer>
    </Main>
  )
}
