import type { SignInUser } from '@graphql/generated/types'
import { UserRole, useVerifyEmailMutation } from '@graphql/generated/types'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components'

import { Button, Loader } from '@/components'
import { schoolioBookStoreUrl } from '@/core/const'
import { useRecaptcha, useTitle } from '@/core/hooks'
import { childAnalytics, parentAnalytics } from '@/core/services/analytics'
import { session } from '@/core/services/session'
import { FormColumn, SchoolioLogo, Title } from '@/pages/auth/Auth.styled'
import { route } from '@/pages/routes'
import { useAuthUser } from '@/store/user'

export const Description = styled.p`
  max-width: 27rem;
  text-align: center;
`

export const LoginButton = styled(Button)`
  margin: 1.5rem auto;
`

export function VerifyEmail() {
  const { code = '' } = useParams()
  const { t } = useTranslation('verifyEmail')
  const navigate = useNavigate()
  const { isLoading: isRecaptchaLoading, getToken } = useRecaptcha('login')

  useTitle('verifyEmail')

  const backToLogin = () => {
    navigate(route.signIn())
  }

  const initAnalytics = ({ _id, role, email, username }: SignInUser) => {
    if (role === UserRole.Child) {
      childAnalytics.identify(_id, email, username)
      childAnalytics.emitLogin()
    } else {
      parentAnalytics.identify(_id, email, username)
      parentAnalytics.emitLogin()
    }
  }

  const [verifyEmail, { loading }] = useVerifyEmailMutation({
    onError() {
      backToLogin()
    },
    onCompleted({ verifyEmail: { user } }) {
      session.setRememberMe(true)
      initAnalytics(user)

      if (user.role === UserRole.Child) {
        const lastMoodCheckStart = user.lastMoodCheck?.start

        if (!lastMoodCheckStart) {
          return
        }

        if (new Date(lastMoodCheckStart) < new Date()) {
          return
        }

        setUser(user)
      } else {
        setUser(user)
        backToLogin()
      }
    },
  })

  const { setUser } = useAuthUser()

  useEffect(() => {
    if (!code) {
      backToLogin()
    }

    const getTokenAndVerify = async () => {
      const recaptchaToken = await getToken()

      await verifyEmail({
        variables: {
          code,
          recaptchaToken,
        },
      })
    }

    getTokenAndVerify()
  }, [code])

  const openBookStore = () => {
    window.open(schoolioBookStoreUrl, '_self')
  }

  return (
    <FormColumn>
      <SchoolioLogo onClick={openBookStore} />
      {(loading || isRecaptchaLoading) && <Loader color="grape" size="2rem" />}
      {!loading && !isRecaptchaLoading && (
        <>
          <Title>{t('emailVerification')}</Title>
          <Description>
            {t('congratulations')}
            <br />
            <LoginButton onClick={backToLogin}>{t('backToLogin')}</LoginButton>
          </Description>
        </>
      )}
    </FormColumn>
  )
}
