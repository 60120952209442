import type { DefaultTheme } from 'styled-components'

const color = {
  transparent: 'transparent',
  grape: '#6721b0',
  cornflowerBlue: '#6195ed',
  cornflower: '#6195ed',
  greyTransperent: '#00000026',
  bossanova: '#3e2357',
  black: '#000000',
  rum: '#735b89',
  blueChalk: '#f6edff',
  haiti: '#1f1242',
  doveGray: '#6d6d6d',
  silver: '#cccccc',
  silverChalice: '#9d9d9d',
  wildSand: '#F5F5F5',
  nobel: '#b3b3b3',
  alto: '#d9d9d9',
  gallery: '#ebebeb',
  whiteLilac: '#F3EDF9',
  white: '#ffffff',
  mustard: '#ffda4e',
  electricViolet: '#982fff',
  pastelGreen: '#84dd82',
  cerise: '#e34084',
  green: '#0fb900',
  royalBlue: '#4e75e9',
  fruitSalad: '#5c9f51',
  zest: '#e09220',
  godGray: '#0A0A0A',
  iron: '#DCDCDD',
  portGore: '#281C4C',
  alizarinCrimson: '#EC2727',
  roofTerracotta: '#B21E1E',
  navyBlue: '#03009B',
  goldenrod: '#FCD062',
  tulipTree: '#E3B030',
  mirage: '#1E172B',
  greenKelp: '#284727',
  blueRibbon: '#456DE5',
  fuchsiaBlue: '#8852C1',
  lilacBush: '#A57CD3',
  coldPurple: '#B593D7',
  moonRaker: '#E4D4F6',
  japaneseLaurel: '#0C9A00',
  blueViolet: '#514baa',
  purpleHeart: '#8E39E5',
  bilobaFlower: '#C093EF',
  snuff: '#dfd3eb',
  mangolia: '#FAF5FF',
  zumthor: '#E2EBFF',
  violetMagican: '#5E328B',
  obsidianShell: '#401869',
  kingTriton: '#3988C4',
  uscGold: '#FFCC00',
  fieldPoppy: '#D97139',
  gryphonneSepiaWash: '#843E19',
  manchesterNights: '#9B2222',
  philippineBrown: '#5F1414',
  alpineMeadow: '#68AA30',
  bowserShell: '#56721D',
  seductiveThorns: '#A2CE46',
  pecanVeneer: '#E0A179',
  classicBouquet: '#A388BE',
  greekSea: '#75A1DC',
  jinzaSaddlower: '#EB7E7E',
  babyBlossom: '#FCF1EA',
  pecanVener: '#e0a179',
  americanBlue: '#383B6B',
  sweetLucidDreams: '#CAB9DB',
  aliceBlue: '#F0F6FF',
  babyTooth: '#F5FFE0',
  astroZinger: '#7478BA',
  wafer: '#D6BAAF',
  ladyGuinevere: '#C8A1A0',
  wasabiPeanut: '#B2C8A0',
  alaskanIce: '#7C9AC5',
  wideSky: '#3D6FB1',
  codeCurrent: '#204372',
  placeboFuchsia: '#F4E9FF',
  peachBreeze: '#FEEDE6',
  eternalElegance: '#B2C3DB',
  obscureOlive: '#3E6021',
  fadingHorizon: '#481F72',
  indianRed: '#870B0B',
  angelInBlueJeans: '#BBC7D9',
  blueberryWhip: '#D1D5DB',
  seriousCloud: '#7f858d',
  philippineGoldenYellow: '#EBBC00',
  sprucedUp: '#978024',
  distilledMoss: '#CAFFCC',
  janePurple: '#e1c2ff',
  pumpkin: '#ff7415',
  placebo: '#e7e7e7',
  plumsTheWorld: '#ddcbef',
  lightLavender: '#eee1fc',
  gunmetal: '#374151',
  slateGray: '#4B5563',
  royalPurple: '#4a3883',
  deepLavendar: '#644FA8',
  periwinkle: '#9A86DB',
  midnightAmethyst: '#201541',
  forestGreen: '#4CA22F',
  gainsBoro: '#E5E7EB',
  emeraldGreen: '#5CB75A',
  blueSteel: '#9CA3AF',
  lavenderMist: '#E4D4F6',
  wassail: '#e4e4e4',
  mintJelly: '#ffd5d5',
} as const

export type Color = keyof typeof color

export type Size = `${number}rem` | `${number}em` | `${number}%` | `${number}px`

const fontSize = {
  tiny: '0.75rem', // 12px
  small: '0.875rem', // 14px
  normal: '1rem', // 16px
  big: '1.125rem', // 18px
  bigger: '1.25rem', // 20px
  large: '1.375rem', // 22px
  great: '1.5rem', // 24px
  huge: '1.625rem', // 26px
  massive: '1.875rem', // 30px
  gigantic: '2.25rem', // 36px
} as const

export type FontSize = keyof typeof fontSize

const fontWeight = {
  light: 300,
  regular: 400,
  medium: 500,
  big: 600,
  bigger: 700,
  boldest: 900,
} as const

const landingSpecialFontSize = {
  tiny: '1rem', // 16px
  small: '1.125rem', // 18px
  normal: '1.25rem', // 20px
  big: '1.375rem', // 22px
  bigger: '1.5rem', // 24px
  large: '1.375rem', // 22px
  great: '1.5rem', // 24px
  extraGreat: '1.625rem', // 26px
  huge: '1.75rem', // 28px
  massive: '2.375rem', // 38px
  extraMassive: '3.125rem', // 50px
  gigantic: '3.5rem', // 56px
  extraGigantic: '4.375rem', // 70px
}

export type LandingSpecialFontSize = keyof typeof landingSpecialFontSize

export type FontWeight = keyof typeof fontWeight

export const theme: DefaultTheme = {
  color,
  fontSize,
  fontWeight,
  landingSpecialFontSize,
}
