import styled from 'styled-components'

import { Button } from '@/components/Button'
import { flexColumn } from '@/styles/utils'

export const Buttons = styled.div`
  ${flexColumn()};
  gap: 0.1rem;
`

export const Cancel = styled(Button)`
  color: ${({ theme }) => theme.color.grape};

  && {
    border: none;
  }
`

export const Success = styled.p`
  ${flexColumn()};
  align-items: center;
`
