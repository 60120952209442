import { selector } from 'recoil'

import { authUser } from './atoms'

export const isLogged = selector({
  key: 'isAuthUserLogged',
  get({ get }) {
    return Boolean(get(authUser))
  },
})
