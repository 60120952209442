import { Link as RouterLink } from 'react-router-dom'
import styled, { css, keyframes } from 'styled-components'

import { containerMaxWidth } from '@/styles/components'
import { media } from '@/styles/media'
import { changeOpacity, flexColumn, flexRow } from '@/styles/utils'

import { commonQuickStartStyle } from '../QuickStart/QuickStart.styled'

const stickyIn = keyframes`
  0% {
    position: static;
    opacity: 1;
  }
  
  49% {
    position: static;
    opacity: 0;
  }
  
  50% {
    position: absolute;
    opacity: 0;
  }
  
  100% {
    position: absolute;
    top: 110px;
    opacity: 1;
  }
`

const stickyOut = keyframes`
  0% {
    position: absolute;
    top: 110px;
    opacity: 1;
  }

  49% {
    position: absolute;
    top: 110px;
    opacity: 0;
  }

  50% {
    position: static;
    opacity: 0;
  }

  100% {
    opacity: 1;
    position: static;
  }
`

export const MaxWidthContainer = styled.div`
  max-width: ${containerMaxWidth};
`
export const NavSection = styled.section<{
  isAbsolute: boolean
  isNavExpanded: boolean
}>`
  width: 100%;
  text-align: center;
  ${({ isAbsolute }) =>
    css`
      animation: ${isAbsolute ? stickyIn : stickyOut} 250ms linear 1 forwards;
      position: fixed;
      left: 0;
      right: 0;
      z-index: 100;
      top: 0;
      background: white;
      margin-top: ${isAbsolute ? '-7rem' : 'none'};

      box-shadow: ${isAbsolute
        ? css`
      0px 4px 6px -1px ${({ theme }) =>
        changeOpacity(theme.color.black, 0.1)}, 0px 2px 4px -1px ${({
            theme,
          }) => changeOpacity(theme.color.black, 0.06)}
        `
        : 'none'};
    `};

  ${({ isNavExpanded }) =>
    css`
      border-radius: ${isNavExpanded ? '0' : '0 0 32px 32px'};
    `};
`

export const Main = styled.div<{ isAbsolute: boolean }>`
  max-width: 70rem;
  ${flexRow()};
  position: relative;
  justify-content: space-between;

  ${media.desktop(
    () => css`
      max-width: 55rem;
    `,
  )}

  ${media.tablet(
    () => css`
      max-width: 40rem;
    `,
  )}
  
${({ isAbsolute }) =>
    css`
      margin: ${isAbsolute ? '1rem auto' : '1.25rem auto'};
    `};
`

export const Links = styled.div<{ isNavExpanded: boolean }>`
  ${flexRow()};
  gap: 32px;
  align-items: center;

  ${media.mobile(
    () => css`
      ${flexColumn()};
      position: absolute;
      top: 45px;
      width: 100%;
      z-index: 10;
      background-color: white;
      padding: 2rem 1rem;
      gap: 1.5rem;
      border-radius: 0px 0px 64px 64px;
      box-shadow: 0px 25px 50px -25px rgba(0, 0, 0, 0.25);
    `,
  )}

  ${({ isNavExpanded }) => {
    return media.mobile(
      () => css`
        display: ${isNavExpanded ? 'flex' : 'none'};
      `,
    )
  }}
`

export const HamburgerBtn = styled.button`
  display: none;

  ${media.mobile(
    () => css`
      display: block;
      margin: 0 1rem;
    `,
  )}
`
export const HamburgerLogo = styled.img``

export const Logo = styled.img`
  width: 8rem;
  cursor: pointer;

  ${media.mobile(
    () => css`
      width: 6rem;
      margin: auto 1rem;
    `,
  )}
`

export const Link = styled.a`
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  font-size: ${({ theme }) => theme.fontSize.bigger};
  text-decoration: none;
  color: ${({ theme }) => theme.color.black};

  ${media.mobile(
    () => css`
      font-weight: ${({ theme }) => theme.fontWeight.bigger};
      font-size: ${({ theme }) => theme.fontSize.huge};
    `,
  )}
`

export const SignUpLink = styled.a`
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  font-size: ${({ theme }) => theme.fontSize.big};
  text-decoration: none;
  color: ${({ theme }) => theme.color.white};
  background: linear-gradient(
    98.27deg,
    ${({ theme }) => theme.color.violetMagican} 1.4%,
    ${({ theme }) => theme.color.kingTriton} 183.31%
  );
  padding: 0.2rem 1rem;
  border-radius: 16px;

  ${media.mobile(
    () => css`
      font-weight: ${({ theme }) => theme.fontWeight.bigger};
      font-size: ${({ theme }) => theme.fontSize.huge};
    `,
  )}
`

export const LinkForClick = styled(RouterLink)`
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  font-size: ${({ theme }) => theme.fontSize.bigger};
  text-decoration: none;
  color: ${({ theme }) => theme.color.black};

  ${media.mobile(
    () => css`
      font-weight: ${({ theme }) => theme.fontWeight.bigger};
      font-size: ${({ theme }) => theme.fontSize.huge};
    `,
  )}
`

export const Hr = styled.hr<{ isNavExpanded: boolean }>`
  border: 4px solid ${({ theme }) => theme.color.pecanVeneer};
  display: none;

  ${({ isNavExpanded }) => {
    return media.mobile(
      () => css`
        display: ${isNavExpanded ? 'flex' : 'none'};
      `,
    )
  }}
`

export const NotSureText = styled.p<{ isNavExpanded: boolean }>`
  ${commonQuickStartStyle}
  margin-top: 2rem;
  display: none;

  ${({ isNavExpanded }) => {
    return media.mobile(
      () => css`
        display: ${isNavExpanded ? 'block' : 'none'};
      `,
    )
  }}
`

export const GetHelp = styled.a<{ isNavExpanded: boolean }>`
  color: ${({ theme }) => theme.color.fieldPoppy};
  ${commonQuickStartStyle}
  text-decoration: underline;
  display: none;

  ${({ isNavExpanded }) => {
    return media.mobile(
      () => css`
        display: ${isNavExpanded ? 'block' : 'none'};
      `,
    )
  }}
`
