import { useTranslation } from 'react-i18next'

import analytics from '@/assets/images/landing/analytics.svg'
import courses from '@/assets/images/landing/courses.webp'

import {
  ImageTextFlexContainer,
  LinesContainer,
  MainTitle,
  MaxWidthContainer,
} from '../../Landing.styled'
import { LineListing } from '../LineListing'
import {
  ImageAnalytics,
  ImageContainer,
  ImageCourses,
  Main,
} from './Library.styled'

const lines = [
  'accessThousands',
  'flexibleScheduling',
  'increaseEngagement',
  'gapAssessment',
]

export function Library() {
  const { t } = useTranslation('landing')

  return (
    <MaxWidthContainer>
      <Main>
        <MainTitle>{t('titles.aLibraryThat')}</MainTitle>

        <ImageTextFlexContainer>
          <LinesContainer>
            <LineListing showSignupBtn lines={lines} color="Purple" />
          </LinesContainer>

          <ImageContainer>
            <ImageCourses src={courses} loading="lazy" alt="courses view" />
            <ImageAnalytics src={analytics} />
          </ImageContainer>
        </ImageTextFlexContainer>
      </Main>
    </MaxWidthContainer>
  )
}
