import type { AssignCourseInput } from '@graphql/generated/types'

import type { CartCourse } from '@/store/parent/marketplaceCart/atoms'

export const convertToAssignCourseInput = (
  courses: Array<[string, CartCourse[]]>,
): AssignCourseInput[] =>
  courses.map(([childId, courses]) => ({
    childId,
    courseIds: courses.map(({ _id }) => _id),
  }))
