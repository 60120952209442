import { useTranslation } from 'react-i18next'

import { Icon } from '@/components'
import { metaverseName } from '@/core/const'
import { mediaPath } from '@/core/services/mediaPath'
import type { CartCourse } from '@/store/parent/marketplaceCart/atoms'

import {
  Course,
  CourseImage,
  Courses,
  Delete,
  Name,
  Price,
  Student,
  StudentCourse as StyledStudentCourse,
} from './Cart.styled'

type StudentCourseProps = {
  childFullName: string
  currency: string
  courses: CartCourse[]
  onDelete: (id: string) => void
}

export function StudentCourse({
  childFullName,
  courses,
  currency,
  onDelete,
}: StudentCourseProps) {
  const { t } = useTranslation('parent', {
    keyPrefix: 'cart',
  })
  const { t: tCommon } = useTranslation('common')

  return (
    <StyledStudentCourse>
      <Student>
        {t('student')} {childFullName}:
      </Student>
      <Courses>
        {courses.map(({ image, name, _id, price }) => {
          const isMetaverse = name === metaverseName

          return (
            <Course key={_id}>
              <CourseImage
                src={mediaPath.build({
                  url: image,
                  folderKey: 'course',
                })}
                alt="course image"
              />
              <Name>{isMetaverse ? t(name) : name}</Name>
              <Price>
                {price > 0 ? `${price} ${currency}` : tCommon('free')}
              </Price>
              <Delete
                onClick={() => {
                  onDelete(_id)
                }}
              >
                <Icon icon="delete" />
              </Delete>
            </Course>
          )
        })}
      </Courses>
    </StyledStudentCourse>
  )
}
