import styled, { css } from 'styled-components'

import { sectionContainerPadding } from '@/styles/components'
import type { Size } from '@/styles/theme'
import {
  fadeInAnimation,
  flexCenter,
  flexColumn,
  oneLineText,
  transitions,
} from '@/styles/utils'

type WithExpanded = {
  isExpanded: boolean
}

type WithDisableAnimation = {
  disableAnimation: boolean
}

export const animationDuration = 500
const bodyMaxHeight = '2000px'

export const Container = styled.div<
  WithExpanded & WithDisableAnimation & { isLoading: boolean }
>`
  ${flexColumn()};
  ${({ disableAnimation }) =>
    !disableAnimation &&
    css`
      ${transitions({
        duration: animationDuration,
      })('gap')};
    `}
  gap: ${({ isExpanded }) => (isExpanded ? '0.75rem' : 0)};
  padding: ${sectionContainerPadding};
  cursor: ${({ isLoading }) => (isLoading ? 'not-allowed' : 'pointer')};
  color: ${({ theme }) => theme.color.white};
  font-size: ${({ theme }) => theme.fontSize.huge};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
`

export const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${oneLineText()};

  color: inherit;
  font-size: inherit;
  font-weight: inherit;
`

const expanderPseudoElement = () => css`
  content: '';
  display: block;
  background-color: ${({ theme }) => theme.color.white};
`

export const Expander = styled.button<WithExpanded>`
  width: 1rem;
  height: 1rem;
  position: relative;
  ${flexCenter()};
  ${fadeInAnimation()};

  &:before {
    ${expanderPseudoElement()};
    width: 100%;
    height: 0.125rem;
  }

  &:after {
    ${expanderPseudoElement()};
    position: absolute;
    height: 100%;
    width: 0.125rem;
    ${transitions({})('opacity')};
    opacity: ${({ isExpanded }) => (isExpanded ? 0 : 1)};
  }
`

export const Body = styled.div<
  WithExpanded & WithDisableAnimation & { maxHeight?: Size }
>`
  overflow: auto;
  ${({ disableAnimation }) =>
    !disableAnimation &&
    css`
      ${transitions({
        duration: animationDuration,
      })('max-height')};
    `}

  ${fadeInAnimation('ease-in-out')};
  max-height: ${({ isExpanded, maxHeight }) =>
    isExpanded ? maxHeight ?? bodyMaxHeight : 0};
`
