import { Outlet, Route } from 'react-router-dom'

import { SystemRequirements } from '@/pages/common/SystemRequirements'

import { VerifyEmail } from '../auth/VerifyEmail'
import { CourseInfo } from './CourseInfo'
import { DigitalAd } from './DigitalAd'
import { Marketplace } from './Marketplace'
import { Pricing } from './Pricing'
import { PrivacyPolicy } from './PrivacyPolicy'
import { TermsAndConditions } from './TermsAndConditions'
import { TestingPage } from './TestingPage'

export enum CommonRoute {
  TermsAndConditions = '/terms-and-conditions',
  PrivacyPolicy = '/privacy-policy',
  SystemRequirements = '/system-requirements',
  openMarketplace = '/open-marketplace',
  TestingPage = '/testing-route',
  Ad = 'ad',
  CourseInfo = '/course-info/:courseId',
  VerifyEmail = '/verifyEmail/:code',
  Pricing = '/pricing',
}

// Unable use <CommonRoutes /> due to react-router-dom exception
export const getCommonRoutes = () => (
  <Route element={<Outlet />}>
    <Route path={CommonRoute.Ad} element={<DigitalAd />} />
    <Route path={CommonRoute.CourseInfo} element={<CourseInfo />} />
    <Route path={CommonRoute.openMarketplace} element={<Marketplace />} />
    <Route
      path={CommonRoute.TermsAndConditions}
      element={<TermsAndConditions />}
    />
    <Route path={CommonRoute.PrivacyPolicy} element={<PrivacyPolicy />} />
    <Route
      path={CommonRoute.SystemRequirements}
      element={<SystemRequirements />}
    />
    <Route path={CommonRoute.TestingPage} element={<TestingPage />} />
    <Route path={CommonRoute.VerifyEmail} element={<VerifyEmail />} />
    <Route path={CommonRoute.Pricing} element={<Pricing />} />
  </Route>
)
