import { useRecoilState } from 'recoil'

import { app } from '@/store/app/atoms'

export const useApp = () => {
  const [{ isLoading }, setApp] = useRecoilState(app)

  const setLoading = (isLoading: boolean) => {
    setApp((prev) => ({ ...prev, isLoading }))
  }

  return { isLoading, setLoading }
}
