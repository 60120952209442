import colorTool from 'color'
import type { Property } from 'csstype'
import type * as React from 'react'
import type { DefaultTheme, StyledComponent } from 'styled-components'
import styled, { css } from 'styled-components'

import type { PartialRecord } from '@/core/types'
import { fadeIn } from '@/styles/keyframes'
import type { Color, FontSize, Size } from '@/styles/theme'

export type TransitionOptions = {
  duration?: number
  timingFunction?: Property.TransitionTimingFunction
  delay?: number
}

export type TransitionOptionsWithAttribute = {
  attribute: string
} & TransitionOptions

type TextSizeMap = PartialRecord<Size | FontSize, [number, number?]>
type TextLineMap = Record<number, [number, number?]>

export const changeOpacity = (color: string, opacity: number): string =>
  colorTool(color).alpha(opacity).hsl().string()

export const darken = (color: string, ratio: number) =>
  colorTool(color).darken(ratio).hsl().string()

export const lighten = (color: string, ratio: number) =>
  colorTool(color).lighten(ratio).hsl().string()

export const absoluteCenter = () => css`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`

export const flexCenter = () => css`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const flexColumn = () => css`
  display: flex;
  flex-direction: column;
`

export const flexRow = () => css`
  display: flex;
  flex-direction: row;
`

export const oneLineText = () => css`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

export const multilineTruncateText = (lines = 2) => css`
  display: -webkit-box;
  -webkit-line-clamp: ${lines};
  -webkit-box-orient: vertical;
  overflow: hidden;
`

export const fancyButtonBackground = (color: string) => css`
  background: radial-gradient(
      92.09% 85.42% at 86.3% 87.5%,
      ${({ theme }) => changeOpacity(theme.color.black, 0.23)} 0%,
      ${({ theme }) => changeOpacity(theme.color.black, 0)} 86.18%
    ),
    radial-gradient(
      65.28% 65.28% at 26.39% 20.83%,
      ${({ theme }) => changeOpacity(theme.color.white, 0.412)} 0%,
      ${({ theme }) => changeOpacity(theme.color.white, 0)} 69.79%,
      ${({ theme }) => changeOpacity(theme.color.white, 0)} 100%
    ),
    ${color};
`

export const fancyContainerBackground = (color: string) => css`
  background: radial-gradient(
      92.09% 85.42% at 86.3% 87.5%,
      ${({ theme }) => changeOpacity(theme.color.black, 0.23)} 0%,
      ${({ theme }) => changeOpacity(theme.color.black, 0)} 86.18%
    ),
    radial-gradient(
      104% 117% at 102.85% -9.48%,
      ${({ theme }) => changeOpacity(theme.color.white, 0.412)} 0%,
      ${({ theme }) => changeOpacity(theme.color.white, 0)} 69.79%,
      ${({ theme }) => changeOpacity(theme.color.white, 0)} 100%
    ),
    ${color};
`

export const typography = {
  h1: () => css`
    font-size: ${({ theme }) => theme.fontSize.gigantic};
    font-weight: ${({ theme }) => theme.fontWeight.medium};
  `,
  h2: () => css`
    font-size: ${({ theme }) => theme.fontSize.large};
    font-weight: ${({ theme }) => theme.fontWeight.medium};
  `,
  h3: () => css`
    font-size: ${({ theme }) => theme.fontSize.big};
    font-weight: ${({ theme }) => theme.fontWeight.medium};
  `,
  bodyBold: () => css`
    font-size: ${({ theme }) => theme.fontSize.normal};
    font-weight: ${({ theme }) => theme.fontWeight.medium};
  `,
  body: () => css`
    font-size: ${({ theme }) => theme.fontSize.normal};
    font-weight: ${({ theme }) => theme.fontWeight.regular};
  `,
  bodySmall: () => css`
    font-size: ${({ theme }) => theme.fontSize.small};
    font-weight: ${({ theme }) => theme.fontWeight.regular};
  `,
}

export const transitions =
  ({
    duration: transitionDuration = 200,
    timingFunction = 'ease-in-out',
    delay = 0,
  }: TransitionOptions) =>
  (...attributes: Property.TransitionProperty[]) =>
    css`
      transition: ${attributes
        .map(
          (attribute) =>
            `${attribute} ${transitionDuration}ms ${timingFunction} ${delay}ms`,
        )
        .join(', ')};
    `

export const differingTransitions = (
  ...animations: TransitionOptionsWithAttribute[]
) =>
  css`
    transition: ${animations
      .map(
        ({
          duration = 300,
          timingFunction = 'ease-in-out',
          delay = 0,
          attribute,
        }) => `${attribute} ${duration}ms ${timingFunction} ${delay}ms`,
      )
      .join(', ')};
  `

export const withDynamicFontSize = <
  Tag extends keyof JSX.IntrinsicElements | React.ComponentType<any>,
  Props extends Record<string, unknown>,
>(
  Component: StyledComponent<Tag, any, Props>,
) => styled(Component)<
  {
    textSizeMap: TextSizeMap
    textLength: number
    textLineMap?: TextLineMap
  } & Props
>`
  font-size: ${({
    theme,
    textSizeMap,
    textLength,
  }: {
    theme: DefaultTheme
    textSizeMap: TextSizeMap
    textLength: number
  }) => {
    const isPredefinedSize = (size: string): size is FontSize =>
      !/\d/.test(size)

    for (const key in textSizeMap) {
      const [min, max] = textSizeMap[key as keyof TextSizeMap]!

      if (!max && textLength >= min) {
        return isPredefinedSize(key) ? theme.fontSize[key] : key
      }

      if (min <= textLength && textLength <= (max ?? min)) {
        return isPredefinedSize(key) ? theme.fontSize[key] : key
      }
    }
  }}};

  ${({ textLineMap, textLength }) => {
    for (const key in textLineMap) {
      const [min, max] = textLineMap[key as unknown as number] as [
        number,
        number?,
      ]

      if (!max && textLength >= min) {
        return css`
          -webkit-line-clamp: ${key};
        `
      }

      if (min <= textLength && textLength <= (max ?? min)) {
        return css`
          -webkit-line-clamp: ${key};
        `
      }
    }

    return false
  }}
  
`

export const scrollbar = (
  trackColor: Color = 'white',
  thumbColor: Color = 'grape',
) => css`
  ::-webkit-scrollbar-track {
    border-radius: 100px;
    background: ${({ theme }) => theme.color[trackColor]};
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 100px;
    background: ${({ theme }) => theme.color[thumbColor]};
  }
`

export const fadeInAnimation = (
  timingFunction: Property.TransitionTimingFunction = 'linear',
  delay?: number,
) => css`
  animation: ${fadeIn} 250ms ${timingFunction} ${delay ? `${delay}ms` : ''} 1
    forwards;
`
