import { useTranslation } from 'react-i18next'

import boy from '@/assets/images/landing/boy.webp'
import girl from '@/assets/images/landing/girl.webp'
import spiral from '@/assets/images/landing/spiral.svg'

import { Button, MainTitle, MaxWidthContainer } from '../../Landing.styled'
import { LineListing } from '../LineListing'
import { ButtonContainer } from '../PerfectFor/PerfectFor.styled'
import {
  ImageBoy,
  ImageContainer,
  ImageGirl,
  ImageSpiral,
  ImageTextFlexContainer,
  ListContainer,
  Main,
  TagLine,
  VideoFrame,
} from './AllInOneSolution.styled'

const lines = [
  'exploreAllSubjects',
  'personalizedScheduling',
  'customCourse',
  'studentSuccess',
  'exclusiveKids',
  'regularCheckIns',
]

export function AllInOneSolution() {
  const { t } = useTranslation('landing')

  const signUp = () => {
    document.documentElement.scrollTop = 0
    document.body.scrollTop = 0
    // navigate(route.signUp())
    // window.location.reload()
  }

  return (
    <MaxWidthContainer>
      <Main>
        <ImageBoy src={boy} />
        <ImageGirl src={girl} />
        <MainTitle>{t('titles.yourCompleteAllInOne')}</MainTitle>
        <TagLine>{t('lines.whatsIncluded')}</TagLine>

        <ImageTextFlexContainer>
          <ImageContainer>
            <ImageSpiral src={spiral} />
            <VideoFrame
              src="https://www.youtube.com/embed/2yWHHFxPfXg"
              frameBorder="0"
            />
          </ImageContainer>
          <ListContainer>
            <LineListing lines={lines} color="Orange" />
            <ButtonContainer>
              <Button
                isFullWidthMobile
                marginTop={2}
                bgColor="Orange"
                onClick={signUp}
              >
                {t('buttons.btnGetStarted')}
              </Button>
            </ButtonContainer>
          </ListContainer>
        </ImageTextFlexContainer>
      </Main>
    </MaxWidthContainer>
  )
}
