import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import type { SingleValue } from 'react-select'

import downloadImg from '@/assets/icons/download.svg'
import type { Option } from '@/components'
import { Select } from '@/components'
import type { StudentsForReports } from '@/graphql/generated/types'
import {
  useGetStudentsByGradeCountryLazyQuery,
  useGradesQuery,
  useUserCountriesReportQuery,
} from '@/graphql/generated/types'

import {
  Card,
  DownloadButton,
  DownloadIcon,
  RowCard,
  RowCardDownload,
  StudentsLine,
} from '../ChildRepots.styled'

const useCountries = () => {
  const { data, loading } = useUserCountriesReportQuery()

  const countries = useMemo(() => {
    if (data) {
      const countryNames = data.userCountriesReport.map((x) => x.country)
      return ['All Countries', ...countryNames] // Adding 'All' as the first option
    }

    return ['Loading Countries']
  }, [data])

  if (loading) return ['Loading Countries']

  return countries
}

export function ChildrenData() {
  const { data: gradesData } = useGradesQuery()

  const countries = useCountries()

  const [selectedGrade, setSelectedGrade] = useState<Option | undefined>({
    value: 'All Grades',
    label: 'All Grades',
  })
  const [filterQuery, setFilterQuery] = useState<{
    country: string
    location: string
  }>({ country: 'Canada', location: 'state' })

  const [studentsData, setStudentsData] = useState<StudentsForReports[]>([])

  const [getStudentsByGradeCountry, { loading }] =
    useGetStudentsByGradeCountryLazyQuery({
      fetchPolicy: 'no-cache',
      onCompleted({ getStudentsByGradeCountry }) {
        setStudentsData(getStudentsByGradeCountry)
      },
    })

  const { t } = useTranslation('admin', { keyPrefix: 'students' })

  const gradeOptions: Option[] = useMemo(() => {
    const grades =
      gradesData?.grades.map(({ _id, title }) => ({
        label: title,
        value: _id,
      })) ?? []

    return [{ label: 'All Grades', value: 'All Grades' }, ...grades]
  }, [gradesData])

  useEffect(() => {
    if (!gradeOptions || gradeOptions.length === 0) {
      return
    }

    setSelectedGrade(gradeOptions[0])
  }, [gradeOptions])

  useEffect(() => {
    if (!selectedGrade || !filterQuery.country) {
      return
    }

    getStudentsByGradeCountry({
      variables: {
        grade: selectedGrade.value,
        country: filterQuery.country,
      },
    })
  }, [selectedGrade, filterQuery])

  function downloadCSV(array: any[], filename: string) {
    const csv = convertArrayOfObjectsToCSV(array)
    const blob = new Blob([csv], { type: 'text/csv' })
    const url = window.URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', filename)
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  function convertArrayOfObjectsToCSV(array: any[]) {
    const header = Object.keys(array[0]).join(',')
    const csv = array.map((obj) => Object.values(obj).join(',')).join('\n')
    return `${header}\n${csv}`
  }

  return (
    <Card>
      {selectedGrade && (
        <RowCard>
          <Select
            name="grade"
            label={t('filterGrade')}
            options={gradeOptions.map((grade) => ({
              label: grade.label,
              value: grade.value,
            }))}
            isLoading={loading}
            isDisabled={loading}
            defaultValue={selectedGrade}
            appearance="grey"
            onChange={(e: SingleValue<Option>) => {
              setSelectedGrade(
                gradeOptions.find((grade) => grade.value === e?.value) ??
                  undefined,
              )
            }}
          />

          <Select
            name="country"
            label={t('filterCountry')}
            options={countries.map((country) => ({
              label: country,
              value: country,
            }))}
            isLoading={loading}
            isDisabled={loading}
            defaultValue={{ label: 'Canada', value: 'Canada' }}
            appearance="grey"
            onChange={(e: SingleValue<Option>) => {
              setFilterQuery({ ...filterQuery, country: e?.value ?? '' })
            }}
          />
        </RowCard>
      )}

      <RowCardDownload>
        {studentsData && !loading && (
          <StudentsLine>{studentsData.length} Student(s)</StudentsLine>
        )}
        <DownloadButton
          disabled={studentsData.length === 0}
          loading={loading}
          onClick={() => {
            downloadCSV(studentsData, 'students.csv')
          }}
        >
          <DownloadIcon src={downloadImg} />
        </DownloadButton>
      </RowCardDownload>
    </Card>
  )
}
