import { Modal } from '@/components'
import type { useModalControls } from '@/components/Modal/useModalControls'

import { Container, IFrame } from './MetaverseDetailsModal.styled'

export function MetaverseDetailsModal({
  isOpen,
  onClose,
}: Pick<ReturnType<typeof useModalControls>, 'isOpen' | 'onClose'>) {
  return (
    <Modal
      shouldCloseOnOverlayClick
      hideClose
      isOpen={isOpen}
      onRequestClose={onClose}
    >
      <Container>
        <IFrame
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          src="https://www.youtube.com/embed/Teip7HP76rQ"
        />
      </Container>
    </Modal>
  )
}
