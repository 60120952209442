import styled, { css } from 'styled-components'

import { media } from '@/styles/media'

const containerStyle = () => css`
  display: flex;
  gap: 1.25rem;
`

export const HalfContainer = styled.div`
  ${containerStyle()};

  & > div {
    width: 47%;
  }

  ${media.mobile(
    () => css`
      display: flex;
      flex-direction: column;

      & > div {
        width: 100%;
      }
    `,
  )}
`

export const Buttons = styled.div`
  ${containerStyle()};
  flex-direction: row-reverse;

  & > button {
    flex: 1;
  }
`
