import type { MarketplaceCoursesCourse } from '@graphql/generated/types'
import { atom } from 'recoil'
import { recoilPersist } from 'recoil-persist'

const { persistAtom } = recoilPersist()

export type CartCourse = MarketplaceCoursesCourse & {
  child: {
    _id: string
    fullName: string
    grade: string
  }
}

export type MarketplaceCart = {
  courses: Record<string, CartCourse[]>
}

export const defaultValue = {
  courses: {},
}

export const marketplaceCart = atom<MarketplaceCart>({
  key: 'marketplaceCart',
  default: defaultValue,
  effects_UNSTABLE: [persistAtom],
})
