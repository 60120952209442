import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import type { MenuItemStyles } from 'react-pro-sidebar'
import { Menu, MenuItem, Sidebar, SubMenu } from 'react-pro-sidebar'
import { NavLink } from 'react-router-dom'

import { Icon } from '@/components'
import { route } from '@/pages/routes'
import { theme } from '@/styles/theme'

import { NavigationContainer, NavigationWrapper } from './Navbar.styled'
import { NavbarFooter } from './NavbarFooter'
import { NavbarHeader } from './NavbarHeader'

const menuItemStyles: MenuItemStyles = {
  root: {
    fontSize: theme.fontSize.small,
    fontWeight: 400,
  },
  icon: {
    color: theme.color.white,
  },
  SubMenuExpandIcon: {
    color: theme.color.white,
  },
  subMenuContent: () => ({
    backgroundColor: theme.color.codeCurrent,
  }),

  button: {
    '&:hover': {
      backgroundColor: 'transparent',
      color: theme.color.greekSea,
    },
    [`&.active`]: {
      paddingLeft: 60,
      color: theme.color.greekSea,
    },
  },
}

export function Navbar() {
  const [collapsed, setCollapsed] = useState<boolean>(false)
  const { t } = useTranslation('admin', { keyPrefix: 'navigation' })
  return (
    <Sidebar
      backgroundColor={theme.color.codeCurrent}
      collapsed={collapsed}
      rootStyles={{
        color: theme.color.white,
      }}
    >
      <NavigationWrapper>
        <NavigationContainer>
          <NavbarHeader
            isCollapsed={collapsed}
            toggleCollapse={() => {
              setCollapsed(!collapsed)
            }}
          />
          <Menu menuItemStyles={menuItemStyles}>
            <SubMenu
              label={t('statistics.header')}
              icon={<Icon icon="rocketLaunch" />}
            >
              <MenuItem
                component={<NavLink to={route.admin.default()} />}
                icon={<Icon icon="rocketLaunch" />}
                active={false}
              >
                {t('statistics.overView')}
              </MenuItem>
              <MenuItem
                component={<NavLink to={route.admin.location()} />}
                icon={<Icon icon="rocketLaunch" />}
              >
                {t('statistics.location')}
              </MenuItem>
              <MenuItem
                component={<NavLink to={route.admin.educator()} />}
                icon={<Icon icon="rocketLaunch" />}
                active={false}
              >
                {t('statistics.educators')}
              </MenuItem>
              <MenuItem
                component={<NavLink to={route.admin.student()} />}
                icon={<Icon icon="rocketLaunch" />}
              >
                {t('statistics.students')}
              </MenuItem>
            </SubMenu>
          </Menu>
        </NavigationContainer>

        <NavbarFooter />
      </NavigationWrapper>
    </Sidebar>
  )
}
