import type { ApolloClient, NormalizedCacheObject } from '@apollo/client'
import type { ErrorResponse } from '@apollo/client/link/error'
import type { RefreshMutationResult } from '@graphql/generated/types'
import { RefreshDocument } from '@graphql/generated/types'

import { childAnalytics, parentAnalytics } from '@/core/services/analytics'
import { session } from '@/core/services/session'
import { TokensEmptyError } from '@/core/utils/errors'

type TokenData = Omit<ErrorResponse, 'networkError'> & {
  client: ApolloClient<NormalizedCacheObject>
}

type UpdateTokens = (data: TokenData) => Promise<void>

export const updateTokens: UpdateTokens = async ({ client, operation }) => {
  try {
    const { data } = await client.mutate<RefreshMutationResult['data']>({
      mutation: RefreshDocument,
    })

    const refreshData = data?.refresh

    if (!refreshData) {
      throw new TokensEmptyError()
    }

    const { accessToken } = refreshData

    const { headers } = operation.getContext()

    operation.setContext({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      headers: {
        ...headers,
        authorization: `Bearer ${accessToken}`,
      },
    })
  } catch (error: unknown) {
    // will emit where userDistinctId is set
    childAnalytics.emitLogoutAuto()
    parentAnalytics.emitLogoutAuto()

    session.clear()
    throw error
  }
}
