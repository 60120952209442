import { useEffect } from 'react'
import styled from 'styled-components'

import { Main as StyledMain, MaxWidthContainer } from '@/styles/components'

export const Container = styled(MaxWidthContainer)`
  padding: 2rem 8rem;
  width: auto;
  height: auto;
`

export const Main = styled(StyledMain)`
  background-color: ${({ theme }) => theme.color.white};
  padding-top: 1rem;
`

const adClient = 'ca-pub-7557424312252696'
const slotUnit = '3761534732'
const sizeType = 'horizontal'

export function TestingPage() {
  useEffect(() => {
    try {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-assignment
      ;((window as any).adsbygoogle = (window as any).adsbygoogle || []).push(
        {},
      )
    } catch (error: unknown) {}
  }, [])

  return (
    <Main>
      <MaxWidthContainer>
        <Container>
          <ins
            className="adsbygoogle"
            style={{ display: 'block' }}
            data-ad-client={adClient}
            data-ad-slot={slotUnit}
            data-adtest="on"
            data-ad-format={sizeType}
            data-full-width-responsive="true"
          />
        </Container>
      </MaxWidthContainer>
    </Main>
  )
}
