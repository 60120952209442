import { useEffect } from 'react'
import styled, { css } from 'styled-components'

import { Loader } from '@/components'
import { fadeOut } from '@/styles/keyframes'
import type { Color } from '@/styles/theme'
import { flexCenter } from '@/styles/utils'

const Container = styled.div<Required<Omit<FullscreenLoaderProps, 'color'>>>`
  position: ${({ isAbsolute }) => (isAbsolute ? 'absolute' : 'static')};
  inset: 0;
  overflow: hidden;
  ${flexCenter()};
  background-color: ${({ theme, backgroundColor }) =>
    theme.color[backgroundColor]};
  animation-iteration-count: 1;
  z-index: ${({ zIndex }) => zIndex};

  ${({ isFadeOut, duration, delay }) =>
    isFadeOut &&
    css`
      animation: ${fadeOut} ${duration}ms linear ${delay}ms forwards;
    `};
`

type FullscreenLoaderProps = {
  isFadeOut?: boolean
  duration?: number
  delay?: number
  isAbsolute?: boolean
  backgroundColor?: Color
  color?: Color
  zIndex?: number
}

// TODO: ideally make a useSuspense to allow animation end
export function FullscreenLoader({
  isAbsolute = true,
  duration = 500,
  delay = 0,
  isFadeOut = false,
  backgroundColor = 'blueChalk',
  color = 'grape',
  zIndex = 100,
}: FullscreenLoaderProps) {
  useEffect(() => {
    if (!isAbsolute) {
      return
    }

    document.body.style.overflow = 'hidden'

    return () => {
      document.body.style.overflow = 'auto'
    }
  }, [isAbsolute])
  return (
    <Container
      isAbsolute={isAbsolute}
      duration={duration}
      delay={delay}
      isFadeOut={isFadeOut}
      backgroundColor={backgroundColor}
      zIndex={zIndex}
    >
      <Loader color={color} size="2rem" />
    </Container>
  )
}
