import { joiResolver } from '@hookform/resolvers/joi'
import * as Joi from 'joi'
import { useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import type { Option } from '@/components'
import { Button, Checkbox } from '@/components'
import { ControlledSelect } from '@/components/Select/ControlledSelect'
import { validators } from '@/core/validation'
import { Form, Inputs } from '@/pages/auth/Auth.styled'
import { FormContainer } from '@/pages/auth/SignIn/SignIn.styled'
import { route } from '@/pages/routes'

import { Buttons } from './DetailsForm.styled'
import { EducatorRole } from './useSelectOptions'

export type DetailsFormSchema = {
  role: Option<EducatorRole> | undefined
  termsAndConditions: boolean
  responsibleParent: boolean
}

const schema = Joi.object<DetailsFormSchema>({
  role: validators.optionRequired(),
  termsAndConditions: validators.booleanTrueRequired(),
  responsibleParent: validators.booleanTrueRequired(),
})

type DetailsFormProps = {
  isLoading: boolean
  onSubmit: (form: DetailsFormSchema) => void
  onBack: VoidFunction
  hide: boolean
}

export function DetailsForm({
  onSubmit: onSubmitHandler,
  onBack,
  hide,
  isLoading,
}: DetailsFormProps) {
  const { t } = useTranslation('signUp')

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<DetailsFormSchema>({
    resolver: joiResolver(schema),
    defaultValues: {
      role: undefined,
      termsAndConditions: false,
      responsibleParent: false,
    },
  })

  const onSubmit = (form: DetailsFormSchema) => {
    onSubmitHandler(form)
  }

  const educatorOptions = useMemo(
    () =>
      Object.values(EducatorRole).map((value) => ({
        label: t(`role.${value}`),
        value,
      })),
    [],
  )

  return (
    <Form hide={hide} onSubmit={handleSubmit(onSubmit)}>
      <FormContainer>
        <Inputs>
          <ControlledSelect<DetailsFormSchema>
            control={control}
            name="role"
            label={t('form.role')}
            error={{
              message: errors.role?.message,
              field: t('form.roleField'),
            }}
            options={educatorOptions}
          />
        </Inputs>

        <Checkbox
          {...register('termsAndConditions')}
          error={errors.termsAndConditions?.message}
        >
          {t('form.agreeToSchoolio')}{' '}
          <Link to={route.termsAndConditions()} target="_blank">
            {t('form.termsAndConditions')}
          </Link>
          {', '}
          <Link to={route.privacyPolicy()} target="_blank">
            {t('form.privacyPolicy')}
          </Link>{' '}
          {t('form.and')}{' '}
          <Link to={route.systemRequirements()} target="_blank">
            {t('form.systemRequirements')}
          </Link>
          .
        </Checkbox>
        <Checkbox
          {...register('responsibleParent')}
          error={errors.responsibleParent?.message}
        >
          {t('form.responsibleParent')}
        </Checkbox>
      </FormContainer>
      <Buttons>
        <Button loading={isLoading}>{t('form.createAccount')}</Button>
        <Button
          appearance="outline"
          onClick={(event) => {
            event.preventDefault()
            onBack()
          }}
        >
          {t('form.goBack')}
        </Button>
      </Buttons>
    </Form>
  )
}
