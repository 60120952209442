/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-useless-constructor */
import { client } from '@graphql/apolloClient'
import type {
  SaveEventMutationResult,
  SaveEventMutationVariables,
} from '@graphql/generated/types'
import { SaveEventDocument } from '@graphql/generated/types'
import type { Dict } from 'mixpanel-browser'
import mixpanel from 'mixpanel-browser'
import voca from 'voca'

import type {
  AuthData,
  Event,
  LoginFailEventProps,
  SetProfileProps,
  UserProfile,
} from './types'
import { CommonEvent } from './types'

export abstract class Analytics {
  // private readonly projectId = import.meta.env.VITE_MIXPANEL_PROJECT_ID
  private userDistinctId: string | undefined
  private authData: AuthData | undefined

  constructor() {
    // mixpanel.init(this.projectId, {
    //   debug: !import.meta.env.PROD,
    // })
  }

  public identify(userId: string, email: string, username: string) {
    if (this.userDistinctId) {
      return
    }

    // mixpanel.identify(userId)
    this.identifyForGoogleAnalytics(userId)
    this.identifyForMetaPixel(userId, email)
    this.userDistinctId = userId
    this.authData = {
      email,
      username,
    }
  }

  public setProfile({
    email,
    role,
    firstName,
    lastName,
    username,
    grade,
  }: SetProfileProps) {
    if (!this.userDistinctId) {
      return
    }

    const userProfile: UserProfile = {
      $name: `${firstName} ${lastName}`,
      $email: email,
      userName: username,
      firstName,
      lastName,
      role,
      grade,
    }

    // mixpanel.people.set(userProfile)
  }

  public emitLogin() {
    this.emitEvent(CommonEvent.Login, this.authData)
  }

  public emitLoginFail(props: LoginFailEventProps) {
    this.emitUnauthorizedEvent(CommonEvent.LoginFail, props)
  }

  public emitLogout() {
    this.emitEvent(CommonEvent.Logout, this.authData)
  }

  public emitLogoutAuto() {
    this.emitEvent(CommonEvent.LogoutAuto, this.authData)
  }

  public emitLocationWebserviceError(
    status: number | undefined,
    url: string | undefined,
  ) {
    this.emitEvent(CommonEvent.LocationWebserviceError, { status, url })
  }

  public reset() {
    this.userDistinctId = undefined
    this.authData = undefined
  }

  protected emitEvent(name: Event, props?: Dict) {
    if (!this.userDistinctId) {
      return
    }

    // mixpanel.track(name, props)
    this.triggerForMetaAndGoogle(name, props)
    this.saveEvent(name, props)
  }

  protected emitUnauthorizedEvent(name: Event, props?: Dict) {
    // mixpanel.track(name, {
    //   distinct_id: '',
    //   $user_id: '',
    //   ...props,
    // })
    this.triggerForMetaAndGoogle(name, props)
    this.saveEvent(name, props)
  }

  protected formatDate(date: Date) {
    const dateArray = date.toISOString().split('.')
    dateArray.pop()
    return dateArray.join('.')
  }

  private triggerForMetaAndGoogle(name: Event, props?: Dict) {
    gtag('event', voca.snakeCase(name), props)
    fbq('trackCustom', voca.capitalize(name), props)
  }

  private saveEvent(name: Event, props?: Dict) {
    client.mutate<SaveEventMutationResult['data'], SaveEventMutationVariables>({
      mutation: SaveEventDocument,
      variables: {
        input: {
          name,
          propsJson: props ? JSON.stringify(props) : undefined,
          initiatorId: this.userDistinctId,
        },
      },
    })
  }

  private identifyForGoogleAnalytics(userId: string) {
    const isDev = import.meta.env.MODE === 'development'

    const measurementId = import.meta.env.VITE_GOOGLE_ANALYTICS_MEASUREMENT_ID

    gtag('js', new Date())
    gtag('config', measurementId, {
      debug_mode: isDev,
      user_id: userId,
    })
  }

  private identifyForMetaPixel(userId: string, email: string) {
    const pixelId = import.meta.env.VITE_FACEBOOK_PIXEL_ID

    fbq('init', pixelId, {
      external_id: userId,
      em: email,
    })
  }
}
