import type { HTMLProps, InputHTMLAttributes } from 'react'
import { forwardRef, useId, useMemo } from 'react'

import type { InputAppearance } from '@/components'

import type { ErrorType } from './Error'
import { Error, getIsError } from './Error'
import {
  Container,
  Label,
  Search,
  TextArea as StyledTextArea,
} from './Input.styled'

type TextAreaProps = Omit<
  Omit<HTMLProps<HTMLTextAreaElement>, 'as'>,
  'onChange'
> & {
  error?: ErrorType
  searchIcon?: boolean
  onChange?: InputHTMLAttributes<HTMLTextAreaElement>['onChange']
  appearance?: InputAppearance
}

export const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(
  (
    {
      id,
      searchIcon,
      label,
      placeholder,
      error = '',
      autoCorrect = 'off',
      disabled = false,
      appearance = 'default',
      ...rest
    },
    ref,
  ) => {
    const generatedId = useId()

    const inputId = generatedId ?? id

    const isError = useMemo(() => getIsError(error), [error])

    return (
      <Container disabled={disabled} aria-disabled={disabled}>
        {label && <Label htmlFor={inputId}>{label}</Label>}
        <StyledTextArea
          {...rest}
          ref={ref}
          disabled={disabled}
          autoCorrect={autoCorrect}
          placeholder={placeholder}
          id={inputId}
          searchIcon={searchIcon}
          appearance={appearance}
          error={isError}
        />
        {searchIcon && <Search icon="search" />}
        {error && <Error error={error} label={label} />}
      </Container>
    )
  },
)
