import type { CourseCountry } from '@graphql/generated/types'
import { joiResolver } from '@hookform/resolvers/joi/dist/joi'
import * as Joi from 'joi'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import type { Option } from '@/components'
import { Loader } from '@/components'
import { ControlledSelect } from '@/components/Select/ControlledSelect'
import { validators } from '@/core/validation'

import type { SearchSuccessParams } from '../../Marketplace'
import { Container, Form, SubTitle, Title } from './Filters.styled'
import { useGradesSubjectsChildrenOptions } from './useGradesSubjectsChildrenOptions'

export type Price = 'free' | 'paid'

export type Unit = 'allUnits' | 'individualUnits'

export type MarketplaceFiltersSchema = {
  grade: Option | undefined
  subjects: Option[] | undefined
  countries: Option<CourseCountry> | undefined
  price: Array<Option<Price>> | undefined
  unit: Option<Unit> | undefined
}

const schema = Joi.object<MarketplaceFiltersSchema>({
  grade: validators.optionRequired(),
  subjects: validators.optionsInArray(),
  countries: validators.optionRequired(),
  price: validators.optionsInArray(),
  unit: validators.optionRequired(),
})

type FiltersProps = {
  onSearchStart: (params: SearchSuccessParams) => void
}

export function Filters({ onSearchStart }: FiltersProps) {
  const { t } = useTranslation('parent', {
    keyPrefix: 'marketplace',
  })

  const { control, watch, setValue } = useForm<MarketplaceFiltersSchema>({
    resolver: joiResolver(schema),
  })

  const {
    subjectOptions,
    gradeOptions,
    countryOptions,
    priceOptions,
    unitOptions,
    isLoading,
  } = useGradesSubjectsChildrenOptions()

  useEffect(() => {
    if (isLoading) {
      return
    }

    const currentGradeOption = gradeOptions[0]

    if (!currentGradeOption) {
      setValue('grade', currentGradeOption)

      return
    }

    setValue('grade', currentGradeOption)
  }, [isLoading, gradeOptions])

  useEffect(() => {
    if (isLoading) {
      return
    }

    setValue('subjects', subjectOptions)
  }, [isLoading, subjectOptions])

  useEffect(() => {
    const foundOption = countryOptions.find(({ value }) => value === 'CANADA')

    if (!foundOption) {
      return
    }

    setValue('countries', foundOption)
  }, [countryOptions])

  useEffect(() => {
    setValue('price', priceOptions)
  }, [priceOptions])

  useEffect(() => {
    const unit = unitOptions.find(({ value }) => value === 'individualUnits')

    setValue('unit', unit)
  }, [unitOptions])

  const [grade, subjects, countries, price, unit] = watch([
    'grade',
    'subjects',
    'countries',
    'price',
    'unit',
  ])

  useEffect(() => {
    if (isLoading || !grade || !subjects || !countries || !price || !unit) {
      return
    }

    const valueMapper = <Value,>({ value }: { value: Value }) => value

    onSearchStart({
      gradeId: grade.value,
      subjectIds: subjects.map(valueMapper),
      countries: countries.value,
      price: price.map(valueMapper),
      unit: unit.value,
    })
  }, [grade, subjects, countries, price, unit, isLoading])

  return (
    <Container>
      <Title>{t('marketplace')}</Title>
      <SubTitle>{t('filterSearch')}</SubTitle>
      <Form>
        {isLoading ? (
          <Loader color="grape" />
        ) : (
          <>
            <ControlledSelect
              appearance="grey"
              control={control}
              name="grade"
              label={t('grade')}
              options={gradeOptions}
            />
            <ControlledSelect
              isMulti
              appearance="grey"
              control={control}
              name="subjects"
              label={t('subjects')}
              options={subjectOptions}
            />
            <ControlledSelect
              appearance="grey"
              control={control}
              name="countries"
              label={t('countries')}
              options={countryOptions}
            />
            <ControlledSelect
              isMulti
              appearance="grey"
              control={control}
              name="price"
              label={t('price')}
              options={priceOptions}
            />
            <ControlledSelect
              appearance="grey"
              control={control}
              name="unit"
              label={t('unit')}
              options={unitOptions}
            />
          </>
        )}
      </Form>
    </Container>
  )
}
